import React from 'react'
import styles from './Grid.module.scss'
import { AnyTag, PropsOf } from '~types'

function Grid<Tag extends AnyTag>(
  props: { as?: Tag } & PropsOf<Tag>,
): JSX.Element

function Grid({ children, as: Wrapper = 'div', className, ...rest }: any) {
  return (
    <Wrapper
      className={`${styles.container} ${className ? className : ''}`}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

export default Grid

import React from 'react'
import {
  Button,
  Form,
  FormProps,
  PasswordInput,
  useCustomForm,
  FormError,
} from '~components'
import { getValidationSchema } from '~utils'
import { password, passwordMatch } from '~validations'

const formSchema = getValidationSchema({
  resetPassword: password,
  confirmPassword: passwordMatch('resetPassword'),
})

interface ForgotPasswordFormValues {
  password: string
  confirmPassword: string
}

const ResetPasswordForm = ({
  error,
  loading,
  onSubmit,
}: Pick<FormProps, 'error' | 'loading' | 'onSubmit'>) => {
  const context = useCustomForm<ForgotPasswordFormValues>({
    schema: formSchema,
  })

  return (
    <Form {...context} error={error} loading={loading} onSubmit={onSubmit}>
      <PasswordInput
        name={'resetPassword'}
        inputProps={{
          label: 'New Password',
          placeholder: '********',
          theme: 'white',
          wrapperClassName: 'mb-3',
        }}
      />
      <PasswordInput
        name={'confirmPassword'}
        inputProps={{
          label: 'Re-enter password',
          placeholder: '********',
          theme: 'white',
          wrapperClassName: 'mb-3',
        }}
      />
      <FormError error={error} className={'mb-3'} />
      <Button type={'submit'}>Reset Password</Button>
    </Form>
  )
}

export default ResetPasswordForm

import React, { useMemo } from 'react'
import { ALIVE_MARKETING_URL, formatPrice } from '~utils'
import { IStripeSubscription, IStripeCustomer } from '~stores'
import { Link, PricingTableItem, Typography } from '~components'

interface AccountSubscriptionProps {
  customer: IStripeCustomer
  subscription: IStripeSubscription
}

const AccountSubscription: React.FC<AccountSubscriptionProps> = ({
  subscription,
  customer,
}) => {
  const title = useMemo(
    () => `${subscription.readableInterval} Plan`,
    [subscription],
  )

  const subtitle = useMemo(
    () =>
      subscription.discountedAmount
        ? `$${formatPrice(subscription.discountedAmount)} USD`
        : `$${formatPrice(subscription.plan.amount)} USD`,
    [subscription],
  )

  const disclaimer = useMemo(
    () =>
      subscription.discountedAmount
        ? `${subscription.readablePricing.subtitle}. Full price will apply to future renewals.`
        : `${subscription.readablePricing.subtitle}`,
    [subscription],
  )

  return (
    <PricingTableItem
      checked
      current
      title={title}
      subtitle={subtitle}
      disclaimer={disclaimer}
    />
  )
}
export default AccountSubscription

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Request for create a subscription */
export interface AddressRecord {
  /** City, district, suburb, town, or village. */
  city: string | null
  /** Two-letter country code (ISO 3166-1 alpha-2). https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
  country: string | null
  /** Address line 1 (e.g., street, PO Box, or company name). */
  line1: string | null
  /** Address line 2 (e.g., apartment, suite, unit, or building). */
  line2: string | null
  /** ZIP or postal code. */
  postal_code: string | null
  /** State, county, province, or region. */
  state: string | null
}

/**
 * Error
 * A API Error
 */
export interface ApiError {
  detail: string
  status: number
  title: string
}

/**
 * AuthGetToken
 * Get a JWTToken
 */
export interface AuthGetToken {
  /**
   * The user's email address or mobile number in E164 format
   * @example "test@gmail.com"
   */
  login: string
  /**
   * The user's unencrypted password or mobile verification code
   * @example "MySecretPassword"
   */
  password: string
}

/**
 * Category
 * A category
 */
export interface Category {
  /**
   * Determines if the category should appear on the home page
   * @example true
   */
  display: boolean
  id: number
  /** The URL of the image for this category */
  image_url: string | null
  /**
   * Name of the category
   * @example "HIIT"
   */
  name: string
}

/** Response object contains all the categories */
export interface CategoryResponse {
  /** List of categories */
  categories?: Category[]
}

/**
 * Challenge
 * A User Challenge
 */
export interface Challenge {
  /** A boolean to indicate if the record is active, used for offline synchronization */
  active?: boolean
  /** The timestamp of which the record was created on the server, used for offline synchronization */
  create_date?: string
  /**
   * The internal id of the challenge generated by the server
   * @example 123
   */
  id?: number
  /** The title of the challenge */
  name?: string | null
  state?: 'enrolled' | 'active' | 'complete'
  /**
   * A Challenge Task in list form
   * @example ["str1","str2","str3"]
   */
  tasks?: ChallengeTask[] | null
  /** A UUID generated by the app, used for offline synchronization */
  uuid?: string
  /** The timestamp of which the record was last modified, used for offline synchronization */
  write_date?: string
}

/**
 * Challenge
 * Join a Challenge by creating a new UserChallenge record
 */
export interface ChallengeCreate {
  /**
   * The id of the challenge to join
   * @example 10
   */
  challenge_id: number
  /** A UUID generated by the app, used for offline synchronization */
  uuid: string
}

/**
 * Challenge
 * Modify a User Challenge
 */
export interface ChallengePatch {
  /**
   * An array of Challenge Tasks to be updated
   * @example [{"is_complete":true,"task_id":5},{"is_complete":false,"task_id":22}]
   */
  tasks?: ChallengeTask[] | null
}

/** Response object contains all challengesfor the logged on user */
export interface ChallengeResponse {
  /** List of challenges */
  journals?: Challenge[]
}

/** Challenge Task */
export interface ChallengeTask {
  /** True or False indicating whether or not the task has been completed */
  is_complete?: boolean
  /** The id of the Challenge Task */
  task_id?: number
}

/** Request for validating a verification email */
export interface EmailVerification {
  /** The system generated token used to confirm the email address */
  token: string
  /** The ID of the user for which the email will be validated for */
  uid: number
}

/** Request for Sending a verification email */
export interface EmailVerificationSend {
  /** The ID of the user for which the email will be sent to */
  uid: number
}

/**
 * Equipment
 * A piece of Equipment
 */
export interface Equipment {
  id: number
  /** The URL of the image for this category */
  image_url: string | null
  /**
   * Name of the equipment
   * @example "Dumbells"
   */
  name: string
}

/** Response object contains all the equipment */
export interface EquipmentResponse {
  /** List of all equipment */
  equipment?: Equipment[]
}

/** An exercise */
export interface Exercise {
  /** an array of integers specifying the alternate exercise IDs */
  alternate_exercise_ids: number[]
  /** an array of alternate exercises */
  alternate_exercises: Exercise[]
  /** an array of equipment specifying the equipment ID and name uses for this exercise */
  equipment: Equipment[]
  /** an array of integers specifying the equipment ID used for this exercise */
  equipment_ids: number[]
  /**
   * the unique ID associated with this exercise set
   * @example 1
   */
  id: number
  /** The URL of the image for this category */
  image_url: string | null
  /**
   * True if this exercise is designated as rest, otherwise false if it is a real exercise
   * @example false
   */
  is_rest: boolean
  /**
   * the name of this specific exercise
   * @example "Toe Taps"
   */
  name: string
  /** subtext for this exercise, ie. Left Leg, Right Leg, etc */
  subtext: string | null
  /** The URL of the image for this category */
  video_url: string | null
}

/** A collection of exercises with additional attributes */
export interface Exerciseset {
  /**
   * the count associated with this exercise
   * @example 12
   */
  count: number
  /** An exercise */
  exercise: Exercise
  /**
   * the unique ID associated with this exercise set
   * @example 1
   */
  id: number
  /** the units related to the specific exercise (ie, reps, seconds, etc) */
  units: 'seconds' | 'reps'
}

/**
 * JWTToken
 * A valid JWT Token
 */
export interface JWTToken {
  /**
   * a valid JWT Token
   * @example "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjby5jYW52YXNjcmVhdGl2ZS5hbGl2ZS5hcGkiLCJpYXQiOjE1ODcwMTYzNzQsImV4cCI6MTU4NzAxOTk3NCwic3ViIjoiNzUifQ.hoVBNTJF8LI282uhg2ZMKU3WH5_zn47ENHSYCLmzXsE"
   */
  token: string
}

/**
 * Journal
 * A Journal entry
 */
export interface Journal {
  /** A boolean to indicate if the record is active, used for offline synchronization */
  active?: boolean
  /** The timestamp of which the record was created on the server, used for offline synchronization */
  create_date?: string
  /**
   * A journal entry in list form
   * @example ["str1","str2","str3"]
   */
  entries?: string[] | null
  /** The body of the journal entry */
  entry?: string | null
  /**
   * The internal id of the journal entry, generated by the server
   * @example 123
   */
  id?: number
  /** The title of the journal entry */
  name?: string | null
  /** The journal prompt */
  prompt?: string | null
  /** The type of journal entry, either Entry for full textbox or List for array of items */
  type?: 'entry' | 'list'
  /** A UUID generated by the app, used for offline synchronization */
  uuid?: string
  /** The timestamp of which the record was last modified, used for offline synchronization */
  write_date?: string
}

/**
 * Journal
 * A Journal entry
 */
export interface JournalCreate {
  /**
   * A journal entry in list form
   * @example ["str1","str2","str3"]
   */
  entries?: string[] | null
  /** The body of the journal entry */
  entry?: string | null
  /** The title of the journal entry */
  name: string | null
  /** The journal prompt */
  prompt?: string | null
  /** The type of journal entry, either Entry for full textbox or List for array of items */
  type: 'entry' | 'list'
  /** A UUID generated by the app, used for offline synchronization */
  uuid: string
}

/**
 * Journal
 * Modify a journal entry
 */
export interface JournalPatch {
  /**
   * A journal entry in list form
   * @example ["str1","str2","str3"]
   */
  entries?: string[] | null
  /** The body of the journal entry */
  entry?: string | null
  /** The title of the journal entry */
  name?: string
  /** The journal prompt */
  prompt?: string | null
}

/** Response object contains all journal entries for the logged on user */
export interface JournalResponse {
  /** List of journal entries */
  journals?: Journal[]
}

/**
 * Achievement
 * An Achievement record
 */
export interface JourneyAchievement {
  /** @example 1 */
  id: number
  /** The URL of the image for the achievement after it has been earned */
  image_url: string | null
  /**
   * True if this achievement has been acknowledged by the current user
   * @example true
   */
  is_acknowledged: boolean
  /**
   * True if this achievement has been earned by the current user
   * @example false
   */
  is_awarded: boolean
  /**
   * The name of the achievement
   * @example "1 Workout Completed"
   */
  name: string
  /**
   * Shows the order for the three most recently acknowledged achievements
   * @example 1
   */
  order: number | null
  /** The type of program achievement */
  program_type: 'start' | 'halfway' | 'complete' | null
  /** The type of achievement */
  type?: 'workout_count' | 'journal_count' | 'journal_streak' | 'program'
  /** The URL of the dark mode image for the achievement before it has been earned */
  unearned_dark_image_url?: string | null
  /** The URL of the light mode image for the achievement before it has been earned */
  unearned_light_image_url?: string | null
}

/**
 * Ackowledge An Achievement
 * Marks an awarded achievement as acknowledged
 */
export interface JourneyAchievementPatch {
  /**
   * Set to true once the UI has been presented to the current user for newly awarded achievement
   * @example true
   */
  is_acknowledged: boolean
}

/** Response object contains all achievements records */
export interface JourneyAchievementResponse {
  /** List of all achievements records.  Indicates if the current user has been awarded this achievement and acknowledged this achievement */
  achievements?: JourneyAchievement[]
}

/**
 * Photo
 * A photo record
 */
export interface JourneyPhoto {
  /**
   * The timestamp that the weight record was created
   * @example "2019-10-29T06:21:48Z"
   */
  create_date: string
  /** @example 1 */
  id: number
  /** The URL of the photo */
  image_url: string
}

/** Response object contains all photos for the current user */
export interface JourneyPhotoResponse {
  /** List of all photos for the current user */
  photos?: JourneyPhoto[]
}

/**
 * Program
 * A User Program record
 */
export interface JourneyProgram {
  /**
   * the accumulated number of minutes for all completed workouts in this program
   * @example 45
   */
  active_minutes: number
  /**
   * the number of completed workouts in this program
   * @example 5
   */
  completed_workout_count: number
  /**
   * The timestamp that the workout record was completed
   * @example "2019-10-29T06:21:48Z"
   */
  create_date: string
  /**
   * the number of elapsed days since this program was started
   * @example 2
   */
  days_count: number
  /** @example 1 */
  id: number
  /**
   * the ID of the ProgramWorkout that should be completed next
   * @example 10
   */
  next_program_workout_id: number | null
  /**
   * An array of the IDs of the ProgramWorkout that are available for completion in the current week
   * @example [2800,2801,2802,2803]
   */
  next_program_workout_ids: number[]
  /**
   * the week of the ProgramWorkout that should be completed next
   * @example 2
   */
  next_program_workout_week: number | null
  /**
   * The id of the program
   * @example 22
   */
  program_id: number | null
  /** List of Program Workouts */
  program_workouts: JourneyProgramWorkoutResponse[]
  /**
   * The current state of the program
   * @example "active"
   */
  state: 'active' | 'inactive' | 'complete'
  /**
   * the number of workouts in this program
   * @example 15
   */
  workout_count: number
}

/**
 * Program
 * Enroll in a new program
 */
export interface JourneyProgramNoId {
  /**
   * The id of the program of which to enroll in
   * @example 10
   */
  program_id: number
}

/**
 * Modify a JourneyProgram
 * Modifies a journey program
 */
export interface JourneyProgramPatch {
  /** An array of dictionaries specifying the `id` and `day` */
  program_workouts?: JourneyProgramPathProgramWorkouts[]
  /**
   * The ID specifying the reason why the program was withdrawn from
   * @example 4
   */
  withdrawal_reason_id?: number
}

export interface JourneyProgramPathProgramWorkouts {
  /**
   * The new day number for the program workout
   * @example 3
   */
  day?: number
  /**
   * the ID of the specific program workout that is to be modified
   * @example 1
   */
  id?: number
}

/**
 * Journey Program Reason
 * The reason why withdrawing from a program
 */
export interface JourneyProgramReason {
  /** @example 1 */
  id: number
  /**
   * phrase asking why the user withdrawed from the program
   * @example "Too difficult"
   */
  name: string
}

/** Response object contains all reasons for withdrawing from a program */
export interface JourneyProgramReasonResponse {
  /** List of all reasons for withdrawing from a program */
  reasons?: JourneyProgramReason[]
}

/** Response object contains all the JourneyPrograms */
export interface JourneyProgramResponse {
  /** List of Journey Programs */
  journey_programs?: JourneyProgram[]
}

/**
 * Journey Program Workout
 * User records for workouts belonging to a program
 */
export interface JourneyProgramWorkout {
  /**
   * The day that this workout should be completed
   * @example 1
   */
  day: number
  /** @example 1 */
  id: number
  /**
   * set to True if the workout has been completed
   * @example true
   */
  is_complete: boolean
  /**
   * the name of the workout
   * @example "HIIT 1"
   */
  name: string
  /**
   * The week in which this workout should be completed
   * @example 3
   */
  week: number
  /**
   * the ID to the original workout record
   * @example 2
   */
  workout_id: number | null
}

/** Response object contains workouts belonging to a program */
export interface JourneyProgramWorkoutResponse {
  /** List of all workouts belonging to a program */
  program_workouts?: JourneyProgramWorkout[]
}

/**
 * Weight
 * A weight record
 */
export interface JourneyWeight {
  /** @example 1 */
  id: number
  /**
   * The date and time on which the weight was recorded
   * @example "2019-10-29T06:21:48Z"
   */
  timestamp: string
  /**
   * The weight returned in either kgs or lbs based on users current unit of measure selection
   * @example 150.1
   */
  weight: number
}

/**
 * Weight
 * Create a weight record
 */
export interface JourneyWeightNoId {
  /**
   * The date and time the weight entry was logged
   * @example "2019-10-31T15:03:00Z"
   */
  timestamp?: string
  /**
   * The weight in either kgs or lbs based on users current unit of measure selection
   * @example 150.1
   */
  weight: number
}

/** Response object contains all weight records for the current user */
export interface JourneyWeightResponse {
  /** List of all weight records for the current user */
  weights?: JourneyWeight[]
}

/**
 * JourneyWorkout
 * A JourneyWorkout record
 */
export interface JourneyWorkout {
  /**
   * The id of the category for the completed workout
   * @example 11
   */
  category_id: number | null
  /**
   * The name of the category for the completed workout
   * @example "HIIT"
   */
  category_name: string
  /**
   * The timestamp that the workout record was completed
   * @example "2019-10-29T06:21:48Z"
   */
  create_date: string
  /**
   * the original day of which this workout was to be completed
   * @example 3
   */
  day: number
  /**
   * The duration of the workout in seconds
   * @example 240
   */
  duration: number
  /** @example 1 */
  id: number
  /** The name of the program */
  program_name?: string | null
  /**
   * the original week number in which this workout was to be completed
   * @example 3
   */
  week: number
  /**
   * The id of the completed workout
   * @example 22
   */
  workout_id: number | null
  /**
   * The name of the completed workout
   * @example "HIIT Workout 2"
   */
  workout_name: string
}

/**
 * Workout
 * Create a workout record
 */
export interface JourneyWorkoutNoId {
  /**
   * The id of the completed workout
   * @example 12
   */
  workout_id: number
}

/** Response object contains all workout records for the current user */
export interface JourneyWorkoutResponse {
  /** List of all workout records for the current user */
  weights?: JourneyWorkout[]
}

/**
 * Log
 * A Log
 */
export interface Log {
  /**
   * A JSON encoded string with the data for this record
   * @example "{"id": 29, "name": "Full Body", "image_url": null, "display": false}"
   */
  data: string | null
  /**
   * the id for the log record
   * @example 29
   */
  id: number
  /** The type of log */
  method: 'create' | 'write' | 'unlink'
  /**
   * The database model for this record
   * @example "alive.category"
   */
  model: string
  /**
   * the resource id for the record from the given model
   * @example 29
   */
  res_id: number
}

/** List of log entries */
export type LogResponse = Log[]

/**
 * Pack
 * A pack represents a collection of programs
 */
export interface Pack {
  /** @example 5 */
  id: number
  /** The URL of the image for this pack */
  image_url: string | null
  /**
   * Name of the pack
   * @example "Alive Inside"
   */
  name: string
}

/** Request for modifying a customer and setting the default payment method */
export interface PatchCustomerRequest {
  default_payment_method_id: string
}

/** Request for create a subscription */
export interface PostSubscriptionRequest {
  coupon_id?: string
  price_id: string
}

/** Response after creating a subscription */
export interface PostSubscriptionResponse {
  client_secret?: string
  subscription_id?: string
}

/**
 * Price
 * A Stripe Price Object
 */
export interface Price {
  active?: boolean
  billing_scheme?: string
  created?: number
  currency?: string
  id?: string
  live_mode?: boolean
  lookup_key?: string | null
  metadata?: object
  nickname?: string | null
  object?: string
  product?: string
  recurring?: object
  tax_behavior?: string
  tiers_mode?: string | null
  transform_quantity?: string | null
  unit_amount_?: number
  unit_amount_decimal?: string
}

/**
 * Program
 * A Program
 */
export interface Program {
  /** description of the program */
  description: string | null
  /**
   * The unique identifier of the program
   * @example 1
   */
  id: number
  /** Image URL for this specific program */
  image_url: string | null
  /**
   * Name of the program
   * @example "10 Week Beginner"
   */
  name: string
  /**
   * the number of weeks for this program
   * @example 20
   */
  number_of_weeks: number
  /** A pack represents a collection of programs */
  pack: Pack | null
  /** Secondary image URL for this specific program */
  secondary_image_url: string | null
  /**
   * For programs that belong to a pack, the sequence represents the order of the program
   * @example 1
   */
  sequence: number
  skill_levels: SkillLevel[] | null
  /** Slogan or catch phrase for the program */
  slogan: string
  /** The trainer for the specific program */
  trainer: Trainer | null
  /** The type for the specific program */
  type?: ProgramType | null
  workouts: Workout[]
  /**
   * the number of workouts per week
   * @example 4
   */
  workouts_per_week: number
}

/** Response object contains all the programs */
export interface ProgramResponse {
  /** List of all programs */
  programs?: Program[]
}

/**
 * Program Type
 * The type for the specific program
 */
export interface ProgramType {
  /** @example 1 */
  id: number
  /** The URL of the image for this type of program */
  image_url: string | null
  /**
   * Name of the program type
   * @example "Circuit Training"
   */
  name: string
}

/** Recurring */
export interface Recurring {
  aggregate_usage?: string | null
  interval?: string
  inverval_count?: number
  trial_period_days?: number | null
  usage_type?: string
}

/** Request for changing the user's password */
export interface SetPassword {
  /** The new password to assign to the user */
  password: string
  /** The system generated token used to confirm the email address */
  token: string
}

export interface SetupIntentRequest {
  /** The id returned by Stripe after collecting the payment method */
  payment_method_id?: string
}

/**
 * Skill Level
 * The skill level for a program
 */
export interface SkillLevel {
  /** @example 1 */
  id: number
  /**
   * Name of the skill level
   * @example "Beginner"
   */
  name: string
}

/** Response object contains all active price records */
export interface StripePriceResponse {
  prices?: Price[]
  publishableKey?: string
}

/** A collection of Exercise sets and additional attributes make up a Superset */
export interface Superset {
  /**
   * additional rest for this superset after all of the exercise sets are complete, specified in seconds
   * @example 60
   */
  additional_rest: number
  /**
   * the day of which this workout is to be completed
   * @example 3
   */
  day?: number
  /**
   * The duration of the superset in minutes
   * @example 15
   */
  duration: number
  exercise_sets: Exerciseset[]
  /**
   * the unique id for this specific superset
   * @example 1
   */
  id: number
  /**
   * specific instructions for this superset
   * @example "30 seconds on, 30 seconds off"
   */
  instructions: string | null
  /**
   * the number of times that this superset should be repeated
   * @example 3
   */
  repetitions: number
  /** The type of superset */
  type: 'normal' | 'warm_up' | 'cool_down' | 'glute_activation'
  /**
   * the week number in which this workout is to be completed
   * @example 3
   */
  week?: number
}

/**
 * Trainer
 * The trainer for the specific program
 */
export interface Trainer {
  /** A brief description of the trainer */
  description: string | null
  /** @example 1 */
  id: number
  /** The URL of the image for this trainer */
  image_url: string | null
  /**
   * Name of the trainer
   * @example "Whitney Simmons"
   */
  name: string
}

/**
 * User
 * A User
 */
export interface User {
  /**
   * The ID of the currently active journey_program, or NULL of no active program
   * @example 545
   */
  active_journey_program_id: number | null
  /**
   * An array of the IDs of the currently active journey_programs, or an empty array [] if no active program
   * @example [545,795]
   */
  active_journey_program_ids: number[]
  /** Date of birth */
  birthdate: string | null
  /** True if the user has selected to receive email notifications */
  can_send_email: boolean
  /** True if the user has selected to receive text messages */
  can_send_text: boolean
  /** Email address */
  email: string | null
  /** True if the user has selected to have audible sounds for the timer */
  enable_timer_sounds: boolean
  /**
   * The date/time that the current subscription expires in UTC
   * @example "2020-01-06T19:04:30Z"
   */
  expires_date: string | null
  /**
   * An unordered array of the IDs of the workouts that the user has favorited, or an empty array [] indicates no favorites
   * @example [31,456,19]
   */
  favorited_workout_ids: number[]
  /**
   * User first name
   * @example "Thomas"
   */
  first_name: string | null
  /** A boolean flag signifying if the email has been validated. Validation occurs when it passes a syntax check and MX Record check for the given domain */
  has_email_been_validated?: boolean
  /** A boolean flag signifying if the email had been veriried.  Verification occurs when clicking the link that's included in the email */
  has_email_been_verified?: boolean
  /** A boolean flag signifying if the mobile number has been validated.  Validation occurs when it passes a syntax check and is stored in E.164 format */
  has_mobile_been_validated?: boolean
  /** A boolean flag signifying if the mobile number has been verified.  Verification occurs when a user successfully verifies the 6 digit code */
  has_mobile_been_verified?: boolean
  /**
   * The internal id of the user
   * @example 33
   */
  id: number
  /** True if the user wants to include cooldown exercises */
  include_cooldown_exercises: boolean
  /** True if the user wants to include warmup exercises */
  include_warmup_exercises: boolean
  /**
   * User last name
   * @example "Jones"
   */
  last_name: string | null
  /** Confirmed mobile phone number */
  mobile: string | null
  /** The users password */
  password?: string
  /** True if the user has selected to prevent the screen from sleeping */
  prevent_screen_from_sleeping: boolean
  /**
   * The current product id of the current subscription
   * @example "com.alive.premium.monthly"
   */
  product_id: string | null
  /** True if the user has selected to receive a daily reminder to journal */
  remind_me_to_journal: boolean
  /** The ID of the corresponding Stripe customer */
  stripe_customer_reference: string | null
  /** The most recent active subscription platform */
  subscription_platform?: 'android' | 'ios' | 'promo' | 'web' | null
  /** The timezone name of the user */
  tz?: string | null
  /** Unit of measure */
  units: 'imperial' | 'metric'
  /** A JSON string used to store miscellaneous variables for website */
  website_json?: string | null
}

/**
 * User
 * A Newly Created User
 */
export interface UserIdAndToken {
  /**
   * The internal id of the user
   * @example 33
   */
  id: number
  /**
   * a valid JWT Token
   * @example "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjby5jYW52YXNjcmVhdGl2ZS5hbGl2ZS5hcGkiLCJpYXQiOjE1ODcwMTYzNzQsImV4cCI6MTU4NzAxOTk3NCwic3ViIjoiNzUifQ.hoVBNTJF8LI282uhg2ZMKU3WH5_zn47ENHSYCLmzXsE"
   */
  token: string
}

/**
 * User
 * A User
 */
export interface UserIdOnly {
  /**
   * The internal id of the user
   * @example 33
   */
  id: number
}

/**
 * User
 * A User
 */
export interface UserNoId {
  /**
   * Date of birth
   * @example "2000-11-24"
   */
  birthdate?: string
  /** True if the user has selected to receive email notifications */
  can_send_email?: boolean
  /** True if the user has selected to receive text messages */
  can_send_text?: boolean
  /**
   * Email address
   * @example "thomas@jones.com"
   */
  email?: string | null
  /** True if the user has selected to have audible sounds for the timer */
  enable_timer_sounds?: boolean
  /**
   * User first name
   * @example "Thomas"
   */
  first_name?: string | null
  /** True if the user wants to include cooldown exercises */
  include_cooldown_exercises?: boolean
  /** True if the user wants to include warmup exercises */
  include_warmup_exercises?: boolean
  /**
   * User last name
   * @example "Jones"
   */
  last_name?: string | null
  /**
   * Confirmed mobile phone number
   * @example "+18015551212"
   */
  mobile?: string | null
  /** The users password */
  password?: string
  /** True if the user has selected to prevent the screen from sleeping */
  prevent_screen_from_sleeping?: boolean
  /** True if the user has selected to receive a daily reminder to journal */
  remind_me_to_journal?: boolean
  /** The timezone name of the user */
  tz?: string | null
  /** Unit of measure */
  units?: 'imperial' | 'metric'
  /** A JSON string used to store miscellaneous flags and variables for website */
  website_json?: string | null
  redirect_url?: string
}

/** User list */
export interface UserResponse {
  /** User ID */
  users?: UserIdOnly[]
}

/**
 * User Stats
 * The annual stats for the logged on user
 */
export interface UserStats {
  /**
   * An array of the names of all completed programs
   * @example ["Alive Inside","Alive Inside 2.0"]
   */
  programs_completed: string[]
  /**
   * The name of the category of which the most daily workouts were completed in
   * @example "Legs"
   */
  top_daily_category: string
  /**
   * An array of the names of the top five daily workouts performed
   * @example ["Ab Be Ready For It","Guess Who's Back? Back Again","At-Home Full Body","HIIT IT WHIIT ME","HIIT"]
   */
  top_five_daily_workouts: string[]
  /**
   * The duration for all workouts
   * @example 1037
   */
  total_active_minutes: number
  /**
   * The total number of workouts performed
   * @example 172
   */
  total_workouts_performed: number
}

/**
 * Verify receipt
 * Receipt verification data structure
 */
export interface VerifyReceipt {
  /** The environment by which the receipt should be validated against. */
  environment: 'sandbox' | 'production'
  /** The purchase token (for Android only) */
  purchase_token?: string
  /** The base64 encoded receipt data (for iOS only) */
  'receipt-data'?: string
  /** The subscription id (for Android only) */
  subscription_id?: string
}

/**
 * Workout
 * A Workout
 */
export interface Workout {
  /** A category */
  category: Category
  /**
   * description of the workout
   * @example "30 seconds on, 30 seconds off... repeat exercise 3x before moving to next"
   */
  description: string | null
  /**
   * the duration of the workout in minutes
   * @example 20
   */
  duration: number
  /**
   * The unique identifier of the workout
   * @example 1
   */
  id: number
  /** Image URL for this specific workout */
  image_url: string | null
  /**
   * set to true if this workout should be hidden from the API
   * @example false
   */
  is_hidden: boolean
  /**
   * set to true if this is a premium workout, otherwise false.  Symantically this field is the same as `is_hidden` and will be removed in a future release
   * @deprecated
   * @example false
   */
  is_premium: boolean
  /**
   * Name of the workout
   * @example "HIIT Workout 1"
   */
  name: string
  supersets: Superset[]
}

/** Response object contains all the workouts */
export interface WorkoutResponse {
  /** List of all workouts */
  workouts?: Workout[]
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/v1.1'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key],
    )
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal: cancelToken
          ? this.createAbortSignal(cancelToken)
          : requestParams.signal,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title OpenAPI Alive Information Service
 * @version 1.0.0
 * @baseUrl /v1.1
 *
 * This is the API for the Alive app
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  auth = {
    /**
     * No description
     *
     * @tags user
     * @name ControllersAuthPostGetToken
     * @summary Return JWT token for the user given an email and password
     * @request POST:/auth/getToken
     */
    controllersAuthPostGetToken: (
      data: AuthGetToken,
      params: RequestParams = {},
    ) =>
      this.request<JWTToken, ApiError>({
        path: `/auth/getToken`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersAuthGetVerifyToken
     * @summary Validate the passed JWT token
     * @request GET:/auth/verifyToken
     * @secure
     */
    controllersAuthGetVerifyToken: (params: RequestParams = {}) =>
      this.request<JWTToken, void>({
        path: `/auth/verifyToken`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description This endpoint returns a signed encoded JWT Token that should be sent to the server in a header Authentication&#58;, Bearer xxx.yyy.zzz
     *
     * @tags user
     * @name AuthDetail
     * @summary Return JWT token for the user given an email and password
     * @request GET:/auth/{login}/{password}
     * @deprecated
     */
    authDetail: (login: string, password: string, params: RequestParams = {}) =>
      this.request<JWTToken, ApiError>({
        path: `/auth/${login}/${password}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersAuthGetMobile
     * @summary Send a SMS to the mobile number with a code to be used as the password
     * @request GET:/auth/{mobile}
     */
    controllersAuthGetMobile: (
      mobile: string,
      redirectUrl: string,
      params: RequestParams = {},
    ) => {
      const url = `/auth/${mobile}?redirect_url=${redirectUrl}`
      return this.request<void, ApiError>({
        path: url,
        method: 'GET',
        ...params,
      })
    },
  }
  category = {
    /**
     * @description Retrieves the list of all categories
     *
     * @tags category
     * @name CategoryList
     * @summary Retrieves the list of all categories
     * @request GET:/category
     * @deprecated
     */
    categoryList: (
      query?: {
        /** display filter */
        display?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<CategoryResponse, any>({
        path: `/category`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single category
     *
     * @tags category
     * @name CategoryDetail
     * @summary Retrieve a single category
     * @request GET:/category/{category_id}
     * @deprecated
     */
    categoryDetail: (categoryId: string, params: RequestParams = {}) =>
      this.request<Category, void>({
        path: `/category/${categoryId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  email = {
    /**
     * No description
     *
     * @tags email
     * @name ControllersEmailPostVerification
     * @summary Validate a token
     * @request POST:/email/verification
     */
    controllersEmailPostVerification: (
      data: EmailVerification,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/email/verification`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email
     * @name ControllersEmailPostVerificationSend
     * @summary Trigger an email to be sent to validate the email address
     * @request POST:/email/verification/send
     */
    controllersEmailPostVerificationSend: (
      data: EmailVerificationSend,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/email/verification/send`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  equipment = {
    /**
     * @description Retrieves the list of all equipment
     *
     * @tags equipment
     * @name EquipmentList
     * @summary Retrieves the list of all equipment
     * @request GET:/equipment
     * @deprecated
     */
    equipmentList: (params: RequestParams = {}) =>
      this.request<EquipmentResponse, any>({
        path: `/equipment`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single piece of equipment
     *
     * @tags equipment
     * @name EquipmentDetail
     * @summary Retrieve a single piece of equipment
     * @request GET:/equipment/{equipment_id}
     * @deprecated
     */
    equipmentDetail: (equipmentId: string, params: RequestParams = {}) =>
      this.request<Equipment, void>({
        path: `/equipment/${equipmentId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  health = {
    /**
     * No description
     *
     * @name HealthList
     * @request GET:/health
     */
    healthList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/health`,
        method: 'GET',
        ...params,
      }),
  }
  journeyAchievement = {
    /**
     * No description
     *
     * @tags journey
     * @name JourneyAchievementList
     * @summary Return all possible achievements
     * @request GET:/journey_achievement
     * @secure
     */
    journeyAchievementList: (params: RequestParams = {}) =>
      this.request<JourneyAchievementResponse, void>({
        path: `/journey_achievement`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyAchievementDetail
     * @summary Return a specific achievement
     * @request GET:/journey_achievement/{achievement_id}
     * @secure
     */
    journeyAchievementDetail: (
      achievementId: number,
      params: RequestParams = {},
    ) =>
      this.request<JourneyAchievement, void>({
        path: `/journey_achievement/${achievementId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyAchievementPartialUpdate
     * @summary Acknowledge an achievement
     * @request PATCH:/journey_achievement/{achievement_id}
     * @secure
     */
    journeyAchievementPartialUpdate: (
      achievementId: number,
      data: JourneyAchievementPatch,
      params: RequestParams = {},
    ) =>
      this.request<JourneyAchievementPatch, any>({
        path: `/journey_achievement/${achievementId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  journeyPhoto = {
    /**
     * No description
     *
     * @tags journey
     * @name JourneyPhotoList
     * @summary Return all photos for the current user
     * @request GET:/journey_photo
     * @secure
     */
    journeyPhotoList: (params: RequestParams = {}) =>
      this.request<JourneyPhotoResponse, void>({
        path: `/journey_photo`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyPhotoCreate
     * @summary Upload a new photo for the current user
     * @request POST:/journey_photo
     * @secure
     */
    journeyPhotoCreate: (data: File, params: RequestParams = {}) =>
      this.request<JourneyPhoto, any>({
        path: `/journey_photo`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyPhotoDelete
     * @summary deletes a specific photo
     * @request DELETE:/journey_photo/{photo_id}
     * @secure
     */
    journeyPhotoDelete: (photoId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/journey_photo/${photoId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  journeyProgram = {
    /**
     * No description
     *
     * @tags program
     * @name JourneyProgramList
     * @summary Return a list of JourneyPrograms
     * @request GET:/journey_program
     * @secure
     */
    journeyProgramList: (
      query?: {
        /** filters based off of the state of the journey program.  Default is to include `active` only.  Sending `state=all` will return all entries (`active`, `inactive`, and `complete`) */
        state?: 'all' | 'active' | 'inactive' | 'complete'
      },
      params: RequestParams = {},
    ) =>
      this.request<JourneyProgramResponse, any>({
        path: `/journey_program`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags program
     * @name JourneyProgramCreate
     * @summary Start a new program for the current user
     * @request POST:/journey_program
     * @secure
     */
    journeyProgramCreate: (
      data: JourneyProgramNoId,
      params: RequestParams = {},
    ) =>
      this.request<JourneyProgram, ApiError>({
        path: `/journey_program`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags program
     * @name ControllersJourneyProgramGet
     * @summary Retrieve the active program for the  logged on user.
     * @request GET:/journey_program/active
     * @deprecated
     * @secure
     */
    controllersJourneyProgramGet: (params: RequestParams = {}) =>
      this.request<JourneyProgram, void>({
        path: `/journey_program/active`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags program
     * @name JourneyProgramPartialUpdate
     * @summary Modify a JourneyProgram.  Accepts two types of modifications `withdrawal_reason_id` (to withdrawal from a program) and `program_workouts` (to modify the order of the program workouts within a week)
     * @request PATCH:/journey_program/{journey_program_id}
     * @secure
     */
    journeyProgramPartialUpdate: (
      journeyProgramId: number,
      data: JourneyProgramPatch,
      params: RequestParams = {},
    ) =>
      this.request<JourneyProgram, void>({
        path: `/journey_program/${journeyProgramId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  journeyProgramReason = {
    /**
     * No description
     *
     * @tags program
     * @name JourneyProgramReasonList
     * @summary Return reasons for withdrawing from a program
     * @request GET:/journey_program_reason
     */
    journeyProgramReasonList: (params: RequestParams = {}) =>
      this.request<JourneyProgramReasonResponse, any>({
        path: `/journey_program_reason`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  journeyWeight = {
    /**
     * No description
     *
     * @tags journey
     * @name JourneyWeightList
     * @summary Return all weight entries for the current user
     * @request GET:/journey_weight
     * @secure
     */
    journeyWeightList: (params: RequestParams = {}) =>
      this.request<JourneyWeightResponse, void>({
        path: `/journey_weight`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyWeightCreate
     * @summary Create a new weight record for the current user
     * @request POST:/journey_weight
     * @secure
     */
    journeyWeightCreate: (
      data: JourneyWeightNoId,
      params: RequestParams = {},
    ) =>
      this.request<JourneyWeight, any>({
        path: `/journey_weight`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyWeightDelete
     * @summary deletes a weight entry
     * @request DELETE:/journey_weight/{weight_id}
     * @secure
     */
    journeyWeightDelete: (weightId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/journey_weight/${weightId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  journeyWorkout = {
    /**
     * No description
     *
     * @tags journey
     * @name JourneyWorkoutList
     * @summary Return all workout entries for the current user
     * @request GET:/journey_workout
     * @secure
     */
    journeyWorkoutList: (params: RequestParams = {}) =>
      this.request<JourneyWorkoutResponse, void>({
        path: `/journey_workout`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyWorkoutCreate
     * @summary Create a new workout record for the current user
     * @request POST:/journey_workout
     * @secure
     */
    journeyWorkoutCreate: (
      data: JourneyWorkoutNoId,
      params: RequestParams = {},
    ) =>
      this.request<JourneyWorkout, any>({
        path: `/journey_workout`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journey
     * @name JourneyWorkoutDelete
     * @summary deletes a specific workout
     * @request DELETE:/journey_workout/{workout_id}
     * @secure
     */
    journeyWorkoutDelete: (workoutId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/journey_workout/${workoutId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  log = {
    /**
     * @description Log enries
     *
     * @tags log
     * @name GetLog
     * @summary return a sequential log of all entries for underlying tables used for Realm synchronization
     * @request GET:/log
     */
    getLog: (
      query?: {
        /** limit results to all entries after the ID specified */
        after_id?: number
        /** limit results to entries with the given model */
        model?: string
        /** limit results to entries with the given model */
        method?: 'create' | 'write' | 'unlink'
        /** only return the specific log entry matching the given `res_id` (ie. resource ID) */
        res_id?: number
      },
      params: RequestParams = {},
    ) =>
      this.request<LogResponse, any>({
        path: `/log`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description A log entry
     *
     * @tags log
     * @name GetLog2
     * @summary Retrieve a specific log entry, used for Realm synchronization
     * @request GET:/log/{log_id}
     * @originalName getLog
     * @duplicate
     */
    getLog2: (logId: number, params: RequestParams = {}) =>
      this.request<Log, any>({
        path: `/log/${logId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  program = {
    /**
     * @description Retrieves the list of all programs
     *
     * @tags program
     * @name ProgramList
     * @summary Retrieves the list of all programs
     * @request GET:/program
     * @deprecated
     */
    programList: (
      query?: {
        /** filter based off of the superset type.  `true` will return all superset types, `false` will only return supersets where `type=normal` */
        show_all_superset_types?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<ProgramResponse, any>({
        path: `/program`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single program
     *
     * @tags program
     * @name ProgramDetail
     * @summary Retrieve a single program
     * @request GET:/program/{program_id}
     * @deprecated
     */
    programDetail: (
      programId: string,
      query?: {
        /** filter based off of the superset type.  `true` will return all superset types, `false` will only return supersets where `type=normal` */
        show_all_superset_types?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<Program, void>({
        path: `/program/${programId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  stripe = {
    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeGetAddress
     * @summary View the billing address for the logged on user
     * @request GET:/stripe/address
     * @secure
     */
    controllersStripeGetAddress: (params: RequestParams = {}) =>
      this.request<AddressRecord, void>({
        path: `/stripe/address`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripePutAddress
     * @summary Update the billing address for the logged on user
     * @request PUT:/stripe/address
     * @secure
     */
    controllersStripePutAddress: (
      data: AddressRecord,
      params: RequestParams = {},
    ) =>
      this.request<AddressRecord, void>({
        path: `/stripe/address`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeGetCustomer
     * @summary Return customer information
     * @request GET:/stripe/customer
     * @secure
     */
    controllersStripeGetCustomer: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/stripe/customer`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripePatchCustomer
     * @summary Modify a customer
     * @request PATCH:/stripe/customer
     * @secure
     */
    controllersStripePatchCustomer: (
      data: PatchCustomerRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/stripe/customer`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeSearchInvoice
     * @summary Get invoices for the logged on user
     * @request GET:/stripe/invoice
     * @secure
     */
    controllersStripeSearchInvoice: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/stripe/invoice`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeDeletePaymentMethod
     * @summary Deletes a payment method
     * @request DELETE:/stripe/payment_method/{payment_method_id}
     * @secure
     */
    controllersStripeDeletePaymentMethod: (
      paymentMethodId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/stripe/payment_method/${paymentMethodId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeSearchPrice
     * @summary Return active price records for all products
     * @request GET:/stripe/price
     */
    controllersStripeSearchPrice: (
      query?: {
        /** Id of the coupon to be applied */
        coupon_id?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<StripePriceResponse, void>({
        path: `/stripe/price`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeSearchSetupIntent
     * @summary List setup intents
     * @request GET:/stripe/setup_intent
     * @secure
     */
    controllersStripeSearchSetupIntent: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/stripe/setup_intent`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripePostSetupIntent
     * @summary Generate a setup intent for future billing
     * @request POST:/stripe/setup_intent
     * @secure
     */
    controllersStripePostSetupIntent: (
      data: SetupIntentRequest,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/stripe/setup_intent`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeGetSetupIntent
     * @summary Retrieve details for an existing setup intent used for future billing
     * @request GET:/stripe/setup_intent/{setup_intent_id}
     * @secure
     */
    controllersStripeGetSetupIntent: (
      setupIntentId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/stripe/setup_intent/${setupIntentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeSearchSubscription
     * @summary View all active subscriptions
     * @request GET:/stripe/subscription
     * @secure
     */
    controllersStripeSearchSubscription: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/stripe/subscription`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripePostSubscription
     * @summary Create a subscription
     * @request POST:/stripe/subscription
     * @secure
     */
    controllersStripePostSubscription: (
      data: PostSubscriptionRequest,
      params: RequestParams = {},
    ) =>
      this.request<PostSubscriptionResponse, void>({
        path: `/stripe/subscription`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeDeleteSubscription
     * @summary Cancels a subscription
     * @request DELETE:/stripe/subscription/{subscription_id}
     * @secure
     */
    controllersStripeDeleteSubscription: (
      subscriptionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/stripe/subscription/${subscriptionId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags stripe
     * @name ControllersStripeGetSubscription
     * @summary Gets details on a specific subscription
     * @request GET:/stripe/subscription/{subscription_id}
     * @secure
     */
    controllersStripeGetSubscription: (
      subscriptionId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/stripe/subscription/${subscriptionId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
  }
  user = {
    /**
     * @description Retrieves the list of all users
     *
     * @tags user
     * @name UserList
     * @summary Retrieves the list of all users matching the search criteria
     * @request GET:/user
     */
    userList: (
      query?: {
        /** email address to filter on */
        email?: string
        /** Mobile phone to filter on in E164 format with URL encoding (ie. the + should be encoded as %2B) */
        mobile?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<UserResponse, any>({
        path: `/user`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a new user
     *
     * @tags user
     * @name UserCreate
     * @summary Create a new user
     * @request POST:/user
     */
    userCreate: (data: UserNoId, params: RequestParams = {}) =>
      this.request<UserIdAndToken, void>({
        path: `/user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieves all challenges for the logged in user
     *
     * @tags challenge
     * @name ControllersUserChallengeSearch
     * @summary Retrieves all challenges for the logged in user
     * @request GET:/user/challenge
     * @secure
     */
    controllersUserChallengeSearch: (
      query?: {
        /** The number of items to skip before starting to collect the result set */
        offset?: number
        /** The numbers of items to return */
        limit?: number
        /** Only return records with a `write_date` occurring on or after the since date.  Format as YYYY-MM-DD HH:MM:SS (space must be properly URL encoded with %20 or +) */
        since?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<ChallengeResponse, any>({
        path: `/user/challenge`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Join a challenge
     *
     * @tags challenge
     * @name ControllersUserChallengePost
     * @summary Join a challenge
     * @request POST:/user/challenge
     * @secure
     */
    controllersUserChallengePost: (
      data: ChallengeCreate,
      params: RequestParams = {},
    ) =>
      this.request<Challenge, any>({
        path: `/user/challenge`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenge
     * @name ControllersUserChallengeDelete
     * @summary unsubscribes from the challenge
     * @request DELETE:/user/challenge/{challenge_id}
     * @secure
     */
    controllersUserChallengeDelete: (
      challengeId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, void>({
        path: `/user/challenge/${challengeId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve a specific subscribed UserChallenge
     *
     * @tags challenge
     * @name ControllersUserChallengeGet
     * @summary Retrieve a specific UserChallenge
     * @request GET:/user/challenge/{challenge_id}
     * @secure
     */
    controllersUserChallengeGet: (
      challengeId: string,
      params: RequestParams = {},
    ) =>
      this.request<Challenge, void>({
        path: `/user/challenge/${challengeId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags challenge
     * @name ControllersUserChallengePatch
     * @summary Modify a UserChallenge
     * @request PATCH:/user/challenge/{challenge_id}
     * @secure
     */
    controllersUserChallengePatch: (
      challengeId: number,
      data: ChallengePatch,
      params: RequestParams = {},
    ) =>
      this.request<Challenge, void>({
        path: `/user/challenge/${challengeId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserDelete
     * @summary deletes the currently logged on user
     * @request DELETE:/user/me
     * @secure
     */
    controllersUserDelete: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user/me`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserGet
     * @summary Retrieve a user record for the currently logged on user
     * @request GET:/user/me
     * @secure
     */
    controllersUserGet: (params: RequestParams = {}) =>
      this.request<User, void>({
        path: `/user/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserPatch
     * @summary Partially updates the record of the current user.  Only send the fields that need to be modified
     * @request PATCH:/user/me
     * @secure
     */
    controllersUserPatch: (data: UserNoId, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/user/me`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserGetMePhoto
     * @summary The profile photo for the current user
     * @request GET:/user/me/photo
     * @secure
     */
    controllersUserGetMePhoto: (
      query?: {
        /** How to encode the response, binary is default */
        encoding?: 'base64' | 'binary'
      },
      params: RequestParams = {},
    ) =>
      this.request<File, any>({
        path: `/user/me/photo`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'blob',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserPostMePhoto
     * @summary Upload a new profile photo for the current user
     * @request POST:/user/me/photo
     * @secure
     */
    controllersUserPostMePhoto: (data: File, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/me/photo`,
        method: 'POST',
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserGetMeStats
     * @summary The annual statistics for current user
     * @request GET:/user/me/stats/{year}
     * @secure
     */
    controllersUserGetMeStats: (year: string, params: RequestParams = {}) =>
      this.request<UserStats, void>({
        path: `/user/me/stats/${year}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserSetPassword
     * @summary Assign a new password to the user identified by the token sent via email
     * @request POST:/user/set_password
     */
    controllersUserSetPassword: (
      data: SetPassword,
      params: RequestParams = {},
    ) =>
      this.request<JWTToken, void>({
        path: `/user/set_password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserPostVerifyReceipt
     * @summary Upload the current receipt for app store validation
     * @request POST:/user/verifyReceipt
     * @secure
     */
    controllersUserPostVerifyReceipt: (
      data: VerifyReceipt,
      params: RequestParams = {},
    ) =>
      this.request<User, any>({
        path: `/user/verifyReceipt`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name ControllersUserPasswordReset
     * @summary Triggers an email with a reset password link for resetting the user's password
     * @request POST:/user/{email}/password_reset
     */
    controllersUserPasswordReset: (email: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/user/${email}/password_reset`,
        method: 'POST',
        ...params,
      }),
  }
  userJournal = {
    /**
     * @description Retrieves all journal entries for the logged in user
     *
     * @tags journal
     * @name UserJournalList
     * @summary Retrieves all journal entries for the logged in user
     * @request GET:/user_journal
     * @secure
     */
    userJournalList: (
      query?: {
        /** The number of items to skip before starting to collect the result set */
        offset?: number
        /** The numbers of items to return */
        limit?: number
        /** Only return records with a `write_date` occurring on or after the since date.  Format as YYYY-MM-DD HH:MM:SS (space must be properly URL encoded with %20 or +) */
        since?: string
      },
      params: RequestParams = {},
    ) =>
      this.request<JournalResponse, any>({
        path: `/user_journal`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * @description Create a journal entry
     *
     * @tags journal
     * @name UserJournalCreate
     * @summary Create a journal entry
     * @request POST:/user_journal
     * @secure
     */
    userJournalCreate: (data: JournalCreate, params: RequestParams = {}) =>
      this.request<Journal, any>({
        path: `/user_journal`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journal
     * @name UserJournalDelete
     * @summary deletes a journal entry
     * @request DELETE:/user_journal/{journal_id}
     * @secure
     */
    userJournalDelete: (journalId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/user_journal/${journalId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Retrieve a single journal entry
     *
     * @tags journal
     * @name UserJournalDetail
     * @summary Retrieve a single journal entry
     * @request GET:/user_journal/{journal_id}
     * @secure
     */
    userJournalDetail: (journalId: string, params: RequestParams = {}) =>
      this.request<Journal, void>({
        path: `/user_journal/${journalId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags journal
     * @name UserJournalPartialUpdate
     * @summary Modify a journal entry
     * @request PATCH:/user_journal/{journal_id}
     * @secure
     */
    userJournalPartialUpdate: (
      journalId: number,
      data: JournalPatch,
      params: RequestParams = {},
    ) =>
      this.request<Journal, void>({
        path: `/user_journal/${journalId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  workout = {
    /**
     * @description Retrieves the list of all workouts
     *
     * @tags workout
     * @name WorkoutList
     * @summary Retrieves the list of all workouts
     * @request GET:/workout
     * @deprecated
     */
    workoutList: (
      query?: {
        /** filter based off of the superset type.  `true` will return all superset types, `false` will only return supersets where `type=normal` */
        show_all_superset_types?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<WorkoutResponse, any>({
        path: `/workout`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description Retrieve a single workout
     *
     * @tags workout
     * @name WorkoutDetail
     * @summary Retrieve a single workout
     * @request GET:/workout/{workout_id}
     * @deprecated
     */
    workoutDetail: (
      workoutId: string,
      query?: {
        /** filter based off of the superset type.  `true` will return all superset types, `false` will only return supersets where `type=normal` */
        show_all_superset_types?: boolean
      },
      params: RequestParams = {},
    ) =>
      this.request<Workout, void>({
        path: `/workout/${workoutId}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
}

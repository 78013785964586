import { useForm } from 'react-hook-form'
import type { AnyObjectSchema } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { UseFormProps, UseFormReturn, FieldValues } from 'react-hook-form'

interface UseCustomFormProps extends UseFormProps {
  schema: AnyObjectSchema
}

export const useCustomForm = <TFormValues extends FieldValues = FieldValues>({
  schema,
  ...rest
}: UseCustomFormProps): UseFormReturn => {
  return useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    ...rest,
  })
}

import React, { useState } from 'react'
import { useMst } from '~stores'
import { AccountPageCard } from '~components/pages'
import { LoginIcon } from '~svg'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'

const AccountCardLogin = ({ className = '' }: { className?: string }) => {
  const { userStore } = useMst()
  const router = useRouter()

  const handleEditClick = () => {
    router.push('/account/info')
  }

  return (
    <>
      <AccountPageCard.Box
        title={'Account login'}
        className={`col-span-3 ${className}`}
        actions={[
          {
            label: 'Edit',
            onClick: handleEditClick,
          },
        ]}
        empty={{
          Icon: <LoginIcon />,
          text: 'Please add your email and password.',
        }}
      >
        {userStore.userData?.email ? (
          <AccountPageCard.Text>
            {userStore.userData?.email} <br />
            {userStore.userData?.password_type === 'user' ? '••••••••••' : null}
          </AccountPageCard.Text>
        ) : null}
      </AccountPageCard.Box>
    </>
  )
}

export default observer(AccountCardLogin)

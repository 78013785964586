import React from 'react'
import { Grid as GridComp } from '~components'
import css from './Hero.module.scss'

interface PageCircleHeroProps {
  title?: string
  backgroundImage?: string
  circleClassName?: string
  centered?: boolean
}

const Circle = ({
  src,
  className,
  large,
  removeMobile,
  invert,
}: {
  src?: string
  className?: string
  large?: boolean
  removeMobile?: boolean
  invert?: boolean
}) => {
  return (
    <div
      className={`${css.circleWrapper} ${className ? className : ''} ${
        large ? css.large : ''
      } ${removeMobile ? css.removeMobile : ''} ${invert ? css.invert : ''}`}
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={src} className={css.circle} alt="Alive background image" />
    </div>
  )
}

const Grid: React.FC<PageCircleHeroProps> = ({
  children,
  backgroundImage,
  circleClassName,
  centered,
}) => {
  return (
    <div className={css.container}>
      <div className={css.childrenContainer}>
        <GridComp
          className={[css.grid, centered ? css.centered : ''].join(' ')}
        >
          {children}
        </GridComp>
      </div>
    </div>
  )
}

const Hero = {
  Grid,
  Circle,
}

export default Hero

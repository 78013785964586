import NextLink from 'next/link'
import type { ReactNode } from 'react'
import type { LinkProps as NexLinkProps } from 'next/link'

interface LinkProps extends NexLinkProps {
  children: ReactNode
  className?: string
  target?: string
}

const Link = ({ href, children, ...rest }: LinkProps) => {
  return (
    <NextLink href={href} {...rest}>
      {children}
    </NextLink>
  )
}

export default Link

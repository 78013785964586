import React from 'react'
import { observer } from 'mobx-react'
import { RadioGroup } from '@headlessui/react'
import PricingTableItem, { PricingTableItemProps } from './PricingTableItem'

export interface PricingTableItemRadioProps extends PricingTableItemProps {
  value: string
  isDisabled?: boolean
  addCurrent?: 'Monthly' | 'Yearly'
}

const PricingTableItemRadio: React.FC<PricingTableItemRadioProps> = ({
  value,
  checked,
  addCurrent,
  isDisabled,
  ...restProps
}) => {
  return (
    <RadioGroup.Option
      value={value}
      className={'outline-0 outline-hidden flex-1'}
      disabled={isDisabled}
    >
      {({ checked }) => (
        <PricingTableItem
          {...restProps}
          checked={checked}
          addCurrent={addCurrent}
          isDisabled={isDisabled}
        />
      )}
    </RadioGroup.Option>
  )
}

export default observer(PricingTableItemRadio)

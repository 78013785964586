import React from 'react'
import { CenteredPage, CenteredPageContainer } from '~components/pages'
import { Layout, Link, Typography } from '~components/index'
import { ACTION } from 'mobx/dist/api/action'
import { act } from 'react-dom/test-utils'

interface ErrorPageProps {
  title?: string
  subtitle?: string
  caption?: string
  action?: {
    text: string
    function: () => void
  }
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  title,
  subtitle,
  caption,
  action,
}) => {
  return (
    <Layout loading={false} authorized={false}>
      <CenteredPage>
        <CenteredPageContainer>
          {title ? (
            <Typography.Title className={'text-stroke'}>
              {title}
            </Typography.Title>
          ) : null}
          {subtitle ? (
            <Typography.Title level={'h3'} className={'text-blue'}>
              {subtitle}
            </Typography.Title>
          ) : null}
          {caption ? <Typography.Text>{caption}</Typography.Text> : null}

          {action ? (
            <div className={'mt-7'}>
              <a className={'link text-blue copy'} onClick={action.function}>
                {action.text}
              </a>
            </div>
          ) : null}
        </CenteredPageContainer>
      </CenteredPage>
    </Layout>
  )
}

export default ErrorPage

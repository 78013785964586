import React, { use, useState, useEffect } from 'react'
import currency from 'currency.js'
import { useMst } from '~stores'
import { Logger, formatDateNumber, formatPrice } from '~utils'
import { Notify } from 'notiflix'
import { observer } from 'mobx-react'
import * as Sentry from '@sentry/react'
import { Button, FormError, Summary } from '~components'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import classNames from 'classnames'
import { IUserSubscriptionPlatform } from '~stores/user'
import { Checkbox } from '~svg'

const logger = Logger('PaymentForm', { nsBackground: 'yellow' })

type SummaryValues = {
  type: string
  date: string
  price: currency
  tax: currency
  total: currency
}

interface PaymentFormProps {
  white?: boolean
  update?: boolean
  change?: boolean
  submitButtonTexts?: {
    disabled?: string
    submit: string
    loading: string
  }
  newUser?: boolean
  newPayment?: boolean
  setNewPayment?: React.Dispatch<React.SetStateAction<boolean>>
  summaryValues?: SummaryValues
  noSummary?: boolean
  beforeSubmit?: () => void
  onSubmitCallback?: (
    success: boolean,
    previousSubscriptionPlatform: IUserSubscriptionPlatform | null,
  ) => void
}

const PaymentForm = ({
  beforeSubmit,
  onSubmitCallback,
  white = true,
  update = false,
  change = false,
  summaryValues = {
    type: '',
    date: '',
    price: currency(0, { precision: 2 }),
    tax: currency(0, { precision: 2 }),
    total: currency(0, { precision: 2 }),
  },
  noSummary,
  newUser,
  newPayment,
  setNewPayment,
  submitButtonTexts = {
    disabled: 'Select',
    submit: 'Pay',
    loading: 'Paying',
  },
}: PaymentFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const { userStore, stripeStore } = useMst()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<{ message: string }>()
  const [paymentChanged, setpaymentChanged] = useState(false)

  const handleChange = (event) => {
    setpaymentChanged(event.complete)
    // You can perform additional actions here based on the event
  }

  useEffect(() => {
    if (!stripeStore.elementsClientSecret) {
      // Reload the component after a short delay
      setTimeout(() => {
        window.location.reload()
      }, 1000) // 1 second delay before reload
    }
  }, [stripeStore.elementsClientSecret])

  if (!stripeStore.elementsClientSecret) {
    return <div>Loading Stripe Elements...</div>
  }

  const onSubmit = async (event) => {
    event.preventDefault()

    // Taken from https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-submit-payment
    setError(null)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setLoading(true)
    if (beforeSubmit) await beforeSubmit()

    const previousSubscriptionPlatform =
      userStore.userData?.subscription_platform?.concat() ||
      (null as IUserSubscriptionPlatform)

    const confirmSetup = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })
    logger.log('[confirmSetup]', confirmSetup)

    if (confirmSetup.error) {
      setLoading(false)
      setError({ message: confirmSetup.error.message })
      Notify.failure('Something went wrong.')

      if (onSubmitCallback)
        onSubmitCallback(
          false,
          previousSubscriptionPlatform as IUserSubscriptionPlatform,
        )
      return
    } else {
      /**
       * Update the customer so we can set the payment method as the 'default'
       */
      const patchCustomer = await stripeStore.patchCustomer(
        confirmSetup.setupIntent.payment_method,
      )
      logger.log('[patchCustomer]', patchCustomer)

      /**
       * Update the billing Address before, so it matches in case we need a subscription.
       */
      const billingAddress = await stripeStore.putBillingAddress(
        patchCustomer.customer.default_payment_method.billing_details.address,
      )
      logger.log('[billingAddress]', billingAddress)

      if (onSubmitCallback) {
        await onSubmitCallback(
          true,
          previousSubscriptionPlatform as IUserSubscriptionPlatform,
        )
      }
    }
  }

  return (
    <form
      onSubmit={onSubmit}
      className={classNames({ 'bg-white py-3 rounded-[10px]': white })}
    >
      <div className={'mb-4 lg:mb-6'}>
        {!update && (
          <h3 className="h2 mt-10 mb-5 text-blue">Payment information</h3>
        )}

        {change ? (
          stripeStore.elementsClientSecret ? (
            <div>
              <div className="rounded-[20px] px-5 py-5 border border-[#c4c4c4] mb-5">
                <div
                  className="flex items-center cursor-pointer"
                  role="button"
                  onClick={() => setNewPayment && setNewPayment(false)}
                >
                  <div
                    className={`rounded-full h-[22px] w-[22px] border border-black/10 ${
                      newPayment ? '' : 'bg-blue border-0'
                    }`}
                  >
                    {newPayment ? '' : <Checkbox />}
                  </div>
                  <div className="ml-6">
                    <h4 className="h4">Pay with saved method</h4>
                    <div className="uppercase copy-2">
                      {stripeStore.customer?.readablePaymentMethod?.brand}{' '}
                      {stripeStore.customer?.readablePaymentMethod?.lastFour}
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-[20px] px-5 py-5 border border-[#c4c4c4]">
                <div
                  className="flex items-center cursor-pointer"
                  role="button"
                  onClick={() => setNewPayment && setNewPayment(true)}
                >
                  <div
                    className={`rounded-full h-[22px] w-[22px] border border-black/10 ${
                      newPayment ? 'bg-blue border-0' : ''
                    }`}
                  >
                    {newPayment ? <Checkbox /> : ''}
                  </div>
                  <div className="ml-6">
                    <h4 className="h4">New payment method</h4>
                  </div>
                </div>
                {newPayment ? (
                  <div className="border-t border-black/10 pt-2 mt-4">
                    <PaymentElement onChange={handleChange} />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null
        ) : !newPayment || update ? (
          stripeStore.elementsClientSecret ? (
            <PaymentElement onChange={handleChange} />
          ) : null
        ) : null}
      </div>
      <Summary hide={noSummary} summaryValues={summaryValues} />
      <FormError error={error} />

      {change ? (
        newPayment && (
          <Button
            type={'submit'}
            className="new"
            loading={loading}
            disabled={!paymentChanged || (summaryValues.type === '' && !update)}
          >
            {summaryValues.type === ''
              ? submitButtonTexts.disabled
              : loading
              ? submitButtonTexts.loading
              : submitButtonTexts.submit}
          </Button>
        )
      ) : (
        <Button
          type={'submit'}
          className="new"
          loading={loading}
          disabled={!paymentChanged || (summaryValues.type === '' && !update)}
        >
          {summaryValues.type === ''
            ? submitButtonTexts.disabled
            : loading
            ? submitButtonTexts.loading
            : submitButtonTexts.submit}
        </Button>
      )}
    </form>
  )
}
export default observer(PaymentForm)

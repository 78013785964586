import React from 'react'
import { Transition as HeadlessTransition } from '@headlessui/react'

const Transition = ({ children, ...rest }: any) => {
  return (
    <HeadlessTransition
      enter="transition ease-out duration-50"
      enterFrom="absolute inset-0 transform opacity-0 scale-90"
      enterTo="relative transform opacity-100 scale-100"
      leave="transition ease-in duration-50"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="absolute inset-0 opacity-0 scale-110"
      {...rest}
    >
      {children}
    </HeadlessTransition>
  )
}

export default Transition

import cookies from 'js-cookie'
import {ALIVE_COUPON_ID, LOCAL_STORAGE_TOKEN_KEY} from '~utils/constants'


export const CookiesService = {
    getCookie: (name: string) => {
        return cookies.get(name)
    },
    setCookie: (name: string, value: string) => {
        cookies.set(name, value)
    },
    getCoupon: () => {
        return cookies.get(ALIVE_COUPON_ID)
    },
    setCoupon: (id: string) => {
        cookies.set(ALIVE_COUPON_ID, id)
    },
    removeCoupon: () => {
        cookies.remove(ALIVE_COUPON_ID)
    },
    setToken: (token: string) => {
        cookies.set(LOCAL_STORAGE_TOKEN_KEY, token)
    },
    removeToken: () => {
        cookies.remove(LOCAL_STORAGE_TOKEN_KEY)
    },
    getToken: () => {
        return cookies.get(LOCAL_STORAGE_TOKEN_KEY)
    },
}

import { SVGComponent } from '~types'

const LockIcon = (props: SVGComponent) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <rect x="2" y="7" width="13" height="12" rx="2" fill="#2576FF" />
      <rect
        x="5"
        y="2"
        width="7"
        height="8"
        rx="1"
        stroke="#2576FF"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default LockIcon

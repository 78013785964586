import React, { useState } from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { ProfileIcon } from '~svg'
import { AccountPageCard } from '~components/pages'
import { useRouter } from 'next/router'

const AccountCardProfile = ({ className = '' }: { className?: string }) => {
  const { userStore } = useMst()
  const router = useRouter()

  const handleEditClick = () => {
    router.push('/account/profile')
  }

  const isProfileEmpty =
    !userStore.userData?.last_name &&
    !userStore.userData?.mobile &&
    userStore.userData?.first_name === '*'

  const actionLabel = isProfileEmpty ? 'Add' : 'Edit'

  return (
    <>
      <AccountPageCard.Box
        title={'Profile'}
        className={`col-span-3 ${className}`}
        actions={[
          {
            label: actionLabel,
            onClick: handleEditClick,
          },
        ]}
      >
        {userStore.userData ? (
          <AccountPageCard.Text>
            {isProfileEmpty ? (
              <div className="text-black copy-2">No name or number</div>
            ) : (
              <>
                {userStore.userData.first_name === '*' ? null : (
                  <span className="text-blue h4">
                    {userStore.userData.first_name}
                  </span>
                )}
                {userStore.userData.last_name && (
                  <span className="text-blue h4">
                    {` `}
                    {userStore.userData.last_name}
                  </span>
                )}
                {userStore.userData.mobile && (
                  <div className="text-blue h4">
                    {userStore.userData.mobileWithFormat}
                  </div>
                )}
              </>
            )}
          </AccountPageCard.Text>
        ) : null}
      </AccountPageCard.Box>
    </>
  )
}

export default observer(AccountCardProfile)

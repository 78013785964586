import React, { useMemo } from 'react'
import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas'

interface LoadingIndicatorProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  className,
  ...rest
}: LoadingIndicatorProps) => {
  const { RiveComponent } = useRive({
    src: '/loader.riv',
    autoplay: true,
    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.TopLeft,
    }),
  })

  return (
    <div
      {...rest}
      style={{ width: '160px', height: '160px' }}
      className={`${className ? className : ''} pointer-events-none`}
    >
      <div style={{ width: '160px', height: '160px' }}>
        <RiveComponent />
      </div>
    </div>
  )
}

export default LoadingIndicator

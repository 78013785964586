import { SVGComponent } from '~types'

const MasterCard = (props: SVGComponent) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 324 228">
    <path
      fill="#36495D"
      d="M293.28 227.12H30.53c-16.5 0-29.88-13.38-29.88-29.88V29.88C.65 13.38 14.03 0 30.53 0h262.75c16.5 0 29.88 13.38 29.88 29.88v167.35c0 16.51-13.38 29.89-29.88 29.89Z"
    />
    <path fill="#F16522" d="M188.991 64.87h-54.18v97.37h54.18V64.87Z" />
    <path
      fill="#E41B24"
      d="M138.251 113.56c0-19.75 9.25-37.34 23.65-48.68-10.54-8.29-23.82-13.24-38.27-13.24-34.2 0-61.92 27.73-61.92 61.93s27.72 61.93 61.92 61.93c14.45 0 27.73-4.95 38.27-13.24-14.4-11.36-23.65-28.95-23.65-48.7Z"
    />
    <path
      fill="#F89E1C"
      d="M262.09 113.55c0 34.2-27.72 61.93-61.92 61.93-14.45 0-27.73-4.95-38.27-13.24 14.4-11.34 23.65-28.93 23.65-48.68s-9.25-37.34-23.65-48.68a61.654 61.654 0 0 1 38.27-13.24c34.2-.01 61.92 27.72 61.92 61.91Zm-5.91 38.38v-1.99h.8v-.4h-2.05v.4h.8v1.99h.45Zm3.98 0v-2.4h-.63l-.72 1.65-.72-1.65h-.63v2.4h.44v-1.81l.68 1.57h.46l.68-1.57v1.82h.44v-.01Z"
    />
  </svg>
)

export default MasterCard

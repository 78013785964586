import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useMst } from '~stores'
import { Notify } from 'notiflix'
import { Transition, Button, ButtonGroup, Modal, Typography } from '~components'
import { ModalProps } from '~components/Modal/Modal'
import { PaymentForm } from '~components/pages'
import { SentryCaptureException } from '~utils'
import { useRouter } from 'next/router'

type AccountPaymentModalProps = Omit<ModalProps, 'children'>

const AccountPaymentSuccessAlert = ({ title }) => {
  return (
    <>
      <h3
        style={{ textAlign: 'center' }}
        className={'h3 text-center text-blue'}
      >
        {title}
      </h3>
    </>
  )
}

const AccountPaymentDeleteAlert = ({
  onConfirm,
  onCancel,
  loading,
  lastFour,
}) => {
  return (
    <>
      <h1 className={'h2 text-blue mb-2'}>
        Are you sure you want to remove the card ending in {lastFour}?
      </h1>
      <div className={'copy-2 mb-6'}>
        If you remove your card, you'll need to re-enter payment details to
        subscribe again. This will stop any active subscription from renewing,
        ending access to premium features. Your Alive data will remain stored in
        your account if you resubscribe later.
      </div>
      <div className="flex gap-[4%] justify-center flex-wrap">
        <div className="flex-[1_0_48%] mb-4">
          <Button onClick={onConfirm} loading={loading}>
            Yes, remove this card
          </Button>
        </div>
        <div className="flex-[1_0_48%]">
          <Button onClick={onCancel} theme={'inverted'} disabled={loading}>
            No
          </Button>
        </div>
      </div>
    </>
  )
}

const AccountPaymentModal: React.FC<AccountPaymentModalProps> = ({
  onClose,
  open,
}) => {
  const rootStore = useMst()
  const { stripeStore, userStore } = rootStore
  const [loading, setLoading] = useState(false)
  const [newPayment, setNewPayment] = useState(true)
  const [step, setStep] = useState<
    | 'payment_information'
    | 'payment'
    | 'payment_confirm'
    | 'delete'
    | 'delete_confirm'
  >(
    stripeStore.customer?.readablePaymentMethod
      ? 'payment_information'
      : 'payment',
  )
  const router = useRouter()

  const onSubmitCallback = async (success) => {
    if (success) {
      await rootStore.hydrate()
      router.push('/account')
      Notify.success('Payment info updated')
    }
  }

  const onDeleteConfirm = async () => {
    try {
      setLoading(true)
      await stripeStore.deletePaymentMethod(
        stripeStore.customer.default_payment_method.id,
      )
      setLoading(false)
      await rootStore.hydrate()
      router.push('/account')
      Notify.success('Payment info removed')
    } catch (e) {
      SentryCaptureException(e)
      Notify.failure('An error occurred, please try again later.')
    }
  }

  switch (step) {
    case 'payment_information':
      return (
        <>
          <h1 className={'text-blue mb-6 h2'}>Update payment info</h1>

          <PaymentForm
            white
            onSubmitCallback={onSubmitCallback}
            noSummary={true}
            update={true}
            newPayment={true}
            setNewPayment={setNewPayment}
            submitButtonTexts={{
              disabled: 'Update Card',
              submit: 'Replace Card',
              loading: 'Saving',
            }}
          />

          <div className="rounded-[20px] px-5 py-5 border border-[#c4c4c4] mb-5 mt-10">
            <div>
              <h4 className="h4 mb-2">Current saved method</h4>
              <div className="uppercase copy-2">
                {stripeStore.customer?.readablePaymentMethod?.brand}{' '}
                {stripeStore.customer?.readablePaymentMethod?.lastFour}
              </div>
            </div>
            <div className="border-t border-black/10 pt-2 mt-4">
              <div className={'caption'}>
                To remove your card you will need to cancel your subscription
                first.
              </div>
            </div>
          </div>
          {userStore.subscriptionStatus === 'active' ? null : (
            <ButtonGroup>
              <Button onClick={() => setStep('delete')} theme={'inverted'}>
                Remove Card
              </Button>
            </ButtonGroup>
          )}
        </>
      )
    case 'delete':
      return (
        <>
          <AccountPaymentDeleteAlert
            lastFour={stripeStore.customer?.default_payment_method?.card.last4}
            loading={loading}
            onConfirm={onDeleteConfirm}
            onCancel={() => router.push('/account')}
          />
        </>
      )
  }
}
export default observer(AccountPaymentModal)

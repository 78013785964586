// Styles should be at the top!
import '~styles/globals.scss'
import {
  FacebookPixelScript,
  PinterestPixelScript,
  TiktokPixelScript,
  GoogleGTM,
  OutbrainPixelScript,
  Layout,
} from '~components'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import {
  Logger,
  initMixpanel,
  isPublicPath,
  isSignupOrLoginPath,
  SentryCaptureException,
  elementsAppearance,
  initNotify,
} from '~utils'
import { observer } from 'mobx-react'
import { loadStripe } from '@stripe/stripe-js'
import { connectMobxDevtools, rootStore, RootStoreContext } from '~stores'
import * as fbq from '~utils/fpixel'
import * as pintrk from '~utils/pintrk'
import * as ttq from '~utils/ttq'
import * as smoothscroll from 'smoothscroll-polyfill'
import * as Intercom from '~utils/intercom'

const logger = Logger('App', {
  nsSeparator: '',
  nsColor: 'white',
  nsBackground: '#0D66FC',
})

const authLogger = Logger('AuthCheck', {
  nsColor: 'white',
  nsBackground: 'green',
})

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

const AliveApp = ({ Component, pageProps, router }: AppProps) => {
  const [booted, setBooted] = useState(false)
  const [showContent, setShowContent] = useState(false)

  function initIntercom() {
    if (rootStore.userStore.userData) {
      logger.log('Booting Intercom with user data')
      Intercom.bootIntercom({
        name: `${rootStore.userStore.userData.first_name} ${rootStore.userStore.userData.last_name}`,
        email: rootStore.userStore.userData.email,
        user_id: rootStore.userStore.userData.id,
      })
    } else {
      logger.log('No data found, booting Intercom without user data')
      Intercom.bootIntercom()
    }
  }

  const hydrate = async () => {
    setBooted(false)
    await rootStore.hydrate()
    setBooted(true)
  }

  const hydrateWithoutToken = async () => {
    setBooted(false)
    await rootStore.hydrateWithoutToken()
    setBooted(true)
  }

  useEffect(() => {
    Intercom.loadIntercom()
  }, [])

  useEffect(() => {
    const hideContent = () => {
      setShowContent(false)
    }

    const routesAuthCheck = (url: string) => {
      fbq.pageview()
      pintrk.pageview()
      ttq.pageview()

      initNotify()

      const path = url.split('?')[0]

      const isHome = path === '/'
      const isPublic = isPublicPath(path)
      const isSignupOrLogin = isSignupOrLoginPath(path)

      const showContent = (message?: string, show = true) => {
        setShowContent(show)
        if (message) authLogger.log(`[${path}] - ${message}`)
      }

      if (rootStore.userStore.authorized) {
        if (isSignupOrLogin || isHome) {
          router.push({ pathname: '/account' })
          showContent(`User is authorized, but redirecting to /account. ⤴️`)
        } else {
          showContent(`User is authorized, and OK to be here. ✅`)
        }
      } else {
        if (isHome) {
          router.push({ pathname: '/sign-up' })
          showContent(
            `User is NOT authorized to be here, redirecting to /sign-up.`,
          )
        }
        if (isPublic || isSignupOrLogin) {
          showContent(`User is OK to be here. ✅`)
        } else {
          showContent(
            `User is NOT authorized to be here, redirecting to /login. ❌`,
          )
          router.push({
            pathname: '/sign-up',
            query: { returnUrl: router.asPath },
          })
        }
      }
    }

    const tokenVerification = async (): Promise<boolean> => {
      try {
        const { userStore } = rootStore

        const response = userStore.token
          ? await userStore.verifyStoreToken()
          : await userStore.verifyCookieToken()

        if (!response) return false
        return response.status === 204 || response.status === 200
      } catch (e) {
        onLogoutClick()
        router.push('/login')
        logger.log('Unauthorized')
        SentryCaptureException(e.response)
      }
    }

    async function init() {
      // Dev tools only if needed.
      /*if (rootStore && process.env.NODE_ENV === 'development')
                                                                                                                                                        connectMobxDevtools(rootStore)*/

      // Analytics
      initMixpanel()
      initIntercom()

      const hasValidToken = await tokenVerification()

      if (hasValidToken) await hydrate()
      else await hydrateWithoutToken()

      routesAuthCheck(router.asPath)

      router.events.on('routeChangeStart', hideContent)
      router.events.on('routeChangeComplete', routesAuthCheck)
    }

    smoothscroll.polyfill()
    init()

    return () => {
      router.events.off('routeChangeStart', hideContent)
      router.events.off('routeChangeComplete', routesAuthCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onRetryClick = async () => {
    window.location.reload()
  }

  const onLogoutClick = async () => {
    Intercom.shutdown()
    rootStore.logOut()
  }

  useEffect(() => {
    // Force show content after 5 seconds to prevent infinite loading
    const timeoutId = setTimeout(() => {
      if (!showContent) {
        setShowContent(true)
        router.push('/login')
        logger.log('Forced content display after timeout')
      }
    }, 5000)

    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showContent])

  return (
    <>
      <Head>
        <title>Alive App</title>
        <meta name="description" content="Alive App" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <FacebookPixelScript />
      <PinterestPixelScript />
      <TiktokPixelScript />
      <OutbrainPixelScript />
      <GoogleGTM />

      <RootStoreContext.Provider value={rootStore}>
        <Layout
          onLogOut={onLogoutClick}
          authorized={rootStore.userStore.authorized}
          loading={!booted || !showContent}
        >
          <Elements
            stripe={stripePromise}
            options={{
              ...elementsAppearance,
              clientSecret: rootStore.stripeStore.elementsClientSecret,
            }}
          >
            <Component {...pageProps} />
          </Elements>
        </Layout>
      </RootStoreContext.Provider>
    </>
  )
}

export default observer(AliveApp)

import React, { useState, Suspense, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useMst } from '~stores'
import { useRouter } from 'next/router'
import { Grid } from '~components'
import {
  AccountCardLogin,
  AccountCardPaymentHistory,
  AccountCardPaymentInformation,
  AccountCardProfile,
  AccountDownloadButtons,
  AccountSubscription,
  AccountSuccessModal,
  AccountToastSwitch,
} from '~components/pages'
import { useRive } from '@rive-app/react-canvas'
import { CaretRight } from '~svg'

const AccountContent = () => {
  const router = useRouter()
  const rootStore = useMst()
  const { userStore, stripeStore } = rootStore
  const [successOpen, setSuccessOpen] = useState(false)

  const { RiveComponent } = useRive({
    src: '/dashboard-alive.riv',
    autoplay: true,
  })

  return (
    <div className="pt-36 pb-32 md:pt-56 relative min-h-screen">
      <Grid className="items-stretch">
        <div className="col-span-4 md:col-start-2 md:col-span-9 xl:col-start-4 xl:col-span-6">
          <h1 className="h2 text-blue mb-8">My Subscription</h1>
        </div>
        <div
          className={
            'col-span-4 md:col-start-2 md:col-span-10 xl:col-start-4 xl:col-span-6 mb-4 xl:mb-6'
          }
        >
          {stripeStore.subscription &&
          userStore.subscriptionStatus === 'active' ? (
            <AccountSubscription
              customer={stripeStore.customer}
              subscription={stripeStore.subscription}
            />
          ) : (
            <AccountToastSwitch
              openModalFunction={() => {
                router.push('/payment')
              }}
            />
          )}
        </div>
        <div className="col-span-4 md:col-start-2 md:col-span-5 xl:col-start-4 xl:col-span-3 mb-4 md:mb-6">
          <AccountCardPaymentHistory />
        </div>
        <div className="col-span-4 md:col-span-5 xl:col-start-7 xl:col-span-3 mb-4 md:mb-6">
          <AccountCardPaymentInformation />
        </div>
        {stripeStore.subscription &&
        userStore.subscriptionStatus === 'active' ? (
          <div
            className={
              'col-span-4 md:col-start-2 md:col-span-10 xl:col-start-4 xl:col-span-6 mb-4 xl:mb-6'
            }
          >
            <div
              className="rounded-[20px] px-5 py-5 bg-light-blue mb-8 flex justify-between items-center cursor-pointer"
              onClick={() => {
                router.push('/account/cancel')
              }}
            >
              <div className="h4">Cancel your subscription</div>
              <div>
                <CaretRight />
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-span-4 md:col-start-2 md:col-span-9 xl:col-start-4 xl:col-span-6 mt-3 md:mt-0">
          <h3 className="h2 text-blue mb-8 md:mt-2">Account details</h3>
        </div>
        <div className="col-span-4 md:col-start-2 md:col-span-5 xl:col-start-4 xl:col-span-3 mb-4 md:mb-16">
          <AccountCardProfile />
        </div>
        <div className="col-span-4 md:col-span-5 xl:col-start-7 xl:col-span-3 mb-16 md:mb-16">
          <AccountCardLogin />
        </div>
        <div className="col-span-4 md:col-start-2 md:col-span-10 xl:col-start-4 xl:col-span-6">
          <div className="bg-light-grey py-10 px-4 rounded-[30px]">
            <h4 className="h4 text-center mb-4">Download the app</h4>
            <AccountDownloadButtons />
          </div>
        </div>
      </Grid>
      <div className="absolute bottom-[28px] left-0 w-full h-[24vw] z-20 pointer-events-none hidden md:block">
        <RiveComponent />
      </div>
    </div>
  )
}

export default AccountContent

const Visa = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 323 228">
    <path
      fill="#0066B2"
      d="M292.96 227.12H30.21c-16.5 0-29.88-13.38-29.88-29.88V29.88C.32 13.38 13.7 0 30.21 0h262.75c16.5 0 29.88 13.38 29.88 29.88v167.35c0 16.51-13.38 29.89-29.88 29.89Z"
    />
    <path
      fill="#fff"
      d="M147.89 149.14h-16.8l10.5-64.52h16.8l-10.5 64.52ZM116.96 84.62 100.94 129l-1.89-9.56-5.65-29.01s-.68-5.81-7.97-5.81H58.96l-.31 1.09s8.1 1.68 17.57 7.38l14.59 56.05h17.5l26.73-64.52h-18.08ZM249.09 149.14h15.42l-13.45-64.52h-13.51c-6.24 0-7.75 4.81-7.75 4.81l-25.05 59.71h17.51l3.5-9.58h21.35l1.98 9.58Zm-18.49-22.83 8.83-24.15 4.97 24.15h-13.8ZM206.06 100.13l2.401-13.86s-7.401-2.81-15.111-2.81c-8.34 0-28.13 3.65-28.13 21.36 0 16.67 23.231 16.88 23.231 25.63s-20.841 7.19-27.721 1.67l-2.5 14.48s7.5 3.65 18.96 3.65 28.761-5.94 28.761-22.09c0-16.78-23.441-18.34-23.441-25.63.01-7.3 16.37-6.36 23.55-2.4Z"
    />
    <path
      fill="#F9A533"
      d="M99.05 119.44 93.4 90.43s-.68-5.81-7.97-5.81H58.96l-.31 1.09s12.73 2.64 24.93 12.52c11.66 9.44 15.47 21.21 15.47 21.21Z"
    />
  </svg>
)

export default Visa

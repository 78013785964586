import React from 'react'
import { useMst } from '~stores'
import { getValidationSchema } from '~utils'
import { requiredEmail } from '~validations'
import {
  Button,
  Form,
  ControlledInput,
  useCustomForm,
  FormProps,
} from '~components'

const formSchema = getValidationSchema({
  email: requiredEmail,
})

interface ForgotPasswordFormValues {
  email: string
}

interface ForgotPasswordFormProps
  extends Pick<FormProps, 'error' | 'loading' | 'onSubmit'> {
  hideInput?: boolean
  type: 'reset' | 'create'
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  error,
  loading,
  hideInput,
  type,
  onSubmit,
}) => {
  const { userStore } = useMst()
  const context = useCustomForm<ForgotPasswordFormValues>({
    schema: formSchema,
    defaultValues: {
      email: userStore.userData?.email,
    },
  })

  return (
    <Form
      error={error}
      {...context}
      onSubmit={onSubmit}
      className={'col-start-4 col-span-6'}
    >
      {!hideInput && (
        <ControlledInput
          name={'email'}
          inputProps={{
            label: 'Your email',
            placeholder: 'mail@example.com',
            wrapperClassName: 'mb-12',
          }}
          transform={{
            output: (e) => {
              return e.target.value.toLowerCase()
            },
          }}
        />
      )}
      <Button loading={loading}>
        {loading ? 'Sending' : `Send ${type} link`}
      </Button>
    </Form>
  )
}

export default ForgotPasswordForm

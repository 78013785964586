import React from 'react'
import { Typography } from '~components'

const AccountPageTitle = ({
  title,
  RightNode,
}: {
  title: string
  RightNode: React.ReactNode
}) => (
  <div>
    <Typography.Title level={'h3'} className={'text-blue'}>
      {title}
    </Typography.Title>

    {RightNode}
  </div>
)

export default AccountPageTitle

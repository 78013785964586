import { Api } from './APITypes'
import { ALIVE_API_URL } from '~utils'

type SecurityDataType = { token: string }

const api = new Api<SecurityDataType>({
  baseUrl: ALIVE_API_URL,
  securityWorker: (securityData: SecurityDataType | null) => {
    if (securityData) {
      return {
        headers: {
          ['Authorization']: `Bearer ${securityData.token}`,
        },
      }
    }
  },
})

export default api

export const months = [
  { id: '1', value: '1', label: '01' },
  { id: '2', value: '2', label: '02' },
  { id: '3', value: '3', label: '03' },
  { id: '4', value: '4', label: '04' },
  { id: '5', value: '5', label: '05' },
  { id: '6', value: '6', label: '06' },
  { id: '7', value: '7', label: '07' },
  { id: '8', value: '8', label: '08' },
  { id: '9', value: '9', label: '09' },
  { id: '10', value: '10', label: '10' },
  { id: '11', value: '11', label: '11' },
  { id: '12', value: '12', label: '12' },
]

export const years = [
  { id: '21', value: '21', label: '21' },
  { id: '22', value: '22', label: '22' },
  { id: '23', value: '23', label: '23' },
  { id: '24', value: '24', label: '24' },
  { id: '25', value: '25', label: '25' },
  { id: '26', value: '26', label: '26' },
]

export const states = [
  { id: 'AL', value: 'AL', label: 'AL' },
  { id: 'AK', value: 'AK', label: 'AK' },
  { id: 'AZ', value: 'AZ', label: 'AZ' },
  { id: 'AR', value: 'AR', label: 'AR' },
  { id: 'CA', value: 'CA', label: 'CA' },
  { id: 'CO', value: 'CO', label: 'CO' },
  { id: 'CT', value: 'CT', label: 'CT' },
  { id: 'DE', value: 'DE', label: 'DE' },
  { id: 'FL', value: 'FL', label: 'FL' },
  { id: 'GA', value: 'GA', label: 'GA' },
  { id: 'HI', value: 'HI', label: 'HI' },
  { id: 'ID', value: 'ID', label: 'ID' },
  { id: 'IL', value: 'IL', label: 'IL' },
  { id: 'IN', value: 'IN', label: 'IN' },
  { id: 'IA', value: 'IA', label: 'IA' },
  { id: 'KS', value: 'KS', label: 'KS' },
  { id: 'KY', value: 'KY', label: 'KY' },
  { id: 'LA', value: 'LA', label: 'LA' },
  { id: 'ME', value: 'ME', label: 'ME' },
  { id: 'MD', value: 'MD', label: 'MD' },
  { id: 'MA', value: 'MA', label: 'MA' },
  { id: 'MI', value: 'MI', label: 'MI' },
  { id: 'MN', value: 'MN', label: 'MN' },
  { id: 'MS', value: 'MS', label: 'MS' },
  { id: 'MO', value: 'MO', label: 'MO' },
  { id: 'MT', value: 'MT', label: 'MT' },
  { id: 'NE', value: 'NE', label: 'NE' },
  { id: 'NV', value: 'NV', label: 'NV' },
  { id: 'NH', value: 'NH', label: 'NH' },
  { id: 'NJ', value: 'NJ', label: 'NJ' },
  { id: 'NM', value: 'NM', label: 'NM' },
  { id: 'NY', value: 'NY', label: 'NY' },
  { id: 'NC', value: 'NC', label: 'NC' },
  { id: 'ND', value: 'ND', label: 'ND' },
  { id: 'OH', value: 'OH', label: 'OH' },
  { id: 'OK', value: 'OK', label: 'OK' },
  { id: 'OR', value: 'OR', label: 'OR' },
  { id: 'PA', value: 'PA', label: 'PA' },
  { id: 'RI', value: 'RI', label: 'RI' },
  { id: 'SC', value: 'SC', label: 'SC' },
  { id: 'SD', value: 'SD', label: 'SD' },
  { id: 'TN', value: 'TN', label: 'TN' },
  { id: 'TX', value: 'TX', label: 'TX' },
  { id: 'UT', value: 'UT', label: 'UT' },
  { id: 'VT', value: 'VT', label: 'VT' },
  { id: 'VA', value: 'VA', label: 'VA' },
  { id: 'WA', value: 'WA', label: 'WA' },
  { id: 'WV', value: 'WV', label: 'WV' },
  { id: 'WI', value: 'WI', label: 'WI' },
  { id: 'WY', value: 'WY', label: 'WY' },
  { id: 'DC', value: 'DC', label: 'DC' },
  { id: 'AS', value: 'AS', label: 'AS' },
  { id: 'GU', value: 'GU', label: 'GU' },
  { id: 'MP', value: 'MP', label: 'MP' },
  { id: 'PR', value: 'PR', label: 'PR' },
  { id: 'UM', value: 'UM', label: 'UM' },
  { id: 'VI', value: 'VI', label: 'VI' },
]

import React, { useMemo } from 'react'
import { observer } from 'mobx-react'
import { useMst } from '~stores'
import { DEFAULT_TIMEZONE, formatDateString } from '~utils'

const PricingTableTerms: React.FC = () => {
  const { stripeStore } = useMst()

  const date = useMemo(
    () =>
      stripeStore.anyCoupon
        ? formatDateString(stripeStore.anyCoupon.stops_at)
        : undefined,
    [stripeStore.anyCoupon],
  )

  return (
    <div className="pt-8 mb-4">
      <div className={'label mb-2'}>Recurring billing & trials</div>
      <div className={'disclaimer mb-8'}>
        All subscriptions automatically renew unless canceled at least 24 hours
        before the current period ends. You need to manage your subscription on
        the platform where you signed up.
      </div>
      <div className={'label mb-2'}>Cancel anytime</div>
      <div className={'disclaimer mb-8'}>
        Free trials are for new customers and can only be redeemed once. After
        your free trial, the subscription will begin unless canceled 24 hours
        before the end of the trial.
      </div>
      {stripeStore.anyCoupon ? (
        <>
          <div className={'label mb-2'}>
            NO REFUNDS ON DISCOUNTED SUBSCRIPTIONS
          </div>
          <div className={'disclaimer mb-8'}>
            All discounted subscription purchases are non-refundable. Refunds
            will not be provided after the initial 7-day free trial.
          </div>
        </>
      ) : null}
    </div>
  )
}

export default observer(PricingTableTerms)

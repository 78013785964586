import React from 'react'
import { Button, Typography } from '~components'
import css from './Table.module.scss'

const TableRowHeader: React.FC<{ items: string[] }> = ({ items }) => (
  <div className={css.rowHeader}>
    {items.map((item, index) => (
      <div className="h4 md:mb-10 pr-4" key={`${item}-${index}`}>
        {item}
      </div>
    ))}
  </div>
)

const handleUrl = (url: string) => {
  window.open(url)
}

export type TableItem = {
  value: string
  header: string
  type?: 'button' | 'text'
  showHeaderOnMobile?: boolean
  className: string
}

const defaultTableItem: TableItem = {
  header: '',
  value: '',
  type: 'text',
  showHeaderOnMobile: false,
  className: '',
}

const TableItem: React.FC<{ item: TableItem }> = (
  { item } = { item: defaultTableItem },
) => {
  if (item.type === 'button') {
    return (
      <div className={item.className}>
        <a
          className={
            'h5 py-4 md:py-3 flex items-center justify-center bg-blue rounded-full text-center focus:outline-none focus:ring-2 focus:ring-blue px-2'
          }
          href={item.value ? item.value : undefined}
        >
          <div className="text-white">
            Download
            <span className={'inline text-white md:hidden'}> Receipt</span>
          </div>
        </a>
      </div>
    )
  } else {
    return (
      <div className={`mb-8 md:my-0 ${item.className}`}>
        {item.header ? (
          <div className={'h5 mb-2 md:hidden'}>{item.header}</div>
        ) : null}
        <div className={'copy-2'}>{item.value}</div>
      </div>
    )
  }
}

export type TableItems = Array<Array<TableItem>>

interface TableProps {
  items: TableItems
  header: string[]
}

const Table: React.FC<TableProps> = ({ items, header }) => {
  return (
    <div className="md:pt-10">
      <TableRowHeader items={header} />
      {items.map((row, index) => (
        <div className={css.row} key={`TableItem-Row-${index}`}>
          {row.map((col, idx) => (
            <TableItem key={`TableItem-Col-${col.header}-${idx}`} item={col} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default Table

import React, { HTMLProps, forwardRef, ReactNode } from 'react'
import { ErrorOption } from 'react-hook-form'
import { FormError } from '~components'
import classNames from 'classnames/bind'
import css from './Input.module.scss'

const cx = classNames.bind(css)

interface InputLabelProps {
  id?: string
  label: string
  error?: boolean
  disabled?: boolean
}

export const InputLabel = ({
  id,
  label,
  disabled = false,
  error = false,
}: InputLabelProps) => (
  <div className={'mb-4'}>
    <label htmlFor={id} className={cx({ input_label: true, disabled, error })}>
      {label}
    </label>
  </div>
)

export interface InputProps extends HTMLProps<HTMLInputElement> {
  label?: string
  wrapperClassName?: string
  disabled?: boolean
  error?: ErrorOption
  icon?: ReactNode
  Leading?: ReactNode
  theme?: 'default' | 'white'
  trailingNode?: () => ReactNode
}

export const InputWrapper: React.FC<
  Pick<InputProps, 'error' | 'wrapperClassName'>
> = ({ children, error, wrapperClassName }) => {
  return (
    <div className={cx({ wrapper: true, error }, wrapperClassName)}>
      {children}
    </div>
  )
}

export const InputOuter: React.FC = ({ children }) => {
  return <div className={css.input_outer}>{children}</div>
}

export const Input: React.FC<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      id,
      label,
      error,
      disabled = false,
      wrapperClassName,
      icon: Icon,
      Leading,
      theme = 'default',
      trailingNode,
      ...rest
    }: InputProps,
    forwardedRef,
  ) => {
    return (
      <InputWrapper error={error} wrapperClassName={wrapperClassName}>
        {label ? (
          <InputLabel
            disabled={disabled}
            label={label}
            id={id}
            error={!!error}
          />
        ) : null}
        <InputOuter>
          {trailingNode ? trailingNode() : null}
          <input
            {...rest}
            id={id}
            ref={forwardedRef}
            disabled={disabled}
            className={cx({
              input: true,
              white: theme === 'white',
              disabled,
              error,
            })}
          />
          {Icon ? <span className={css.icon}>{Icon}</span> : null}
        </InputOuter>
        {error ? <FormError className={'mt-1'} error={error} /> : ''}
      </InputWrapper>
    )
  },
)

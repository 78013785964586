export const DEFAULT_TIMEZONE = 'EST'
export const LOCAL_STORAGE_EMAIL_DATA = 'emailData'
export const LOCAL_STORAGE_TOKEN_KEY = 'alive-jwt-token'
export const ALIVE_COUPON_ID = 'alive-coupon-id'
export const ALIVE_IS_PARTNER_KEY = 'alive-partner'
export const ALIVE_SELECTED_PRICE_KEY = 'alive-selected-price-key'

export const ALIVE_DEEPLINK_URL = `${process.env.NEXT_PUBLIC_ALIVE_APP_URL}/login/code`
export const ALIVE_API_URL = process.env.NEXT_PUBLIC_API_URL
export const ALIVE_MARKETING_URL = process.env.NEXT_PUBLIC_ALIVE_MARKETING_URL

export const TIKTOK_PIXEL_ID = 'C9AO55RC77U12DDTARAG'
export const PINTEREST_PIXEL_ID = '2613404722017'
export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
export const GOOGLE_PIXEL_ID = process.env.NEXT_PUBLIC_GOOGLE_PIXEL_ID
export const GOOGLE_PIXEL_EVENT =
  process.env.NEXT_PUBLIC_GOOGLE_PIXEL_CONVERSION_EVENT

export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN

import currency from 'currency.js'
import { colors } from './colors'
import { StripeElementsOptions } from '@stripe/stripe-js'

export const formatPrice = (price: number, divide = 1): currency => {
  if (price === 0) return currency(price)
  return currency(price, { fromCents: true, precision: 2 }).divide(divide)
}

export const elementsAppearance: StripeElementsOptions = {
  appearance: {
    theme: 'stripe',
    variables: {
      fontFamily: 'stripefont, sans-serif',
      fontSize2Xs: '16px',
      fontSizeLg: '16px',
      fontSize3Xs: '16px',
      fontSizeSm: '16px',
      fontSizeXl: '16px',
      fontSizeXs: '16px',
      fontSizeBase: '16px',
      borderRadius: '10px',
      // spacingUnit: 'px',
      // colorPrimary?: string;
      // colorPrimaryText?: string;
      colorBackground: '#EEF4FF',
      // colorBackgroundText?: string;
      colorText: '#0D66FC',
      // colorSuccess?: string;
      // colorSuccessText?: string;
      colorDanger: colors.red,
      colorDangerText: colors.red,
      // colorWarning?: string;
      // colorWarningText?: string;
      colorIcon: colors.blue,
      colorIconTab: colors.blue,
    },
    rules: {
      '.Input': {
        padding: '18px 16px 14px 16px',
        margin: '0 0 18px 0',
      },
      '.Label': {
        fontWeight: '500',
        padding: '0',
        margin: '0 0 10px 0',
        color: 'rgba(25, 20, 19, 1)',
      },
      '.Input:focus': {
        boxShadow:
          '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorPrimary)',
      },
      '.Input::placeholder': {
        color: colors.blue30,
      },
      '.TermsText': {
        fontSize: '0.63rem',
        fontWeight: '400',
        lineHeight: '125%',
        letterSpacing: '-0.01em',
        color: 'rgba(25, 20, 19, 1)',
      },
    },
  },
  fonts: [
    {
      family: 'stripefont',
      src: 'url(https://app.aliveapp.co/fonts/stripe-font.woff)',
      weight: '500',
    },
  ],
}

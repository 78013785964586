export const formatDateNumber = (timeStamp: number, short = false) => {
  const jsTimestamp = timeStamp * 1000
  const date = new Date(jsTimestamp)

  if (date)
    return date.toLocaleString('US', {
      day: '2-digit',
      month: short ? '2-digit' : 'long',
      year: 'numeric',
    })

  return 'Invalid date'
}

export const formatDateString = (timeStamp: string, short = false) => {
  const date = new Date(timeStamp)

  if (date)
    return date.toLocaleString('US', {
      day: '2-digit',
      month: short ? '2-digit' : 'long',
    })

  return 'Invalid date'
}

import { SVGComponent } from '~types'

const Menu = ({ close = false }) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="white" />
    <rect
      x="10"
      y="19"
      width="20"
      height="2"
      rx="1"
      fill="#191413"
      className={`origin-center transition-transform ${
        close ? 'rotate-45 translate-y-0' : 'rotate-0 -translate-y-1'
      }`}
    />
    <rect
      x="10"
      y="19"
      width="20"
      height="2"
      rx="1"
      fill="#191413"
      className={`origin-center transition-transform ${
        close ? '-rotate-45 translate-y-0' : 'rotate-0 translate-y-1'
      }`}
    />
  </svg>
)

export default Menu

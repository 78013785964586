//Set your APP_ID
const APP_ID = process.env.NEXT_PUBLIC_INTERCOM_ID

interface IntercomCustomerDetails {
  name?: string
  email?: string
  user_id?: string | number
}

export const loadIntercom = () => {
  const w = window
  // @ts-ignore
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    // @ts-ignore
    ic('update', w.intercomSettings)
  } else {
    const d = document
    const i = function () {
      i.c(arguments)
    }
    i.q = []
    i.c = function (args) {
      i.q.push(args)
    }
    // @ts-ignore
    w.Intercom = i
    let l = function () {
      let s = d.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://widget.intercom.io/widget/' + APP_ID
      let x = d.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    }
    if (document.readyState === 'complete') {
      l()
      // @ts-ignore
    } else if (w.attachEvent) {
      // @ts-ignore
      w.attachEvent('onload', l)
    } else {
      w.addEventListener('load', l, false)
    }
  }
}

export function bootIntercom(customerDetails: IntercomCustomerDetails = {}) {
  // @ts-ignore
  window.Intercom('boot', {
    app_id: APP_ID,
    ...customerDetails,
  })
}

export function shutdown() {
  // @ts-ignore
  window.Intercom('shutdown')
}

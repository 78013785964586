import React from 'react'
import { Toast } from '~components'
import { rootStore, useMst } from '~stores'

interface AccountToastProps {
  title: string
  subscribed?: boolean
  subtitle?: string
  action?: {
    title: string
    onPress?: (() => void) | (() => Promise<void>)
  }
}

const AccountToast = ({
  title,
  subtitle,
  subscribed,
  action,
}: AccountToastProps) => {
  const { stripeStore } = useMst()

  return (
    <button
      onClick={action.onPress}
      disabled={!action.onPress}
      className={`w-full`}
    >
      <Toast subscribed={subscribed}>
        {stripeStore.anyCoupon ? (
          <div className="bg-pink absolute top-0 left-0 rounded-br-[20px] pt-4 pb-3 px-5">
            <div className={'h4 text-black text-center'}>
              {stripeStore.anyCoupon.coupon_name}
            </div>
          </div>
        ) : null}

        <div
          className={`flex flex-row justify-between mb-16 ${
            stripeStore.anyCoupon ? 'items-start' : 'items-center'
          }`}
        >
          <h4 className={`h4 ${stripeStore.anyCoupon ? 'pt-12' : ''}`}>
            Current plan
          </h4>
          <div className="caption text-blue">{action.title}</div>
        </div>
        <div>
          <div>
            <h3 className="h2 text-blue">{title}</h3>
            {subtitle ? (
              <div className={'caption mt-1 mb-1'}>{subtitle}</div>
            ) : null}
          </div>
        </div>
      </Toast>
    </button>
  )
}

export default AccountToast

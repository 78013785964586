import { SVGComponent } from '~types'

const CompletedIcon = (props: SVGComponent) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line x1="5.45898" y1="10.5" x2="15.459" y2="10.5" stroke="#0D66FC" />
    <circle cx="10.459" cy="10" r="9.5" stroke="#0D66FC" />
  </svg>
)

export default CompletedIcon

import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import type { FormProps } from '~components'
import {
  ButtonGroup,
  Form,
  FormError,
  Link,
  PasswordInput,
  Button,
  ControlledInput as Input,
} from '~components'

interface LoginFormProps extends FormProps {
  handleStatusChange?: (status: string) => void
  emailValue: string | undefined
  setEmailValue: React.Dispatch<React.SetStateAction<string | undefined>>
}

const LoginForm = ({
  onSubmit,
  handleStatusChange,
  loading,
  error,
  emailValue,
  setEmailValue,
  ...context
}: LoginFormProps) => {
  useEffect(() => {
    if (emailValue && context.setValue) {
      context.setValue('email', emailValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue, context.setValue])

  return (
    <>
      <h1 className={'text-blue mb-8 md:mb-10 h2'}>Log in with password</h1>
      <div className={'copy-2 mb-6 md:mb-8'}>
        New to Alive?{' '}
        <Link href="/sign-up" className={'link'}>
          Sign up today
        </Link>
      </div>
      {/* @ts-ignore: Suppress deep type instantiation error */}
      <Form {...context} onSubmit={onSubmit}>
        <Input
          name={'email'}
          inputProps={{
            label: 'Email',
            placeholder: 'mail@example.com',
            wrapperClassName: 'mb-4 md:mb-8',
          }}
          transform={{
            output: (e) => {
              return e.target.value.toLowerCase()
            },
          }}
        />
        <PasswordInput
          name={'password'}
          inputProps={{
            type: 'password',
            label: 'Password',
            placeholder: '********',
            wrapperClassName: '',
          }}
        />
        <div className="mb-8">
          <Link href="/password/forgot" className={'caption text-blue'}>
            Forgot password?
          </Link>
        </div>
        {error ? <FormError className={'mb-5'} error={error} /> : ''}
        <ButtonGroup>
          <Button loading={loading} type={'submit'}>
            Log In
          </Button>
        </ButtonGroup>
      </Form>
      <div className="mt-8">
        <div
          onClick={() => handleStatusChange('phone')}
          className="cursor-pointer"
          role="button"
        >
          <h5 className={'h5 mb-4 text-blue text-center'}>Log in with phone</h5>
        </div>
      </div>
    </>
  )
}

export default observer(LoginForm)

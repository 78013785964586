/* eslint-disable import/no-named-as-default-member */
import * as Sentry from '@sentry/nextjs'
import mixpanel from 'mixpanel-browser'
import type { Dict } from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from './constants'
import { Logger } from './Logger'
import { SentryCaptureException } from '~utils/SentryUtils'

const logger = Logger('Mixpanel', {
  nsColor: 'white',
  nsBackground: 'purple',
})

const handleError = (e: Error) => {
  logger.error(e)
  const error = new Error()
  error.name = 'Mixpanel'
  error.message = e.message

  if (process.env.NODE_ENV === 'production') {
    Sentry.addBreadcrumb({ data: e })
    Sentry.captureException('Mixpanel Error.')
  }
}

export function initMixpanel() {
  try {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: false,
      ignore_dnt: true,
    })
    logger.log('Initialize')
  } catch (e) {
    const error = new Error(`Couldn't init mixpanel: ${e}`)
    error.name = 'Mixpanel'
    SentryCaptureException(error)
  }
}

export function identifyUser(id: number) {
  try {
    if (mixpanel) mixpanel.identify(String(id))
  } catch (e) {
    handleError(e)
  }
}

export function peopleIdentifyAndSet(data, can_send_email = false) {
  try {
    if (mixpanel) mixpanel.identify(String(data.id))
    const mixpanelData = {
      platform: 'web',
      Email: data.email,
      'Can send email': can_send_email,
      'First name': data.first_name,
      'Last Name': data.last_name,
      'Full Name': `${data.first_name} ${data.last_name}`,
    }
    if (mixpanel) mixpanel.people.set({ ...mixpanelData })
    logger.log('People set')
  } catch (e) {
    handleError(e)
  }
}

export function reset() {
  try {
    logger.log('reset')
    if (mixpanel) mixpanel.reset()
  } catch (e) {
    handleError(e)
  }
}

export function trackEvent(event: string, properties?: Dict) {
  try {
    logger.log('trackEvent', event, properties)
    if (mixpanel) mixpanel.track(event, properties)
  } catch (e) {
    handleError(e)
  }
}

import React, { ChangeEventHandler } from 'react'
import { Avatar, AvatarProps } from '~components'
import css from './AccountAvatar.module.scss'

interface AccountAvatarProps extends AvatarProps {
  onChange: ChangeEventHandler<HTMLInputElement>
}

const AccountAvatar: React.FC<AccountAvatarProps> = ({
  onChange,
  ...avatarProps
}) => {
  return (
    <>
      <input
        id="avatarInput"
        type="file"
        onChange={onChange}
        className={'hidden'}
      />
      <label htmlFor="avatarInput">
        <Avatar {...avatarProps} className={css.avatar} />
      </label>
    </>
  )
}

export default AccountAvatar

export const subscriptionOptionsDict = {
  year: {
    value: 'year',
    title: 'Yearly Plan - $119.99 USD',
    subtitle: '$9.99 USD a month. Auto-billed annually after free trial.',
    discount: 'Save 33%',
  },
  month: {
    value: 'month',
    title: 'Monthly Plan - $14.99 USD ',
    subtitle: '$14.99 USD Auto-billed monthly after free trial.',
  },
}

import React from 'react'
import { Grid } from '~components'

const AccountPageGrid: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <Grid className={`grid-cols-12 ${className ? className : ''}`}>
    {children}
  </Grid>
)

export default AccountPageGrid

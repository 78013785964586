import React, { useMemo, useState } from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { getValidationSchema } from '~utils'
import PhoneInput from '~components/Input/PhoneInput'
import type { UserNoId } from '~api/APITypes'
import type { CountryCode } from 'libphonenumber-js/min'
import { phone, requiredStringMessage } from '~validations'
import {
  useCustomForm,
  Form,
  ControlledInput as Input,
  Button,
} from '~components'
import { parse } from 'libphonenumber-js'
import { Notify } from 'notiflix'
import * as yup from 'yup'

interface FormSchema {
  first_name: string
  last_name: string
  mobile: string
}

const AccountProfileModal = ({ onClose }) => {
  const { userStore } = useMst()
  const initialPhoneCode = useMemo<CountryCode>(() => {
    if (userStore.userData.mobile)
      return parse(userStore.userData.mobile).country
    else return 'US'
  }, [userStore.userData.mobile])

  const [countryCode, setCountryCode] = useState<CountryCode>(initialPhoneCode)

  const schema = useMemo(
    () =>
      getValidationSchema({
        first_name: requiredStringMessage,
        last_name: yup.string().default(''),
        mobile: phone(countryCode),
      }),
    [countryCode],
  )

  const context = useCustomForm<FormSchema>({
    schema,
    defaultValues: {
      first_name:
        !userStore.userData.first_name || userStore.userData.first_name === '*'
          ? ''
          : userStore.userData.first_name,
      last_name: userStore.userData.last_name || '',
      mobile: userStore.userData.mobile || '',
      mode: 'onChange',
    },
  })

  const onSubmit = async (data: FormSchema) => {
    const patch: UserNoId = {}

    try {
      if (data.first_name !== userStore.userData.first_name)
        patch['first_name'] = data.first_name
      if (data.last_name !== userStore.userData.last_name)
        patch['last_name'] = data.last_name
      if (data.mobile !== userStore.userData.mobile)
        patch['mobile'] = data.mobile || null

      await userStore.patchUserData(data)
      Notify.success('<div>Profile updated</div>')
      onClose()
    } catch (e) {
      Notify.failure('There was an error, try again later.')
    }
  }

  return (
    <div>
      <h1 className={'h2 text-blue mb-8'}>Update profile</h1>

      <Form {...context} onSubmit={onSubmit}>
        <Input
          name={'first_name'}
          inputProps={{
            label: 'First Name',
            wrapperClassName: 'mb-6',
          }}
        />
        <Input
          name={'last_name'}
          inputProps={{
            label: 'Last Name',
            wrapperClassName: 'mb-6',
          }}
        />
        <PhoneInput
          name={'mobile'}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          inputProps={{
            label: 'Phone',
          }}
        />
        <div className="flex gap-[4%] justify-center flex-wrap">
          <div className="flex-[1_0_100%] mb-4 md:mb-0 md:flex-[1_0_48%]">
            <Button type={'submit'} disabled={!context.formState.isDirty}>
              Update
            </Button>
          </div>
          <div className="flex-[1_0_100%] md:flex-[1_0_48%]">
            <Button onClick={onClose} inverted>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default observer(AccountProfileModal)

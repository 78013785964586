import React, { useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import type { FormProps } from '~components'
import {
  Button,
  ButtonGroup,
  ControlledInput,
  Form,
  FormError,
  Link,
  Typography,
  useCustomForm,
  PhoneInput,
} from '~components'
import { numberLength, requiredPhone } from '~validations'
import { getValidationSchema, SentryCaptureException } from '~utils'
import { Transition } from '@headlessui/react'
import { CountryCode } from 'libphonenumber-js/max'
import { useRouter } from 'next/router'
import { useMst } from '~stores'
import { useLoginSubmit } from '~hooks/useLoginSubmit'

const codeSchema = getValidationSchema({
  code: numberLength(6),
})

const PhoneLoginForm = () => {
  const router = useRouter()
  const [countryCode, setCountryCode] = useState<CountryCode>('US')
  const schema = useMemo(
    () =>
      getValidationSchema({
        mobile: requiredPhone(countryCode),
      }),
    [countryCode],
  )

  const { userStore } = useMst()
  const handleLogin = useLoginSubmit()
  const contextMobile = useCustomForm({ schema })
  const contextCode = useCustomForm({ schema: codeSchema })

  const [error, setError] = useState<FormProps['error']>()
  const [codeError, setCodeError] = useState<FormProps['error']>()

  const [loading, setLoading] = useState(false)
  const [loadingCode, setLoadingCode] = useState(false)

  const [showCode, setShowCode] = useState(false)
  const [phoneValue, setPhoneValue] = useState<string>()

  const sendSMS = useCallback(
    async (mobile) => {
      try {
        setPhoneValue(mobile)
        setShowCode(true)
        await userStore.logInWithPhoneRequest(mobile)
      } catch (e) {
        SentryCaptureException(e)
        setError({ message: 'An error occurred, try again later.' })
      }
    },
    [userStore],
  )

  const onSubmitPhone = async (data: { mobile: string }) => {
    setLoading(true)
    await sendSMS(data.mobile)
    setLoading(false)
  }

  const onSubmitCode = useCallback(
    async (data: { code: string }) => {
      try {
        await handleLogin({
          login: phoneValue,
          password: data.code,
        })
      } catch (e) {
        if (e.response?.data?.title) {
          setCodeError({ message: e.response.data.title })
        } else {
          SentryCaptureException(e)
          setCodeError({
            message: 'An error occurred, please try again later.',
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [handleLogin, phoneValue],
  )

  const resendCode = useCallback(async () => {
    if (phoneValue) {
      setLoadingCode(true)
      await sendSMS(phoneValue)
      setLoadingCode(false)
    }
  }, [sendSMS, phoneValue])

  return (
    <>
      <Transition
        show={!showCode}
        enter="ease-out duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <h1 className={'text-blue mb-10 h2'}>Log in with phone</h1>
        <Form {...contextMobile} onSubmit={onSubmitPhone}>
          <PhoneInput
            name={'mobile'}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            inputProps={{
              label: 'Phone number',
              placeholder: '888-888-888',
              wrapperClassName: 'mb-0',
            }}
          />

          <div className={'copy-2 mb-5'}>Standard rates apply</div>

          {error ? <FormError className={'mb-5'} error={error} /> : ''}

          <ButtonGroup>
            <Button loading={loading} type={'submit'}>
              Send Code
            </Button>
          </ButtonGroup>
        </Form>
      </Transition>
      <Transition
        show={showCode}
        enter="ease-out duration-400"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Typography.Title level={'h3'} className={'text-blue'}>
          {`Enter the code we sent to ${phoneValue}`}
        </Typography.Title>
        <Form {...contextCode} onSubmit={onSubmitCode}>
          <ControlledInput
            name={'code'}
            inputProps={{
              wrapperClassName: 'mb-3',
            }}
          />
          <Typography.Text type={'caption'} className={'mb-3'}>
            Please allow up to 5 minutes for the text message. <br />
            <a
              className={'text-blue link caption'}
              onClick={() => setShowCode(false)}
            >
              Change your phone
            </a>
          </Typography.Text>

          {codeError ? (
            <FormError className={'mb-3'} error={codeError} />
          ) : null}

          <Button type={'submit'} loading={loading} className={'mb-4'}>
            Next
          </Button>
          {phoneValue ? (
            <Typography.Text type={'caption'}>
              {'Didn’t receive code? '}
              {loadingCode ? (
                <span>Sending</span>
              ) : (
                <span>
                  <a onClick={resendCode} className={'text-blue link'}>
                    Send new code
                  </a>
                </span>
              )}
            </Typography.Text>
          ) : null}
        </Form>
      </Transition>
    </>
  )
}

export default observer(PhoneLoginForm)

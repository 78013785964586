import React, { Dispatch, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { PlusIcon } from '~svg'
import { PricingTableItemRadio, Typography } from '~components'
import { FieldError } from 'react-hook-form'
import { IStripePrice } from '~stores'
import { observer } from 'mobx-react'

export type PricingTableKeys = 'year' | 'month'

export interface PricingTableRadioGroupProps {
  value: string
  onChange:
    | ((priceKey: string) => void)
    | ((priceKey: string) => Promise<void>)
    | Dispatch<React.SetStateAction<string>>
  error?: FieldError
  prices: Array<IStripePrice>
  addCurrent?: 'Monthly' | 'Yearly'
}

const PricingTableRadioGroup: React.FC<PricingTableRadioGroupProps> = ({
  value,
  onChange,
  error,
  prices,
  addCurrent,
}) => {
  useEffect(() => {
    if (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [error])

  return (
    <>
      <RadioGroup
        value={value}
        onChange={onChange}
        className={
          'md:flex md:gap-4 items-stretch md:justify-stretch md:mb-4 w-full'
        }
      >
        {prices.map((item, index) => {
          return (
            <PricingTableItemRadio
              value={item.id}
              checked={true}
              key={`PricingTableItemRadio-${index}`}
              {...item.readablePricing}
              isDisabled={item.readablePricing.card?.type === addCurrent}
              addCurrent={addCurrent}
            />
          )
        })}
      </RadioGroup>
    </>
  )
}

export default observer(PricingTableRadioGroup)

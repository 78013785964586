import { Instance, SnapshotIn, types } from 'mobx-state-tree'
import { formatNumber } from 'libphonenumber-js'

export const UserSubscriptionPlatform = types.enumeration([
  'android',
  'ios',
  'promo',
  'web',
])
export const UserPasswordType = types.enumeration(['system', 'user'])
export type IUserSubscriptionPlatform = Instance<
  typeof UserSubscriptionPlatform
>

export const UserSubscriptionStatus = types.enumeration([
  'app',
  'active',
  'inactive',
  'expired',
  'canceled',
])
export type IUserSubscriptionStatus = Instance<typeof UserSubscriptionStatus>

export const UserModel = types
  .model('UserModel', {
    id: types.number,
    canceled_at_date: types.maybeNull(types.string),
    email: types.string,
    days_lapsed: types.maybeNull(types.number),
    first_name: types.maybeNull(types.string),
    last_name: types.maybeNull(types.string),
    mobile: types.maybeNull(types.string),
    subscription_platform: types.maybeNull(UserSubscriptionPlatform),
    password_type: types.maybeNull(UserPasswordType),
  })
  .views((self) => ({
    get mobileWithFormat() {
      return formatNumber(self.mobile, 'INTERNATIONAL')
    },
  }))

export interface SnUserModel extends SnapshotIn<typeof UserModel> {}

import React, { useEffect, useMemo, useState } from 'react'
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'
import { AliveLogo } from '~svg'
import Menu from '~svg/Menu'
import { Button, Link, Grid } from '~components'
import { ALIVE_MARKETING_URL, SentryCaptureException } from '~utils'
import classNames from 'classnames/bind'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
} from 'body-scroll-lock-upgrade'

interface NavigationProps {
  authorized: boolean
  onLogOut?: () => void
}

const Navigation: React.FC<NavigationProps> = ({
  authorized = false,
  onLogOut,
}) => {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const [isAccount, setIsAccount] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const pathname = usePathname()

  useEffect(() => {
    if (pathname.includes('/account') || pathname.includes('/payment')) {
      setIsAccount(true)
    } else {
      setIsAccount(false)
    }

    if (pathname.includes('/login')) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }
  }, [pathname])

  useEffect(() => {
    const onRouterStart = () => {
      setOpen(false)
      clearAllBodyScrollLocks()
    }
    router.events.on('routeChangeStart', onRouterStart)

    return () => {
      router.events.off('routeChangeStart', onRouterStart)
      clearAllBodyScrollLocks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showLoginButton = useMemo(() => {
    const paths = ['/login', '/login/phone']
    return !paths.includes(router.pathname)
  }, [router.pathname])

  const onLogout = async () => {
    try {
      if (onLogout) {
        await onLogOut()
        router.push('/login')
      }
    } catch (e) {
      SentryCaptureException('Logout', e)
    }
  }

  const onLogin = async () => {
    router.push('login')
  }

  const onSignUp = async () => {
    router.push('sign-up')
  }

  const toggleOpen = () => {
    if (open) {
      clearAllBodyScrollLocks()
    } else {
      disableBodyScroll(document.querySelector('body'))
    }
    setOpen((isopen) => !isopen)
  }

  return (
    <header className="fixed flex items-center w-full top-0 left-0 z-40 transition-colors justify-stretch h-[88px] md:items-start md:justify-start">
      <div
        className={`fixed top-0 left-0 w-full h-screen flex bg-light-blue z-10 opacity-0 pointer-events-none lg:hidden ${
          open ? 'opacity-100 pointer-events-auto' : ''
        }`}
      ></div>
      <div
        className={`absolute w-full top-0 left-0 z-30 flex justify-between pl-[19px] pr-[11px] py-6 items-center lg:w-[160px] lg:pl-[29px]`}
      >
        <div className="w-[90px] lg:w-[100px]">
          <Link href={authorized ? '/account' : ALIVE_MARKETING_URL}>
            <AliveLogo />
            <span className="sr-only">Alive App</span>
          </Link>
        </div>

        <button
          className="w-[40px] lg:hidden"
          onClick={toggleOpen}
          aria-label="Toggle Menu"
        >
          <Menu close={open} />
        </button>
      </div>

      <nav
        className={`absolute left-0 top-0 h-screen lg:h-auto lg:relative lg:left-auto lg:top-auto z-20 w-full flex items-center lg:block lg:pt-3 ${
          open
            ? 'opacity-100 pointer-events-auto'
            : 'opacity-0 lg:opacity-100 pointer-events-none lg:pointer-events-auto'
        }`}
      >
        <Grid>
          <div className="col-span-4 md:col-span-12 lg:flex lg:justify-end lg:items-start">
            <div
              className={`lg:bg-white lg:rounded-full lg:flex lg:px-6 lg:border ${
                isAccount ? 'lg:border-black/10' : 'lg:border-white'
              }`}
            >
              <ul className="bg-white rounded-[10px] w-full mb-5 lg:w-auto lg:bg-transparent lg:mb-0">
                <li>
                  <a
                    href={`${ALIVE_MARKETING_URL}/programs`}
                    className="text-blue lg:text-black py-[14px] px-4 block h4 lg:h5 lg:px-6 lg:py-5"
                  >
                    Programs
                  </a>
                </li>
              </ul>
              <ul className="bg-white rounded-[10px] w-full mb-5 lg:w-auto lg:bg-transparent lg:mb-0">
                <li>
                  <a
                    target={'_blank'}
                    href={`${ALIVE_MARKETING_URL}/support`}
                    className="text-blue lg:text-black py-[14px] px-4 block h4 lg:h5 lg:px-6 lg:py-5"
                  >
                    Support
                  </a>
                </li>
              </ul>
              <ul className="bg-white rounded-[10px] w-full mb-5 lg:w-auto lg:bg-transparent lg:mb-0 lg:ml-4 lg:border-l lg:border-black/10 lg:rounded-none lg:pl-4">
                <li>
                  <a
                    href={'https://shop.aliveapp.co'}
                    className="text-blue lg:text-black py-[14px] px-4 block h4 lg:h5 lg:px-6 lg:py-5"
                  >
                    Shop
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={`lg:bg-white lg:rounded-full lg:flex lg:ml-3 lg:py-2 lg:px-5 lg:border ${
                isAccount ? 'lg:border-black/10' : 'lg:border-white'
              }`}
            >
              <ul className="mt-16 lg:w-auto lg:mt-0 hidden lg:flex">
                {authorized ? (
                  <>
                    <li className="mb-5 lg:mb-0">
                      <Link
                        href={'/account'}
                        className={`block lg:h5 lg:px-5 lg:py-3 relative group ${
                          isAccount ? 'active' : ''
                        }`}
                      >
                        My Account
                        <span className="absolute -top-[8px] left-0 right-0 block mx-[20px] h-[4px] bg-blue opacity-0 transition-opacity duration-200 group-[.active]:opacity-100"></span>
                      </Link>
                    </li>
                    <li className="mb-5 lg:mb-0">
                      <div
                        onClick={onLogout}
                        className={'block lg:h5 lg:px-5 lg:py-3 cursor-pointer'}
                      >
                        Sign Out
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mb-5 lg:mb-0">
                      <Link
                        href={'/login'}
                        className={`block lg:h5 lg:px-5 lg:py-3 relative group ${
                          isLogin ? 'active' : ''
                        }`}
                      >
                        Log In
                        <span className="absolute -top-[9px] left-0 right-0 block mx-[20px] h-[4px] bg-blue opacity-0 transition-opacity duration-200 group-[.active]:opacity-100"></span>
                      </Link>
                    </li>
                    <li className="mb-5 lg:mb-0">
                      <Link
                        href={'/sign-up'}
                        className={
                          'lg:bg-blue lg:rounded-full lg:text-white block lg:h5 lg:px-8 lg:py-3'
                        }
                      >
                        Sign Up
                      </Link>
                    </li>
                  </>
                )}
              </ul>
              <ul className="mt-16 lg:w-auto lg:mt-0 lg:hidden">
                {authorized ? (
                  <>
                    <li className="mb-10">
                      <Link
                        href={'/account'}
                        className={`rounded-full text-blue border border-blue block h4 px-8 py-3 text-center ${
                          isAccount ? 'active' : ''
                        }`}
                      >
                        My Account
                      </Link>
                    </li>
                    <li>
                      <div
                        onClick={onLogout}
                        className={
                          'text-blue block lg:h5 lg:px-5 lg:py-3 h4 cursor-pointer text-center'
                        }
                      >
                        Sign Out
                      </div>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="mb-5 lg:mb-0">
                      <Button onClick={onLogin} className={'h4'} inverted>
                        Log In
                      </Button>
                    </li>
                    <li className="mb-5 lg:mb-0">
                      <Button onClick={onSignUp} className={'h4'}>
                        Sign Up
                      </Button>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </Grid>
      </nav>
    </header>
  )
}

export default Navigation

import React, { useState, useRef, ClipboardEvent, useEffect } from 'react'

interface CodeInputProps {
  length: number
  onChange: (code: string) => void
  value: string
}

const CodeInput: React.FC<CodeInputProps> = ({ length, onChange, value }) => {
  const [code, setCode] = useState<string[]>(
    value.split('').concat(Array(length - value.length).fill('')),
  )
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])

  const handleChange = (index: number, value: string) => {
    const newCode = [...code]
    newCode[index] = value
    setCode(newCode)
    onChange(newCode.join(''))

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus()
    }
  }

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus()
    }
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text')
    const pastedCode = pastedData.slice(0, length).split('')
    setCode(pastedCode.concat(Array(length - pastedCode.length).fill('')))
    onChange(pastedCode.join(''))
    inputRefs.current[Math.min(pastedCode.length, length - 1)]?.focus()
  }

  useEffect(() => {
    setCode(value.split('').concat(Array(length - value.length).fill('')))
  }, [value, length])

  return (
    <div className="w-full mb-10">
      <div className="grid grid-cols-6 gap-x-2">
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
            ref={(el) => (inputRefs.current[index] = el)}
            className="h2 text-center rounded-[10px] text-blue bg-light-blue pt-8 pb-6"
          />
        ))}
      </div>
    </div>
  )
}

export default CodeInput

import React, { FC, useMemo, useState, useEffect } from 'react'
import currency from 'currency.js'
import { useMst } from '~stores'
import { useRouter } from 'next/router'
import { Notify } from 'notiflix'
import { observer } from 'mobx-react'
import { formatPrice } from '~utils/stripeUtils'
import { Logger, ALIVE_MARKETING_URL } from '~utils'
import { Button, Link, PricingTableRadioGroup } from '~components'
import { PaymentForm } from '~components/pages'
import PricingTableTerms from '~components/Pricing/PricingTableTerms'
import type { ModalProps } from '~components'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { IUserSubscriptionStatus } from '~stores/user'
import { getReadablePricingSignUp } from '~utils/pricing'

const logger = Logger('PaymentForm', { nsBackground: 'yellow' })

const TAXABLE_LOCATIONS = ['MI', 'NC', 'GB', 'UT', 'TX']

type AccountSubscriptionModalProps = Omit<ModalProps, 'children'>

type SummaryValues = {
  type: string
  date: string
  regularPrice?: currency
  price: currency
  tax: currency
  total: currency
}

type AccountSubscriptionModalStatus =
  | 'loading'
  | 'resubscribe'
  | 'subscription_edit'
  | 'subscription_success'
  | 'cancel_alert'
  | 'payment_card'

const getInitialStatus = (
  status: IUserSubscriptionStatus,
): AccountSubscriptionModalStatus => {
  switch (status) {
    case 'canceled':
    case 'expired':
      return 'resubscribe'
    default:
      return 'subscription_edit'
  }
}

const AccountSubscriptionModal: FC<AccountSubscriptionModalProps> = ({
  onClose,
  open,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const rootStore = useMst()
  const { stripeStore, userStore } = rootStore
  const router = useRouter()
  const [userLocation, setUserLocation] = useState('')

  const [status, setStatus] = useState<AccountSubscriptionModalStatus>(
    getInitialStatus(userStore.subscriptionStatus),
  )
  const [disabled, setDisabled] = useState(false)

  const getNextPriceId = (currentPriceId: string): string => {
    const currentIndex = stripeStore.prices.findIndex(
      (price) => price.id === currentPriceId,
    )
    const nextIndex = (currentIndex + 1) % stripeStore.prices.length
    return stripeStore.prices[nextIndex].id
  }

  const [selectedPriceId, setSelectedPriceId] = useState<string>('')

  const [summaryValues, setSummaryValues] = useState<SummaryValues>({
    type: '',
    date: '',
    regularPrice: currency(0, { precision: 2 }),
    price: currency(0, { precision: 2 }),
    tax: currency(0, { precision: 2 }),
    total: currency(0, { precision: 2 }),
  })

  const [newPayment, setNewPayment] = useState(false)

  useEffect(() => {
    if (selectedPriceId) {
      setDisabled(selectedPriceId === stripeStore.subscription.plan.id)
      const selectedPrice = stripeStore.getPriceById(selectedPriceId)
      if (selectedPrice) {
        const priceAmount = stripeStore.anyCoupon
          ? selectedPrice.coupon.discount_price
          : selectedPrice.unit_amount
        const regularAmount = stripeStore.anyCoupon
          ? selectedPrice.unit_amount
          : 0

        const taxAmount = TAXABLE_LOCATIONS.includes(userLocation)
          ? Math.round(priceAmount * 0.0635)
          : 0
        const totalAmount = priceAmount + taxAmount

        const readablePricing = getReadablePricingSignUp({
          id: selectedPrice.id,
          amount: priceAmount,
          coupon: selectedPrice.coupon,
          interval: selectedPrice.recurring.interval,
        })

        setSummaryValues({
          type: readablePricing.card.type,
          date:
            stripeStore.subscription.readableInterval === 'Yearly'
              ? new Date(
                  stripeStore.subscription.current_period_end * 1000,
                ).toLocaleDateString()
              : new Date().toLocaleDateString(),
          regularPrice: formatPrice(regularAmount),
          price: formatPrice(priceAmount),
          tax: formatPrice(taxAmount),
          total: formatPrice(totalAmount),
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPriceId, stripeStore])

  const loading = useMemo(() => status === 'loading', [status])

  const setSubscriptionByID = async () => {
    setStatus('loading')
    const selectedPrice = stripeStore.getPriceById(selectedPriceId)
    const couponId = selectedPrice.coupon ? selectedPrice.coupon.id : undefined

    await stripeStore.setSubscriptionById({
      price_id: selectedPriceId,
      coupon_id: couponId,
    })
  }

  const onSubscriptionChangeSubmit = async () => {
    try {
      setStatus('loading')
      await setSubscriptionByID()
      await rootStore.hydrate()
      router.push('/account')
      Notify.success('Subscription updated.')
    } catch (e) {
      setStatus('subscription_edit')
      Notify.failure('There was an error. Please try again later.')
    }
  }

  /* const onCancelSubmit = async () => {
    try {
      setStatus('loading')
      await stripeStore.deleteSubscription(stripeStore.subscription.id)
      await rootStore.hydrate()
      onClose()
      Notify.success('Your subscription was canceled successfully.')
    } catch (e) {
      Notify.failure('There was an error, try again later.')
    }
  }

  const onPaymentCallback = async (success) => {
    if (success) {
      await setSubscriptionByID()
      setStatus('subscription_success')
    } else {
      setStatus('payment_card')
    }
  }

  const onReSubscribePress = async () => {
    try {
      await setSubscriptionByID()
    } catch (e) {
      setStatus('resubscribe')
    }
  } */

  return (
    <div>
      <h1 className={'text-blue mb-5 h1'}>Change your Alive subscription</h1>

      {userStore.subscriptionStatus === 'active' ? (
        <div className={'mb-14 copy-2'}>
          You are currently subscribed to the{' '}
          {stripeStore.subscription.readableInterval} plan. If you switch to the
          {stripeStore.subscription.readableInterval === 'Monthly'
            ? ' Yearly '
            : ' Monthly '}
          plan and select 'Confirm Changes', you will be switched to the
          {stripeStore.subscription.readableInterval === 'Monthly'
            ? ' Yearly '
            : ' Monthly '}
          plan.
        </div>
      ) : null}

      <PricingTableRadioGroup
        value={selectedPriceId}
        prices={stripeStore.prices}
        onChange={(id) => setSelectedPriceId(id)}
        addCurrent={stripeStore.subscription?.readableInterval}
      />

      <PaymentForm
        onSubmitCallback={(success) => {
          if (success) {
            onSubscriptionChangeSubmit()
          } else {
            Notify.failure('Failed to update payment method. Please try again.')
          }
        }}
        summaryValues={summaryValues}
        newPayment={newPayment}
        change={true}
        setNewPayment={setNewPayment}
        submitButtonTexts={{
          disabled: 'Select Change',
          submit: 'Confirm Changes',
          loading: 'Paying',
        }}
      />

      {!newPayment && (
        <Button
          className="mt-3 default"
          onClick={onSubscriptionChangeSubmit}
          disabled={loading || disabled || summaryValues.type === ''}
        >
          {loading
            ? 'Paying'
            : summaryValues.type === ''
            ? 'Select Change'
            : 'Confirm Changes'}
        </Button>
      )}

      <PricingTableTerms />
      <Link
        href={`${ALIVE_MARKETING_URL}/terms`}
        className={'caption text-blue'}
      >
        Read terms & conditions
      </Link>
    </div>
  )
}

export default observer(AccountSubscriptionModal)

import React, { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { LoadingIndicator } from '~components/index'

interface SplashProps {
  show: boolean
}

const Splash: React.FC<SplashProps> = ({ show }) => {
  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 rotate-0"
      leaveTo="opacity-0"
    >
      <div
        className={
          'inset-0 bg-white fixed w-full h-full flex flex-col items-center justify-center'
        }
      >
        <LoadingIndicator />
      </div>
    </Transition>
  )
}

export default Splash

import React from 'react'
import Select, { OptionValue, SelectProps } from './Select'
import { useFormContext, Controller } from 'react-hook-form'

interface ControlledSelectProps<T extends OptionValue> {
  name: string
  selectProps?: Omit<SelectProps<T>, 'value' | 'onChange' | 'onBlur' | 'error'>
}

const ControlledSelect = <ValueT extends OptionValue>({
  name,
  selectProps,
}: ControlledSelectProps<ValueT>) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select {...selectProps} {...field} error={error} />
      )}
    />
  )
}

export default ControlledSelect

import React, { useState } from 'react'
import { ControlledInput } from '~components'
import type { ControlledInputProps } from '~components'
import { LockIcon, UnlockedIcon } from '~svg'

const PasswordInput = ({
  inputProps,
  ...props
}: ControlledInputProps<string, string>) => {
  const [locked, setLocked] = useState(true)

  const toggleLocked = () => {
    setLocked((prev) => !prev)
  }

  return (
    <ControlledInput
      {...props}
      inputProps={{
        ...inputProps,
        type: locked ? 'password' : 'text',
        icon: (
          <div
            className={`clickable ${locked ? 'opacity-30' : 'opacity-100'}`}
            onClick={toggleLocked}
          >
            {locked ? <LockIcon /> : <UnlockedIcon />}
          </div>
        ),
      }}
    />
  )
}

export default PasswordInput

import React, { useMemo } from 'react'
import PasswordValidator from 'password-validator'
import { Typography } from '~components'

interface PasswordValidationProps {
  className?: string
  value: string
}

const symbolsSchema = new PasswordValidator()
symbolsSchema.symbols(1)

const lengthSchema = new PasswordValidator()
lengthSchema.min(8)

const validationRules = [
  { label: '8 or more characters', validation: lengthSchema },
  { label: 'At least one symbol', validation: symbolsSchema },
]
const PasswordValidation: React.FC<PasswordValidationProps> = ({
  value,
  className,
}) => {
  const validations = useMemo(() => {
    return validationRules.map((item, index) => {
      const hasNoValue = value === '' || value === undefined
      const valid = item.validation.validate(value)

      const textClassName = hasNoValue
        ? 'text-opacity-50 text-black'
        : valid
        ? 'text-blue'
        : 'text-opacity-50 text-black'

      return {
        ...item,
        valid,
        textClassName,
      }
    })
  }, [value])

  return (
    <div className={`${className ? className : ''}`}>
      {validations.map((item, index) => (
        <div
          className={'flex flex-row items-center mb-1'}
          key={`${item.label}-${index}`}
        >
          <div
            className={'w-1 h-1 block border border-blue rounded-full mr-1'}
          />

          <Typography.Text type={'caption'} className={item.textClassName}>
            {item.label}
          </Typography.Text>
        </div>
      ))}
    </div>
  )
}

export default PasswordValidation

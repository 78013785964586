import React, { useCallback, useState } from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { getValidationSchema, SentryCaptureException } from '~utils'
import { ModalProps } from '~components/Modal/Modal'
import { requiredEmail } from '~validations'
import {
  useCustomForm,
  Button,
  Typography,
  Form,
  Input,
  ControlledInput,
} from '~components'
import { Notify } from 'notiflix'
import { ForgotPasswordForm } from '~components/pages'
import { useRouter } from 'next/router'

type AccountLoginModalProps = Omit<ModalProps, 'children'>

interface FormSchema {
  email: string
}

const schema = getValidationSchema({
  email: requiredEmail,
})

const AccountLoginModal: React.FC<AccountLoginModalProps> = ({ onClose }) => {
  const { userStore } = useMst()
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState<
    'initial' | 'password' | 'password-success' | 'email'
  >('initial')

  const [pwdFormError, setPwdFormError] = useState<{ message: string }>()
  const [pwdLoading, setPwdLoading] = useState(false)

  const context = useCustomForm({
    schema,
    defaultValues: {
      email: userStore.userData.email,
    },
  })

  const renderContent = useCallback(() => {
    const onSubmit = async (data: FormSchema) => {
      setLoading(true)
      try {
        await userStore.patchUserData(data)
        Notify.success('Email updated')
        onClose()
      } catch (e) {
        Notify.failure('There was an error, try again later.')
      } finally {
        setLoading(false)
      }
    }

    const onPasswordSubmit = async ({ email }) => {
      setPwdLoading(true)
      try {
        await userStore.authResetPasswordRequest(email)
        setStatus('password-success')
        // Change content
      } catch (e) {
        if (e.response.status === 404) {
          setStatus('password-success')
        } else {
          setPwdFormError({
            message: 'An error occurred, try again later.',
          })
          SentryCaptureException(e)
        }
      } finally {
        setPwdLoading(false)
      }
    }

    switch (status) {
      case 'initial':
        return (
          <>
            <h1 className={'text-blue h2 mb-10 md:mb-8'}>Update log in info</h1>
            <h3 className="h4 mb-6">Your email</h3>
            <div className="h4 text-blue mb-6">{userStore.userData.email}</div>
            <div className="max-w-[23rem] mb-8">
              <Button inverted onClick={() => setStatus('email')}>
                Change Email
              </Button>
            </div>
            <h3 className="h4 mb-6">Password</h3>
            {userStore.userData.password_type === 'user' ? (
              <>
                <div className="h4 text-blue mb-6">••••••••••••</div>
                <div className="max-w-[23rem]">
                  <Button inverted onClick={() => setStatus('password')}>
                    Reset Password
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="max-w-[23rem]">
                  <Button inverted onClick={() => setStatus('password')}>
                    Create Password
                  </Button>
                </div>
              </>
            )}
          </>
        )
      case 'password':
        return (
          <>
            <h2 className={'text-blue h2 mb-10 md:mb-5'}>
              {userStore.userData.password_type === 'user' ? 'Reset' : 'Create'}{' '}
              password
            </h2>
            <div className="copy-2 mb-8">
              Please verify your email and we'll send instructions to{' '}
              {userStore.userData.password_type === 'user' ? 'reset' : 'create'}{' '}
              your password.
            </div>
            <h3 className="h4 mb-6">Your email</h3>
            <div className="h4 text-blue mb-16">{userStore.userData.email}</div>

            <div className="flex gap-[4%] justify-center flex-wrap">
              <div className="flex-[1_0_100%] mb-4 md:flex-[1_0_48%]">
                <ForgotPasswordForm
                  loading={pwdLoading}
                  error={pwdFormError}
                  onSubmit={onPasswordSubmit}
                  type={
                    userStore.userData.password_type === 'user'
                      ? 'reset'
                      : 'create'
                  }
                  hideInput
                />
              </div>
              <div className="flex-[1_0_100%] mb-4 md:flex-[1_0_48%]">
                <Button
                  onClick={() => setStatus('initial')}
                  loading={loading}
                  inverted
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )
      case 'email':
        return (
          <>
            <h2 className={'text-blue h2 mb-10 md:mb-8'}>Change email</h2>
            <Form {...context} onSubmit={onSubmit}>
              <ControlledInput
                name={'email'}
                inputProps={{
                  label: 'New email',
                  placeholder: 'mail@example.com',
                  wrapperClassName: 'mb-12',
                }}
                transform={{
                  output: (e) => {
                    return e.target.value.toLowerCase()
                  },
                }}
              />
              <div className="flex gap-[4%] justify-center flex-wrap">
                <div className="flex-[1_0_48%] mb-4">
                  <Button
                    type={'submit'}
                    disabled={!context.formState.isDirty}
                    loading={loading}
                  >
                    Send Confirmation Email
                  </Button>
                </div>
                <div className="flex-[1_0_48%]">
                  <Button
                    onClick={() => setStatus('initial')}
                    loading={loading}
                    inverted
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          </>
        )
      case 'password-success':
        return (
          <>
            <h2 className={'text-blue h2 mb-6 md:mb-6'}>
              Email has been sent!
            </h2>
            <div className={'copy-2 mb-8 md:mb-16'}>
              You will receive an email with reset instructions.
            </div>
            <div className="w-full md:w-96">
              <Button onClick={onClose}>Done</Button>
            </div>
          </>
        )
    }
  }, [context, loading, onClose, pwdFormError, pwdLoading, status, userStore])

  return renderContent()
}

export default observer(AccountLoginModal)

import React, { useState } from 'react'
import css from './Avatar.module.scss'

export interface AvatarProps {
  alt?: string
  src?: string
  size?: 'sm' | 'md'
  className?: string
}

const sizesClasses = {
  sm: 'w-8 h-8',
  md: 'w-10 h-10',
}

const sizes = {
  sm: 8,
  md: 10,
}

export const Avatar: React.FC<AvatarProps> = ({
  size = 'md',
  src,
  alt,
  className,
}) => {
  const [error, setError] = useState(false)

  if (error)
    return (
      <div className={`${sizesClasses[size]} ${css.wrapper}`}>
        <img
          className={'hover'}
          src="/img/profile-pic.png"
          alt="User Profile"
        />
      </div>
    )

  return (
    <div
      className={`${sizesClasses[size]} ${css.wrapper} ${
        className ? className : ''
      }`}
    >
      <img
        src={src}
        alt={alt}
        width={sizes[size] * 8}
        height={sizes[size] * 8}
        onError={() => {
          setError(true)
        }}
      />
    </div>
  )
}

import React from 'react'
import { Modal } from '~components'

const AccountProfileModal = ({ onClose, open }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="md:px-16">
        <h3 className="h2 text-blue mb-2">Sales Tax</h3>
        <div className="copy-2">
          The tax amount calculated is an estimate and may vary slightly upon
          completion of your transaction.
        </div>
      </div>
    </Modal>
  )
}

export default AccountProfileModal

import React from 'react'
import { AliveWordmark, AliveLogomark, ArrowIcon } from '~svg'
import { Grid, Link, Typography } from '~components'
import { ALIVE_MARKETING_URL } from '~utils'

interface FooterProps {}

const info_links = [
  {
    newTab: true,
    label: 'Support',
    link: `${ALIVE_MARKETING_URL}/support`,
  },
  {
    label: 'Reviews',
    link: `${ALIVE_MARKETING_URL}/reviews`,
  },
  { label: 'Sign In', link: '/login' },
]

const social_links = [
  {
    newTab: true,
    label: 'TikTok',
    link: `https://www.tiktok.com/@whitneyysimmons`,
  },
  {
    newTab: true,
    label: 'Instagram',
    link: `https://www.instagram.com/thealiveapp/`,
  },
  {
    newTab: true,
    label: 'Youtube',
    link: `https://www.youtube.com/channel/UCEQi1ZNJiw3YMRwni0OLsTQ/videos`,
  },
]

const copy_links = [
  {
    newTab: false,
    label: 'Privacy Policy',
    link: `${ALIVE_MARKETING_URL}/privacy`,
  },
  {
    label: 'Terms & Conditions',
    link: `${ALIVE_MARKETING_URL}/terms`,
  },
]

const Footer: React.FC<FooterProps> = () => {
  return (
    <footer className="bg-light-blue md:rounded-t-[30px] pt-12 pb-4 rounded-t-[20px] -mt-[30px] relative z-30 md:pb-3">
      <Grid>
        <div className="sm:col-span-5 md:col-span-5 lg:col-span-3 hidden md:block">
          <Typography.Title level={'h4'} className={'text-blue mb-3'}>
            Subscribe to Alive
          </Typography.Title>
          <Typography.Text type={'copy-2'} className={'mb-4'}>
            Be the first to hear about the latest updates
          </Typography.Text>
          <form
            action="https://www.getdrip.com/forms/884027464/submissions"
            method="post"
            data-drip-embedded-form="884027464"
          >
            <div className="hidden" aria-hidden="true">
              <label htmlFor="website">Website</label>
              <br />
              <input type="text" name="website" autoComplete="false" />
            </div>
            <div className="relative">
              <input
                type="email"
                name="fields[email]"
                required
                placeholder="Your email"
                className="bg-white w-full rounded-full h4 h-[50px] leading-[50px] px-5 placeholder-blue/30"
                aria-label="Your email"
              />
              <div className="absolute right-0 top-0 w-[50px] h-[50px]">
                <button
                  type="submit"
                  data-drip-attribute="sign-up-button"
                  className="w-full h-full bg-blue rounded-full flex items-center justify-center"
                >
                  <ArrowIcon />
                  <span className="hidden">Sign Up</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="md:col-span-6 md:col-start-7 lg:col-start-7 lg:col-span-3">
          <div className="flex md:block w-full">
            <ul className="mb-8 flex-[0_0_50%] md:flex-[0_0_100%]">
              {info_links.map((item) => (
                <li key={`footer-link-${item.label}`}>
                  <Link
                    target={item.newTab ? '_blank' : ''}
                    href={item.link}
                    className="text-blue h4 py-4 md:py-[7px] inline-block"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>

            <ul className="mb-8 flex-[0_0_50%] md:flex-[0_0_100%]">
              {social_links.map((item) => (
                <li key={`footer-link-${item.label}`}>
                  <Link
                    target={item.newTab ? '_blank' : ''}
                    href={item.link}
                    className="text-blue h4 py-4 md:py-[7px] inline-block"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>

            <ul className="hidden md:block">
              <li>
                <Link
                  target="_blank"
                  href="https://www.facebook.com/groups/170337694232785"
                  className="text-blue h4 py-1 inline-flex items-center"
                >
                  Join Facebook group{' '}
                  <div className="border border-1 border-blue rounded-full h-[40px] w-[40px] inline-flex items-center justify-center ml-3">
                    <ArrowIcon fill={'#0D66FC'} />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="md:col-span-6 md:col-start-7 lg:col-start-10 lg:col-span-3 hidden md:block">
          <div className="lg:flex lg:justify-end mt-8 lg:-mt-2"></div>
        </div>
        <div className="md:col-span-5">
          <div className="mb-2">
            <AliveWordmark />
          </div>
        </div>
        <div className="md:col-span-6 md:col-start-7 mt-10 md:mt-8">
          <div className="flex items-end h-full w-full">
            <ul className="flex justify-center md:justify-between w-full flex-wrap">
              {copy_links.map((item) => (
                <li key={`footer-link-${item.label}`}>
                  <Link
                    target={item.newTab ? '_blank' : ''}
                    href={item.link}
                    className="label-2 inline-block text-center md:text-left px-4 md:p-0 whitespace-nowrap"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
              <li>
                <div className="label-2 inline-block text-center md:text-left px-4 md:p-0 whitespace-nowrap">
                  &copy; Whitney Simmons App LLC
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Grid>
    </footer>
  )
}

export default Footer

import { SVGComponent } from '~types'

const CloseIcon = (props: SVGComponent) => (
  <svg
    width="61"
    height="60"
    viewBox="0 0 61 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="30.459"
      cy="30"
      r="30"
      transform="rotate(-180 30.459 30)"
      fill="#EEFFA4"
    />
    <path d="M20.459 20L30.459 30L20.459 40" stroke="#191413" />
    <path d="M40.459 40L30.459 30L40.459 20" stroke="#191413" />
  </svg>
)

export default CloseIcon

import { forms } from '~data/errorMessages'
import { errorStrings } from '~strings'
// import creditCardValidator from 'card-validator'
import passwordValidator from 'password-validator'
import type { CountryCode } from 'libphonenumber-js/min'
import * as yup from 'yup'
import 'yup-phone-lite'

export const zipCode = yup
  .string()
  .required(errorStrings.required)
  .matches(/^[0-9]+$/, 'Must be only numbers')
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')

export const numberLength = (length: number) =>
  yup
    .string()
    .required(errorStrings.required)
    .matches(/^[0-9]+$/, 'Must be only numbers')
    .min(length, 'Must be exactly 5 digits')
    .max(length, 'Must be exactly 5 digits')

export const requiredSubscriptionMessage = yup
  .string()
  .required(forms.subscriptionRequired)
export const requiredStringMessage = yup
  .string()
  .required(errorStrings.required)
export const requiredStringShortMessage = yup
  .string()
  .required(forms.shortRequired)
export const requiredEmail = yup
  .string()
  .required(errorStrings.required)
  .email(errorStrings.loginValidEmail)

export const phone = (countryCode: CountryCode) =>
  yup.string().test('phone', 'Invalid phone number', (value) => {
    if (!value) return true // Allow empty string
    return yup.string().phone(countryCode).isValidSync(value)
  })
export const requiredPhone = (countryCode: CountryCode) =>
  yup.string().phone(countryCode).required(errorStrings.required)

const passwordSchema = new passwordValidator()
passwordSchema.symbols(1, 'Password must contain a symbol')

export const password = yup
  .string()
  .required(errorStrings.required)
  .min(8, 'Password is too short - should be 8 chars minimum.')
  .test(
    'test-password',
    'Password must contain a symbol',
    (value) => passwordSchema.validate(value) as boolean,
  )

export const passwordMatch = (name: string) =>
  yup
    .string()
    .required('Confirm Password is required')
    .oneOf([yup.ref(name)], 'Passwords must match')

// export const creditCardNumber = yup.string()
//     .test(
//         'test-number',
//         'Credit Card number is invalid',
//         value => creditCardValidator.number(value).isValid
//     )
//     .required()

export const booleanNoMessage = yup.boolean()
export const booleanTrueRequired = (msg: string) =>
  yup.boolean().required(msg).oneOf([true], msg)

import React, { useMemo } from 'react'
import css from './Input.module.scss'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import type { StripeCardElementOptions } from '@stripe/stripe-js'
import { FormError, InputLabel, InputProps, InputWrapper } from '~components'
import classNames from 'classnames/bind'

const cx = classNames.bind(css)

interface StripeCardInputProps extends InputProps {}

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Questrial, serif',
      color: '#0D66FC',
      letterSpacing: '0.025em',
      padding: '20px 16px',
      backgroundColor: 'white',
      '::placeholder': {
        color: '#b6d1fe',
      },
    },
    invalid: {
      color: '#FF7E71',
    },
  },
}

export const useStripeTheme = (theme: 'default' | 'white') => {
  return useMemo(() => {
    return {
      ...CARD_ELEMENT_OPTIONS,
      style: {
        ...CARD_ELEMENT_OPTIONS.style,
        base: {
          ...CARD_ELEMENT_OPTIONS.style.base,
          backgroundColor: theme === 'default' ? '#EEF4FF' : 'white',
        },
      },
    }
  }, [theme])
}

const StripeInput: React.FC<Pick<InputProps, 'theme' | 'disabled' | 'error'>> =
  ({ children, disabled, theme, error }) => {
    return (
      <div
        className={cx({
          input: true,
          white: theme === 'white',
          disabled,
          error,
        })}
      >
        {children}
      </div>
    )
  }

export const StripeCardNumberInput: React.FC<StripeCardInputProps> = ({
  error,
  theme = 'default',
  disabled,
  wrapperClassName,
}) => {
  const options = useStripeTheme(theme)
  return (
    <InputWrapper error={error} wrapperClassName={wrapperClassName}>
      <InputLabel label={'Credit Card'} error={!!error} />
      <StripeInput>
        <CardNumberElement options={options} />
      </StripeInput>
      {error ? <FormError className={'mt-1'} error={error} /> : null}
    </InputWrapper>
  )
}

export const StripeCardExpiryInput: React.FC<StripeCardInputProps> = ({
  error,
  theme = 'default',
  disabled,
  wrapperClassName,
}) => {
  const options = useStripeTheme(theme)
  return (
    <InputWrapper error={error} wrapperClassName={wrapperClassName}>
      <InputLabel label={'Credit Card'} error={!!error} />
      <StripeInput>
        <CardExpiryElement options={options} />
      </StripeInput>
      {error ? <FormError className={'mt-1'} error={error} /> : null}
    </InputWrapper>
  )
}

export const StripeCardCVCInput: React.FC<StripeCardInputProps> = ({
  error,
  theme = 'default',
  disabled,
  wrapperClassName,
}) => {
  const options = useStripeTheme(theme)
  return (
    <InputWrapper error={error} wrapperClassName={wrapperClassName}>
      <InputLabel label={'Credit Card'} error={!!error} />
      <StripeInput>
        <CardCvcElement options={options} />
      </StripeInput>
      {error ? <FormError className={'mt-1'} error={error} /> : null}
    </InputWrapper>
  )
}

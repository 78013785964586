import React from 'react'
import { useMst } from '~stores'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { formatDateNumber } from '~utils'
import { AccountToast } from '~components/pages'
import { subscriptionStatusStrings as strings } from '~strings'

const AccountToastSwitch = ({
  openModalFunction,
}: {
  openModalFunction: Function
}) => {
  const router = useRouter()
  const { userStore, stripeStore } = useMst()

  switch (userStore.subscriptionStatus) {
    case 'inactive':
      return (
        <AccountToast
          title={strings.inactive.title}
          // @ts-ignore
          subtitle={strings.inactive.subtitle}
          action={{
            title: strings.inactive.action,
            onPress: () => router.push('/payment'),
          }}
        />
      )
    case 'app':
      if (userStore.platform === 'ios') {
        return (
          <AccountToast
            title={strings.ios.title}
            // @ts-ignore
            subtitle={strings.ios.subtitle}
            subscribed={true}
            action={{
              title: strings.ios.action,
              onPress: () => router.push('/payment'),
            }}
          />
        )
      }
      if (userStore.platform === 'android') {
        return (
          <AccountToast
            title={strings.android.title}
            // @ts-ignore
            subtitle={strings.android.subtitle}
            subscribed={true}
            action={{
              title: strings.android.action,
              onPress: () => router.push('/payment'),
            }}
          />
        )
      }
      return null
    case 'canceled':
      return (
        <AccountToast
          title={strings.canceled.title}
          // @ts-ignore
          subtitle={strings.canceled.subtitle(
            formatDateNumber(stripeStore.subscription?.current_period_end),
          )}
          action={{
            title: strings.canceled.action,
            onPress: () => openModalFunction(),
          }}
        />
      )
    case 'expired':
      return (
        <AccountToast
          title={strings.expired.title}
          // @ts-ignore
          subtitle={strings.inactive.subtitle}
          action={{
            title: strings.expired.action,
            onPress: () => openModalFunction(),
          }}
        />
      )
    default:
    case 'active':
      return null
  }
}

export default observer(AccountToastSwitch)

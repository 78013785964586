import React, { forwardRef, useMemo } from 'react'
import { Listbox } from '@headlessui/react'
import { ErrorOption } from 'react-hook-form'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid'
import classNames from 'classnames/bind'
import css from './Select.module.scss'
import { DownIcon } from '~svg'

const cx = classNames.bind(css)

export type OptionValue = string | number

export type Option<ValueT extends OptionValue> = {
  id: string
  value: ValueT
  label: string
}

const SelectOption = <ValueT extends OptionValue>({
  item,
}: {
  item: Option<ValueT>
}) => (
  <Listbox.Option
    value={item.value}
    className={({ active }) =>
      cx({ 'bg-blue': active }, 'cursor-default select-none relative py-1 pl-1')
    }
  >
    {({ selected, active }) => (
      <React.Fragment>
        <span
          className={classNames('caption', {
            'font-semibold': active,
          })}
        >
          {item.label}
        </span>
        {selected ? (
          <span
            className={classNames(
              'text-black absolute inset-y-0 right-0 flex items-center pr-4',
              { 'text-blue': active },
            )}
          >
            <CheckIcon className="h-[14px] w-[14px]" aria-hidden="true" />
          </span>
        ) : null}
      </React.Fragment>
    )}
  </Listbox.Option>
)

export type SelectProps<ValueT extends OptionValue> = {
  value: ValueT
  options: Option<ValueT>[]

  onChange?: (value: ValueT) => void
  label?: string
  placeholder?: string
  wrapperClassName?: string
  onBlur?: () => void
  error?: ErrorOption
  theme?: 'default' | 'white'
}

const Select = forwardRef<HTMLButtonElement, SelectProps<any>>(
  <ValueT extends OptionValue>(
    {
      label,
      error,
      value,
      options,
      placeholder,
      wrapperClassName,
      onChange,
      theme = 'default',
    }: SelectProps<ValueT>,
    ref,
  ) => {
    const selectedOpt = useMemo<Option<ValueT> | undefined>(
      () => options?.find((opt) => opt.value === value),
      [options, value],
    )

    return (
      <div className={classNames(css.wrapper, wrapperClassName)}>
        <Listbox value={value} onChange={(_val) => onChange(_val as ValueT)}>
          {label ? (
            <Listbox.Label className={cx({ select_label: true, error })}>
              {label}
            </Listbox.Label>
          ) : null}

          <div className="relative">
            <Listbox.Button
              ref={ref}
              className={cx({
                button: true,
                white: theme === 'white',
                error,
              })}
            >
              <span
                className={cx('block truncate', {
                  'text-blue': Boolean(selectedOpt),
                  'text-blue-30': !Boolean(selectedOpt),
                })}
              >
                {selectedOpt ? selectedOpt.label : placeholder}
              </span>
              <span className="absolute inset-y-0 right-2 flex items-center pr-2 pointer-events-none">
                <DownIcon className="h-[14px] w-[14px]" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Listbox.Options className={css.options_box}>
              {options?.map((option) => (
                <SelectOption item={option} key={option.id} />
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
        <span className={'caption text-red'}>
          {error ? error.message : null}
        </span>
      </div>
    )
  },
)

export default Select

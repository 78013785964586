import React, { useCallback, useState, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import Splash from '~components/Layout/Splash/Splash'
import { observer } from 'mobx-react'
import type { FormProps } from '~components'
import {
  Button,
  ButtonGroup,
  ControlledInput,
  Form,
  FormError,
  useCustomForm,
  Link,
  CodeInput,
} from '~components'
import { numberLength, requiredEmail } from '~validations'
import { errorStrings } from '~strings'
import { getValidationSchema, SentryCaptureException } from '~utils'
import { useRouter } from 'next/router'
import { useMst } from '~stores'
import { useLoginSubmit } from '~hooks/useLoginSubmit'

const codeSchema = getValidationSchema({
  code: numberLength(6),
})

const emailSchema = getValidationSchema({
  email: requiredEmail,
})

interface CodeLoginFormProps {
  handleStatusChange?: (status: string) => void
  status?: string
  startCode?: boolean
  initialEmail?: string
  emailValue?: string
  setEmailValue?: React.Dispatch<React.SetStateAction<string | undefined>>
}

const CodeLoginForm: React.FC<CodeLoginFormProps> = ({
  handleStatusChange,
  startCode,
  status,
  initialEmail,
  emailValue,
  setEmailValue,
}) => {
  const router = useRouter()
  const { userStore } = useMst()
  const handleLogin = useLoginSubmit()

  const contextEmail = useCustomForm({ schema: emailSchema })
  const contextCode = useCustomForm({ schema: codeSchema })

  const [error, setError] = useState<FormProps['error']>()
  const [codeError, setCodeError] = useState<FormProps['error']>()
  const [loading, setLoading] = useState(false)
  const [splashLoading, setSplashLoading] = useState(false)
  const [loadingCode, setLoadingCode] = useState(false)
  const [showCode, setShowCode] = useState(startCode || false)
  const [code, setCode] = useState('')

  const [loginAttempted, setLoginAttempted] = useState(false)

  // Hooks
  const watchedCode = useWatch({
    control: contextCode.control,
    name: 'code',
    defaultValue: '',
  })

  // Custom hooks
  const useSendCode = () => {
    return async (email: string) => {
      await userStore.logInWithPhoneRequest(email)
    }
  }
  const sendCode = useSendCode()

  const onSubmitCode = useCallback(
    async (data: { code: string }) => {
      try {
        setLoading(true)
        await handleLogin({
          login: emailValue,
          password: data.code,
        })
      } catch (e) {
        if (e.response?.data?.title) {
          setCodeError({ message: e.response.data.title })
        } else {
          SentryCaptureException(e)
          setCodeError({
            message: errorStrings.verificationCode,
          })
        }
      } finally {
        setLoading(false)
      }
    },
    [handleLogin, emailValue],
  )

  useEffect(() => {
    const { email, code } = router.query

    if (email && code && !loginAttempted) {
      const decodedEmail = decodeURIComponent(email as string)

      const submitLogin = async () => {
        setLoginAttempted(true)
        try {
          setSplashLoading(true)
          await handleLogin({
            login: decodedEmail,
            password: code as string,
          })
        } catch (e) {
          SentryCaptureException(e)
          setCodeError({
            message: errorStrings.verificationCode,
          })
        } finally {
          setCode(code as string)
          setSplashLoading(false)
        }
      }

      submitLogin()
    } else if (email && !loginAttempted) {
      const decodedEmail = decodeURIComponent(email as string)
      setEmailValue(decodedEmail)
      setShowCode(true)
      setLoginAttempted(true)
    } else if (code && !loginAttempted) {
      setCode(code as string)
      setShowCode(true)
      setLoginAttempted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleLogin, loginAttempted])

  // useEffect hooks
  useEffect(() => {
    if (!emailValue && initialEmail) {
      setEmailValue(initialEmail)
      setShowCode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEmail, emailValue])

  useEffect(() => {
    setCode(watchedCode)
  }, [watchedCode])

  useEffect(() => {
    contextCode.setValue('code', code)
  }, [code, contextCode])

  // Event handlers
  const onSubmitEmail = async (data: { email: string }) => {
    try {
      setLoading(true)
      await sendCode(data.email)
    } catch (e) {
      if (e.status === 422) {
        setError({
          message: errorStrings.loginNoEmail,
        })
        return
      } else {
        setEmailValue(data.email)
        setShowCode(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const resendCode = useCallback(async () => {
    if (emailValue) {
      setLoadingCode(true)
      await sendCode(emailValue)
      setLoadingCode(false)
    }
  }, [sendCode, emailValue])

  if (splashLoading) {
    return <Splash show={true} />
  }

  if (showCode) {
    return (
      <>
        <h1 className={'text-blue mb-10 h2'}>Check your email</h1>
        <div className={'copy-2 mb-2'}>We sent an email to {emailValue}</div>
        <Form {...contextCode} onSubmit={onSubmitCode}>
          {emailValue ? (
            <div className="copy-2 text-blue mb-10">
              {loadingCode ? (
                <span>Sending</span>
              ) : (
                <span>
                  <a onClick={resendCode} className={'text-blue link'}>
                    Resend code
                  </a>
                </span>
              )}
            </div>
          ) : null}

          <CodeInput length={6} onChange={setCode} value={code} />

          <div className="hidden">
            <ControlledInput
              name={'code'}
              inputProps={{
                wrapperClassName: 'mb-3',
              }}
            />
          </div>

          {codeError ? (
            <FormError className={'mb-3'} error={codeError} />
          ) : null}

          <Button type={'submit'} loading={loading} className={'mb-8'}>
            {loading ? 'Sending' : 'Next'}
          </Button>

          <div className="caption">
            If you don't see the email within 2 minutes, please check your spam
            or junk folder.
          </div>
        </Form>

        <div className="mt-10">
          <div
            onClick={() => handleStatusChange('password')}
            className="cursor-pointer"
            role="button"
          >
            <h5 className={'h5 mb-4 text-blue text-center'}>
              Log in with password
            </h5>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <h1 className={'text-blue mb-10 h2'}>Log in to Alive</h1>
        <div className={'copy-2 mb-10'}>
          New to Alive?{' '}
          <Link href="/sign-up" className={'link'}>
            Sign up today
          </Link>
        </div>
        <Form {...contextEmail} onSubmit={onSubmitEmail}>
          <ControlledInput
            name={'email'}
            inputProps={{
              label: 'Email',
              placeholder: 'youremail@email.com',
              wrapperClassName: 'mb-8',
            }}
            transform={{
              output: (e) => {
                return e.target.value.toLowerCase()
              },
            }}
          />

          {error ? <FormError className={'mb-5'} error={error} /> : ''}

          <ButtonGroup>
            <Button loading={loading} type={'submit'}>
              Next
            </Button>
          </ButtonGroup>
        </Form>
        <div className="mt-8">
          <div
            onClick={() => handleStatusChange('phone')}
            className="cursor-pointer"
            role="button"
          >
            <h5 className={'h5 mb-4 text-blue text-center'}>
              Log in with phone
            </h5>
          </div>
        </div>
      </>
    )
  }
}

export default observer(CodeLoginForm)

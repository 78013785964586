import React, { useMemo } from 'react'
import { subscriptionSuccessModal } from '~strings'
import { Button, Typography, Modal, ModalProps } from '~components'

interface AccountSuccessModalProps extends Omit<ModalProps, 'children'> {
  subscriptionPlatform: string
}

const AccountSuccessModal: React.FC<AccountSuccessModalProps> = ({
  onClose,
  open,
  subscriptionPlatform,
}) => {
  const strings = useMemo(() => {
    const _str = subscriptionSuccessModal[subscriptionPlatform]
    return _str ? _str : subscriptionSuccessModal.default
  }, [subscriptionPlatform])

  return (
    <Modal open={open} onClose={onClose}>
      <Typography.Title level={'h3'} className={'text-blue mb-2'}>
        {strings.title}
      </Typography.Title>
      <Typography.Text type={'caption'} className={'mb-7'}>
        {strings.content}
      </Typography.Text>

      {strings.link ? (
        <Button onClick={() => window.open(strings.link.url)}>
          {strings.link.title}
        </Button>
      ) : null}

      <div className={'w-full text-center mt-5 caption'}>
        {strings.disclaimer}
      </div>
    </Modal>
  )
}

export default AccountSuccessModal

import React, { useEffect, useState } from 'react'
import { CaretIcon } from '~svg'

type AccordionItemProps = {
  triggerText: string
  isOpen?: boolean
  onToggle?: () => void
  children: React.ReactNode
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  triggerText,
  isOpen = false,
  onToggle,
  children,
}) => {
  // State for managing the maximum height of the content area.
  const [maxHeight, setMaxHeight] = useState<string>(isOpen ? '1000px' : '0px')

  useEffect(() => {
    if (isOpen) {
      setMaxHeight('1000px')
    } else {
      setMaxHeight('0px')
    }
  }, [isOpen])

  return (
    <div className="border-t border-black-line relative">
      <button
        onClick={onToggle}
        className="pt-6 pr-10 pb-9 w-full text-left  cursor-pointer"
      >
        <div className={'copy'}>{triggerText}</div>
        <div className="absolute top-0 right-0 py-6">
          <div
            className="transition-transform origin-center"
            style={
              isOpen
                ? { transform: 'rotate(0deg)' }
                : { transform: 'rotate(180deg)' }
            }
          >
            <CaretIcon />
          </div>
        </div>
      </button>
      <div
        className="overflow-hidden transition-[max-height] duration-200 ease-out"
        style={
          isOpen
            ? { maxHeight: '1000px', transition: 'max-height 1s ease-in-out' }
            : {
                overflow: 'hidden',
                maxHeight: '0',
                transition: 'max-height 0.5s cubic-bezier(0, 1, 0, 1)',
              }
        }
      >
        <div className="pb-8">{children}</div>
      </div>
    </div>
  )
}

AccordionItem.displayName = 'AccordionItem'

interface LoggerConfig {
  nsSeparator?: string
  nsColor?: string
  nsFontWeight?: string
  nsBackground?: string
  disabled?: boolean
}

export const Logger = (namespace: string, config: LoggerConfig) => {
  const localConfig = {
    ...Logger.defaultConfig,
    ...config,
  }

  let { nsSeparator, nsColor, nsFontWeight, nsBackground, disabled } =
    localConfig

  const fullNamespace = `${namespace}${nsSeparator}`

  /**
   * Console logs with styles. Note that console error and warn must should not
   * be used with styles.
   * @param {string} message
   * @param  {...any} args
   */
  const printWithStyles = (message, ...args) => {
    if (disabled) return
    const nsStyle = `
        color: ${nsColor};
        font-weight: ${nsFontWeight};
        background: ${nsBackground};
    `
    const loggerArgs = [`%c ${fullNamespace} `, nsStyle, message]

    if (args.length) {
      loggerArgs.push(...args)
    }

    // eslint-disable-next-line no-console
    console.log(...loggerArgs)
  }

  /**
   * @param {() => void} logger - console method.
   * @param {string} message
   * @param  {...any} args
   */
  const print = (logger, message, ...args) => {
    if (disabled) return
    const namespacedMessage = `${fullNamespace} ${message}`
    if (args.length) {
      logger(namespacedMessage, ...args)
    } else {
      logger(namespacedMessage)
    }
  }

  return {
    log: (message, ...args) => {
      printWithStyles(message, ...args)
    },
    // logS: message => {
    //     Sentry.addBreadcrumb({
    //         level: "info",
    //         message,
    //     });
    //     printWithStyles(message);
    // },
    warn: (message, ...args) => {
      // eslint-disable-next-line no-console
      print(console.warn, message, ...args)
    },
    error: (message, ...args) => {
      // eslint-disable-next-line no-console
      print(console.log, message, ...args)
    },
  }
}

Logger.defaultConfig = {
  nsSeparator: ':',
  nsColor: '#2196f3',
  nsFontWeight: '700',
  nsBackground: '#171616',
  disabled: process.env.NODE_ENV !== 'development',
}

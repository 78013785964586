import { SVGComponent } from '~types'

const AmericanExpress = (props: SVGComponent) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 324 228"
    {...props}
  >
    <path
      fill="#006FCF"
      d="M293.601 227.12H30.851c-16.5 0-29.88-13.38-29.88-29.88V29.88C.97 13.38 14.35 0 30.85 0H293.6c16.5 0 29.88 13.38 29.88 29.88v167.35c0 16.51-13.38 29.89-29.88 29.89Z"
    />
    <mask
      id="a"
      width="324"
      height="133"
      x="0"
      y="53"
      maskUnits="userSpaceOnUse"
    >
      <path fill="#fff" d="M.97 53.845v131.97h322.291V53.845H.971Z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#F7F7F7"
        d="M283.781 110.145h24.19v-56.3h-25.82v9l-5.75-9h-22.51v11.39l-5.04-11.39h-37.21c-1.79 0-3.53.13-5.22.4-1.44.23-2.84.55-4.19.97-1.06.33-2.09.72-3.08 1.18a24.15 24.15 0 0 0-3.34 1.86v-4.41H74.361l-3.31 9.82-3.37-9.82h-28.26v11.51l-5.1-11.51H11.27L.961 77.925v32.22h16.33l3.04-8.08h5.75l3.09 8.08h125.62v-8.19l6.29 8.19h34.71v-4.09c.9.58 1.83 1.09 2.77 1.53.95.44 1.92.81 2.9 1.13 1.21.4 2.45.69 3.67.92 2 .36 4 .52 5.89.52h20.77l3.04-8.08h5.75l3.09 8.08h34.82v-8.35l5.29 8.34Zm39.48 72.2v-52.83h-199.93l-5.1 7.21-5.04-7.21h-57.5v56.3h57.28l4.99-7.32 5.15 7.32h35.85v-12.15h-.38c4.46 0 8.51-.81 12.1-2.14v14.29h25v-8.19l6.29 8.19h108.48c3.12 0 6.05-.53 8.67-1.48 1.49-.54 2.87-1.21 4.14-1.99Z"
      />
      <mask
        id="b"
        width="260"
        height="39"
        x="64"
        y="138"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#fff" d="M323.481 176.648v-37.97H64.541v37.97h258.94Z" />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#006FCF"
          d="M310.681 169.058h-19.47v7.6h19.2c8.2 0 13.08-4.99 13.08-12.1 0-6.78-4.39-10.74-11.77-10.74h-8.79c-2.17 0-3.69-1.41-3.69-3.64 0-2.28 1.51-3.8 3.69-3.8h16.49l3.37-7.7h-19.58c-8.19 0-13.07 4.99-13.07 11.82 0 7 4.61 11.01 11.83 11.01h8.73c2.17 0 3.74 1.41 3.74 3.69-.02 2.34-1.59 3.86-3.76 3.86Zm-36.12 0h-19.47v7.6h19.2c8.19 0 13.08-4.99 13.08-12.1 0-6.78-4.39-10.74-11.77-10.74h-8.79c-2.17 0-3.69-1.41-3.69-3.64 0-2.28 1.52-3.8 3.69-3.8h16.49l3.37-7.7h-19.58c-8.19 0-13.07 4.99-13.07 11.82 0 7 4.61 11.01 11.83 11.01h8.73c2.17 0 3.74 1.41 3.74 3.69-.02 2.34-1.59 3.86-3.76 3.86Zm-25.01-22.67v-7.7h-30.38v37.97h30.38v-7.65h-21.26v-7.65h20.77v-7.6h-20.77v-7.38h21.26v.01Zm-50.87.05c3.25 0 4.99 2.11 4.99 4.72 0 2.6-1.74 4.66-4.99 4.66h-10.69v-9.38h10.69Zm-10.69 16.54h4.29l11.34 13.67h11.34l-12.64-14c6.46-1.2 10.3-5.86 10.3-11.93 0-7.11-5.04-12.04-13.13-12.04h-20.61v37.97h9.11v-13.67Zm-23.22-11.55c0 2.55-1.63 4.67-4.83 4.67h-10.9v-9.33h10.9c3.21 0 4.83 2.06 4.83 4.66Zm-24.84-12.75v37.97h9.11v-12.53h11.44c8.3 0 13.45-5.42 13.45-12.74 0-7.54-5.04-12.69-13.13-12.69h-20.87v-.01Zm-13.4 37.97h11.39l-15.24-19.15 15.13-18.82h-11.06l-9.33 12.04-9.27-12.04h-11.39l15.19 18.98-15.13 18.99h11.07l9.33-12.21 9.31 12.21Zm-31.62-30.26v-7.7h-30.38v37.97h30.38v-7.65h-21.26v-7.65h20.77v-7.6h-20.77v-7.38h21.26v.01Z"
        />
      </g>
      <path
        fill="#006FCF"
        d="m271.141 78.245-.11-3.15 1.9 3.15 14.53 22.73h10.64v-37.97h-8.9v21.97l.11 2.82-1.68-2.82-14.22-21.97h-11.17v37.97h8.89v-22.73h.01Zm-36.34-1.08 2.77-7.11 2.76 7.11 3.37 8.41h-12.21l3.31-8.41Zm15.08 23.81h10.25l-16.21-37.97h-11.99l-16.27 37.97h9.71l3.2-8.14h18.06l3.25 8.14Zm-39.76 0h.49l3.74-8.08h-2.77c-6.24 0-9.93-3.9-9.93-10.63v-.54c0-6.02 3.31-10.52 9.82-10.52h9.87v-8.19h-10.63c-11.72 0-18.12 7.87-18.12 18.66v.65c.01 11.28 6.57 18.6 17.53 18.65Zm-32.38 0h9.11v-37.97h-9.11v37.97Zm-19.15-30.21c3.25 0 4.99 2.11 4.99 4.72 0 2.6-1.74 4.66-4.99 4.66h-10.69v-9.38h10.69Zm-10.69 16.54h4.29l11.34 13.67h11.34l-12.64-14c6.46-1.19 10.31-5.86 10.31-11.93 0-7.11-5.05-12.04-13.13-12.04h-20.61v37.97h9.11v-13.67h-.01Zm-15.78-16.6v-7.7h-30.38v37.97h30.38v-7.76h-21.26v-7.54h20.77v-7.59h-20.77v-7.38h21.26Zm-75.56 4.35-.22-10.25 10.52 36.18h8.25l10.52-35.96-.22 9.98v25.97h8.84v-37.97h-16.05l-7.21 26.9-7.27-26.9h-16v37.97h8.84v-25.92Zm-36.28 2.11 2.77-7.11 2.77 7.11 3.36 8.41h-12.2l3.3-8.41Zm15.07 23.81h10.25l-16.22-37.97h-11.99l-16.27 37.97h9.71l3.2-8.14h18.06l3.26 8.14Z"
      />
    </g>
  </svg>
)

export default AmericanExpress

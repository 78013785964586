export const publicPaths = [
  '/',
  '/404',
  '/privacy',
  '/terms',
  '/email/verification',
  '/reset-password',
  '/password/reset',
  '/password/forgot',
  '/password/forgot-sent',
  '/download',
]

export const signUpAndLoginRoutes = [
  '/login',
  '/login/code',
  '/sign-up',
  '/partner/sign-up',
]

export const isPublicPath = (path: string): boolean =>
  publicPaths.includes(path)

export const isSignupOrLoginPath = (path: string): boolean =>
  signUpAndLoginRoutes.includes(path)

import React from 'react'
import { Grid } from '~components'
import css from './CenteredPage.module.scss'

interface CenteredPageTemplateProps {}

export const CenteredPageContainer: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={`col-start-4 col-span-6 ${className ? className : ''}`}>
    {children}
  </div>
)

export const CenteredPage: React.FC<CenteredPageTemplateProps> = ({
  children,
}) => {
  return (
    <div className={css.default}>
      <Grid className={'grid-cols-12'}>{children}</Grid>
    </div>
  )
}

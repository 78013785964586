import React from 'react'
import Toggle, { ToggleProps } from './Toggle'
import { Controller, ControllerProps, useFormContext } from 'react-hook-form'

interface ControlledToggle extends Omit<ControllerProps<any, any>, 'render'> {
  name: string
  toggleProps?: Omit<ToggleProps, 'name'>
}

const ControlledToggle = ({ name, toggleProps }: ControlledToggle) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...restField }, formState: { errors } }) => (
        <Toggle
          name={name}
          error={errors[name]}
          {...restField}
          {...toggleProps}
        />
      )}
    />
  )
}

export default ControlledToggle

import React from 'react'
import { Card, Grid, Link, Typography } from '~components'

const AccountGrid: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <Grid className={`grid-cols-12 ${className ? className : ''}`}>
    {children}
  </Grid>
)

interface AccountCardActionProps {
  label: string
  href?: string
  onClick?: (params: any) => void
}

const AccountCardAction = ({
  label,
  href,
  onClick,
}: AccountCardActionProps) => {
  return <div className={'caption text-blue'}>{label}</div>
}

const AccountCardText: typeof Typography.Title = ({ children, ...rest }) => (
  <Typography.Title
    {...rest}
    level={'h4'}
    className={'text-blue break-all'}
    lineHeight={1.6}
  >
    {children}
  </Typography.Title>
)

interface AccountBoxProps {
  title?: string
  className?: string
  actions?: AccountCardActionProps[]
  empty?: {
    text: string
    Icon?: React.ReactElement
    actions?: AccountCardActionProps[]
  }
}

const AccountBox: React.FC<AccountBoxProps> = ({
  className,
  title,
  actions,
  children,
  empty,
}) => {
  const firstAction = actions?.[0]
  const isClickable = firstAction?.onClick || firstAction?.href

  const cardProps = {
    className: `${className || ''} ${isClickable ? 'cursor-pointer' : ''}`,
    onClick:
      firstAction?.onClick ||
      (firstAction?.href
        ? () => (window.location.href = firstAction.href)
        : undefined),
  }

  if (children) {
    return (
      <Card {...cardProps}>
        <div className={'flex flex-row items-center justify-between mb-6'}>
          {title ? (
            <Typography.Title level={'h4'}>{title}</Typography.Title>
          ) : null}

          <div>
            {actions
              ? actions?.map((item, index) => (
                  <AccountCardAction
                    key={`AccountCardAction-${title}-${index}`}
                    {...item}
                  />
                ))
              : null}
          </div>
        </div>
        <div>{children}</div>
      </Card>
    )
  }

  if (empty) {
    const { Icon, text, actions: editActions } = empty
    return (
      <Card {...cardProps}>
        <div className={'flex flex-row items-center justify-between mb-8'}>
          {title ? (
            <Typography.Title level={'h4'}>{title}</Typography.Title>
          ) : null}

          <div>
            {editActions
              ? editActions?.map((item, index) => (
                  <AccountCardAction
                    key={`AccountCardEditAction-${title}-${index}`}
                    {...item}
                  />
                ))
              : null}
          </div>
        </div>
        <div className={'flex flex-row items-center justify-start'}>
          {Icon ? <span className={'mr-4'}>{Icon}</span> : null}
          <div className="copy-2">{text}</div>
        </div>
      </Card>
    )
  }

  return (
    <Card className={`${className ? className : ''}`}>
      <Typography.Text>
        {'Ooops! We had an error loading this Card.'}
      </Typography.Text>
    </Card>
  )
}

const AccountCard = {
  Box: AccountBox,
  Text: AccountCardText,
  Grid: AccountGrid,
  Action: AccountCardAction,
}

export default AccountCard

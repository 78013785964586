import { Notify } from 'notiflix'

export const initNotify = () => {
  Notify.init({
    fontFamily: 'Campton',
    position: 'center-top',
    distance: '0',
    opacity: 1,
    borderRadius: '999px',
    messageMaxLength: 1000,
    rtl: false,
    timeout: 3000,
    backOverlay: false,
    plainText: false,
    showOnlyTheLastOne: true,
    clickToClose: true,
    pauseOnHover: true,
    ID: 'NotiflixNotify',
    className: 'notiflix-notify',
    zindex: 4001,
    fontSize: '16px',
    cssAnimation: true,
    cssAnimationDuration: 400,
    cssAnimationStyle: 'from-top',
    closeButton: false,
    useIcon: false,

    success: {
      background: '#EEFFA4',
      textColor: '#000000',
      childClassName: 'notiflix-notify-success',
    },

    failure: {
      background: '#FF7E71',
      textColor: '#000000',
      childClassName: 'notiflix-notify-failure',
    },
  })
}

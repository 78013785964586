import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { LoginForm } from '~components/pages'
import CodeLoginForm from '~components/pages/auth/CodeLoginForm'
import PhoneLoginForm from '~components/pages/auth/PhoneLoginForm'
import { useLoginSubmit } from '~hooks/useLoginSubmit'
import { requiredEmail, requiredStringMessage } from '~validations'
import { getValidationSchema, SentryCaptureException } from '~utils'
import { errorStrings } from '~strings'
import { FormProps, useCustomForm } from '~components'

interface FormSchema {
  email: string
  password: string
}

const schema = getValidationSchema({
  email: requiredEmail,
  password: requiredStringMessage,
})

const Login = ({ mixpanel }) => {
  const handleLogin = useLoginSubmit()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<FormProps['error']>()
  const [emailValue, setEmailValue] = useState<string>('')

  const [status, setStatus] = useState<'password' | 'email' | 'code' | 'phone'>(
    'password',
  )

  useEffect(() => {
    const updateHistory = (newStatus: typeof status) => {
      window.history.pushState({ status: newStatus }, '')
    }

    const handlePopState = (event: PopStateEvent) => {
      if (event.state && event.state.status) {
        setStatus(event.state.status)
      } else {
        setStatus('password')
      }
    }

    window.addEventListener('popstate', handlePopState)

    updateHistory(status)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (status !== 'password') {
      window.history.pushState({ status }, '')
    }
  }, [status])

  const handleStatusChange = (newStatus: typeof status) => {
    setStatus(newStatus)
  }

  const context = useCustomForm<FormSchema>({
    schema,
    defaultValues: {
      email: emailValue,
      password: '',
    },
    mode: 'onChange',
  })

  const emailWatch = context.watch('email')

  useEffect(() => {
    if (emailWatch !== emailValue) {
      setEmailValue(emailWatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailWatch])

  useEffect(() => {
    if (emailValue !== emailWatch) {
      context.setValue('email', emailValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValue, context])

  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      setLoading(true)
      await handleLogin({ login: data.email, password: data.password })
      mixpanel.identify()
    } catch (e) {
      if (e.status === 401) {
        setError({
          message: errorStrings.loginPassword,
        })
        return
      }
    } finally {
      setLoading(false)
    }
  }

  switch (status) {
    case 'code':
      return (
        <>
          <CodeLoginForm
            handleStatusChange={handleStatusChange}
            status={status}
            emailValue={emailValue}
            setEmailValue={setEmailValue}
          />
        </>
      )
    case 'password':
      return (
        <>
          <LoginForm
            {...context}
            error={error}
            loading={loading}
            onSubmit={onSubmit}
            emailValue={emailValue}
            setEmailValue={setEmailValue}
            handleStatusChange={handleStatusChange}
          />
        </>
      )
    case 'phone':
      return (
        <>
          <PhoneLoginForm />
        </>
      )
  }
}

export default observer(Login)

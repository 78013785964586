import { SVGComponent } from '~types'

const Youtube = ({ fill = '#fff', ...rest }: SVGComponent) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 29 19"
    {...rest}
  >
    <path
      fill={fill}
      d="M27.438 3.378c-.31-1.092-1.23-1.953-2.396-2.245-2.112-.53-10.587-.53-10.587-.53s-8.474 0-10.586.53c-1.166.292-2.085 1.153-2.396 2.245C.906 5.36.906 9.493.906 9.493s0 4.132.567 6.114c.31 1.093 1.23 1.953 2.396 2.246 2.112.53 10.586.53 10.586.53s8.475 0 10.587-.53c1.166-.293 2.085-1.153 2.396-2.246.567-1.982.567-6.114.567-6.114s0-4.133-.567-6.115Zm-15.754 9.867V5.74l7.083 3.753-7.083 3.752Z"
    />
  </svg>
)

export default Youtube

import { SVGComponent } from '~types'

const Instagram = ({ fill = '#fff', ...rest }: SVGComponent) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...rest}
  >
    <mask id="a" width="20" height="21" x="0" y="0" maskUnits="userSpaceOnUse">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M0 .496h19.683v19.685H0V.496z"
        clipRule="evenodd"
      />
    </mask>
    <g fill={fill} fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
      <path d="M9.841.496c-2.672 0-3.008.011-4.057.06-1.048.047-1.763.214-2.39.457a4.826 4.826 0 00-1.742 1.135A4.824 4.824 0 00.517 3.891c-.244.627-.41 1.342-.458 2.39C.011 7.33 0 7.666 0 10.339s.011 3.008.06 4.058c.047 1.047.213 1.763.457 2.389a4.824 4.824 0 001.135 1.743 4.826 4.826 0 001.743 1.136c.626.243 1.341.41 2.389.457 1.05.048 1.385.06 4.057.06 2.673 0 3.008-.012 4.058-.06 1.048-.048 1.763-.214 2.389-.457a4.826 4.826 0 001.743-1.136 4.822 4.822 0 001.135-1.743c.243-.626.41-1.342.457-2.39.048-1.049.06-1.384.06-4.057 0-2.673-.012-3.009-.06-4.058-.047-1.048-.214-1.763-.457-2.39a4.822 4.822 0 00-1.135-1.743 4.825 4.825 0 00-1.743-1.135c-.626-.243-1.341-.41-2.39-.458C12.85.507 12.515.496 9.842.496zm0 1.775c2.628 0 2.94.01 3.977.057.96.044 1.48.204 1.828.34.459.178.787.391 1.131.736.345.344.558.672.736 1.131.135.347.295.868.34 1.828.047 1.038.057 1.35.057 3.977 0 2.628-.01 2.94-.058 3.977-.044.96-.204 1.481-.339 1.828a3.05 3.05 0 01-.736 1.132 3.05 3.05 0 01-1.131.736c-.347.135-.868.295-1.828.339-1.037.047-1.348.057-3.976.057s-2.94-.01-3.977-.057c-.96-.044-1.48-.204-1.827-.339a3.05 3.05 0 01-1.132-.736 3.049 3.049 0 01-.736-1.132c-.135-.347-.295-.868-.34-1.828-.046-1.037-.057-1.349-.057-3.977s.01-2.94.058-3.977c.044-.96.204-1.48.339-1.828a3.05 3.05 0 01.736-1.131 3.048 3.048 0 011.132-.737c.346-.135.867-.295 1.827-.339 1.038-.047 1.349-.057 3.977-.057z" />
      <path d="M9.845 13.74c-1.821 0-3.298-1.454-3.298-3.247 0-1.792 1.477-3.246 3.298-3.246 1.822 0 3.298 1.454 3.298 3.246 0 1.793-1.476 3.247-3.298 3.247zm0-8.249c-2.806 0-5.081 2.24-5.081 5.001 0 2.762 2.275 5.001 5.08 5.001 2.807 0 5.082-2.239 5.082-5 0-2.763-2.275-5.002-5.081-5.002zM16.196 5.178c0 .69-.498 1.25-1.112 1.25-.614 0-1.111-.56-1.111-1.25s.497-1.25 1.111-1.25 1.112.56 1.112 1.25z" />
    </g>
  </svg>
)

export default Instagram

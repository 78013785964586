import {createContext, useContext} from 'react'
import {Instance, flow, types} from 'mobx-state-tree'
import {enableStaticRendering} from 'mobx-react-lite'
import {connectReduxDevtools} from 'mst-middlewares'
import {UserStore} from '~stores/user'
import {StripeStore} from '~stores/stripe'
import {Logger} from '~utils'
import remotedev from 'remotedev'

const logger = Logger('RootStore', {nsBackground: 'black', nsColor: 'white'})

const isServer = typeof window === 'undefined'
enableStaticRendering(isServer)

export const RootModel = types
    .model('RootModel', {
        userStore: types.optional(UserStore, {}),
        stripeStore: types.optional(StripeStore, {}),
    })
    .actions((self) => ({
        hydrate: flow(function* () {
            logger.log('Hydrating root ...')

            const clientSecret = yield self.stripeStore.setupIntentPost()
            yield self.userStore.hydrate()
            yield self.stripeStore.hydrate()

            logger.log('Hydrating root finished.')

            return clientSecret
        }),
        hydrateWithoutToken: flow(function* () {
            logger.log('Hydrating without token ...')
            yield self.stripeStore.hydratePrices()
            logger.log('Hydrating finished.')
        }),
        logOut: () => {
            self.userStore.logOut()
            self.stripeStore.logOut()
        },
    }))

export type IRootStore = Instance<typeof RootModel>
export const RootStoreContext = createContext<null | IRootStore>(null)

export const rootStore = RootModel.create({})

export const connectMobxDevtools = (rootStore: IRootStore) => {
    connectReduxDevtools(remotedev, rootStore)
}

export const useMst = () => {
    const store = useContext(RootStoreContext)
    if (store === null) {
        throw new Error('Store cannot be null, please add a context provider.')
    }
    return store
}

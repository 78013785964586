import { FC, HTMLProps } from 'react'

interface CardProps extends HTMLProps<HTMLDivElement> {
  background?: string
  className?: string
}

const backgroundClassNames = {
  white: 'bg-white',
  lightBlue: 'bg-light-blue',
}

const Card: FC<CardProps> = ({
  children,
  background = 'lightBlue',
  className,
  ...rest
}) => {
  return (
    <div
      className={`rounded-[20px] px-5 py-4 h-full md:py-5 ${
        backgroundClassNames[background]
      } ${className ? className : ''}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Card

export const colors = {
  white: '#FFFFFF',
  black: '#191413',
  transparent: 'transparent',

  red: '#FF7E71',

  blue: '#0D66FC',
  blue30: '#b6d1fe',

  pink: '#F5CCFF',
  pinkYellow: '#f2dce1',
  yellow: '#EBFFA1',

  lightGrey: '#FAFAFA',
  lightBlue: '#EEF4FF',
}

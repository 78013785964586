export const errorStrings = {
  required: 'This field is required',
  loginError: 'Something went wrong, try again.',
  loginValidEmail: 'Please enter a valid email (name@mail.com)',
  loginNoEmail:
    "We don't have an account with this email. Try another email, or create a new account above.",
  loginPassword:
    'Either your email or password was incorrect. Please try again.',
  verificationCode: 'The code you entered is incorrect. Please re-enter.',
  verificationCodeExpired: 'This code has expired. Please generate a new code.',
  promoCode: 'Not a valid promo code.',
}

export const successStrings = {
  loginError: 'Something went wrong, try again.',
  loginRequired: 'This field is required',
  loginValidEmail: 'Please enter a valid email (name@mail.com)',
  loginNoEmail:
    "We don't have an account with this email. Try another email, or create a new account above.",
  loginPassword:
    'Either your email or password was incorrect. Please try again.',
  verificationCode: 'The code you entered is incorrect. Please re-enter.',
  verificationCodeExpired: 'This code has expired. Please generate a new code.',
  promoCode: 'Not a valid promo code.',
}

export const subscriptionStatusStrings: {
  [key: string]: {
    title: string
    action: string
    subtitle?: string | ((param: string) => string)
  }
} = {
  ios: {
    title: 'Subscribed through your Apple ID account',
    subtitle: 'Manage subscription on your Apple device',
    action: 'Transfer',
  },
  android: {
    title: 'Subscribed through your Google Play account',
    subtitle: 'Manage subscription on your Android device',
    action: 'Transfer',
  },
  inactive: {
    title: 'No active subscription',
    subtitle: 'Select my subscription',
    action: 'Select',
  },
  expired: {
    title: 'No active subscription',
    subtitle: 'Select my subscription',
    action: 'Select',
  },
  canceled: {
    title: 'Your subscription has been canceled.',
    subtitle: (date: string) =>
      `You will still have access to the app through ${date}`,
    action: 'Resubscribe',
  },
}

export const subscriptionSuccessModal = {
  default: {
    title: 'Your subscription is all set!',
    content: "Don't forget to cancel your subscription on your Platform.",
    disclaimer:
      'If you don’t cancel your subscription with your Platform you will be double charged.',
  },
  android: {
    title: 'Your subscription is all set!',
    content:
      "Don't forget to cancel your subscription on your Google account. Tap the open button to be redirected to your Google Play settings.",
    disclaimer:
      'If you don’t cancel your subscription with Google you will be double charged.',
    link: {
      title: 'Open Settings',
      url: 'https://play.google.com/store/account/subscriptions',
    },
  },
  ios: {
    title: 'Your subscription is all set!',
    content:
      "Don't forget to cancel your subscription on your Apple account. Tap the open button to be redirected to your Apple settings.",
    disclaimer:
      'If you don’t cancel your subscription with Apple you will be double charged.',
    link: {
      title: 'Open Settings',
      url: 'itms-apps://buy.itunes.apple.com/WebObjects/MZFinance.woa/wa/manageSubscriptions',
    },
  },
}

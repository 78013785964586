import React from 'react'
import { useMst } from '~stores'
import { Checkbox, CheckboxEmpty } from '~svg'
import { useRouter } from 'next/router'
import classNames from 'classnames/bind'
import css from './PricingTable.module.scss'
import { formatPrice } from '~utils/stripeUtils'

const cx = classNames.bind(css)

export interface PricingTableItemProps {
  title: string
  subtitle: string
  disclaimer?: string
  current?: boolean
  checked?: boolean
  clickable?: boolean
  banner?: string
  bannerCrossed?: boolean
  promotion?: string
  addCurrent?: 'Monthly' | 'Yearly'
  isDisabled?: boolean
  card?: {
    type: string
    price: string
    priceNoSup: string
    regularPrice: string
    trial: string
    interval: string
    disclaimer: string
    discount?: {
      label: string
      monthly: string
    }
    renewalDate: string
  }
}

const PricingTableItem = ({
  title,
  subtitle,
  disclaimer,
  current = false,
  checked = false,
  clickable = true,
  banner,
  bannerCrossed = false,
  promotion,
  addCurrent,
  isDisabled,
  card,
}: PricingTableItemProps) => {
  const router = useRouter()
  const { userStore, stripeStore } = useMst()

  const handleChangeClick = () => {
    router.push('/account/subscription')
  }

  return (
    <div
      className={cx({
        item_container: true,
        clickable: !isDisabled,
        isDisabled,
        current,
        checked,
      })}
      role="button"
      onClick={current ? handleChangeClick : null}
    >
      <div
        className={cx(css.item_wrapper, {
          [css['discounted']]: banner,
          [css['promo']]: promotion,
        })}
      >
        {banner &&
        !(
          card.type === addCurrent && stripeStore.subscription?.discountedAmount
        ) ? (
          <div className={cx({ item_discount: true })}>
            <div className={'label-2 text-white mb-1'}>
              {card ? card.discount.label : ''}
            </div>
            <h5
              className={cx(css.promoFormat, `h5 text-white`)}
              dangerouslySetInnerHTML={{
                __html: promotion
                  ? `${card ? card.discount.monthly : ''}`
                  : `${banner} • ${card ? card.discount.monthly : ''}`,
              }}
            ></h5>
          </div>
        ) : null}
        {checked ? (
          current ? (
            <div className="text-blue cursor-pointer absolute top-6 right-5 caption">
              Change
            </div>
          ) : (
            <div className={css.checkbox}>
              <Checkbox />
            </div>
          )
        ) : (
          card?.type != addCurrent &&
          !current && <div className={css.checkboxEmpty}></div>
        )}
        {card?.type === addCurrent && !current ? (
          <div className="absolute top-[19px] right-[18px] label bg-yellow rounded-full inline-flex items-center px-2">
            <div className="pt-1">Current Plan</div>
          </div>
        ) : null}
        {current ? (
          <>
            <div className="h4 mb-14">Current plan</div>
            <div className="h4 text-blue mb-1">{card ? card.type : title}</div>
            <div className="h2 text-blue mb-2">
              {card ? card.price : subtitle}
            </div>
            <div className="caption">{card ? card.disclaimer : disclaimer}</div>
          </>
        ) : (
          <>
            <h4 className={'h4 pb-1 text-blue flex'}>
              {card ? card.type : title}
            </h4>
            <h2
              className={cx(css.title, 'h2 text-blue flex pb-4 gap-2')}
              dangerouslySetInnerHTML={{
                __html: card
                  ? card.type === addCurrent &&
                    stripeStore.subscription?.discountedAmount
                    ? `$${formatPrice(
                        stripeStore.subscription.discountedAmount,
                      )}`
                    : promotion
                    ? card.regularPrice
                    : card.price
                  : '',
              }}
            />
            <div className="h-px w-full bg-black/10 mb-4"></div>
            {userStore.subscriptionStatus === 'active' ||
            userStore.subscriptionStatus === 'canceled' ||
            userStore.subscriptionStatus === 'expired' ? null : (
              <h5 className={'h5 mb-4'}>{card ? card.trial : ''}</h5>
            )}
            <div
              className="disclaimer"
              dangerouslySetInnerHTML={{ __html: card ? card.disclaimer : '' }}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PricingTableItem

import { SVGComponent } from '~types'

const ArrowIcon = ({ fill = '#FFFFFF' }: SVGComponent) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5856 8.99992L8.29274 15.2928L9.70695 16.707L17.4141 8.99993L9.70696 1.29282L8.29274 2.70703L14.5856 8.99992Z"
      fill={fill}
    />
    <path d="M1 8L15 8L15 10L1 10L1 8Z" fill={fill} />
  </svg>
)

export default ArrowIcon

import { HTMLProps, forwardRef, Fragment, ReactNode } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseIcon } from '~svg'
import css from './Modal.module.scss'

const ModalCloseButton = ({ onClick }) => (
  <button type="button" onClick={onClick} className={css.closeButton}>
    <CloseIcon />
  </button>
)

interface ModalBoxProps extends HTMLProps<HTMLDivElement> {
  onClose: Function
}

const ModalBox = forwardRef<HTMLInputElement, ModalBoxProps>(
  ({ children, onClose, ...rest }, ref) => (
    <>
      <ModalCloseButton onClick={onClose} />
      <div className={css.box} ref={ref} {...rest}>
        <div className={css.boxInner}>{children}</div>
      </div>
    </>
  ),
)

export interface ModalProps {
  open: boolean
  children: ReactNode
  onClose?: () => void
}

export const Modal = ({ children, open, onClose }: ModalProps) => {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" onClose={onClose} unmount={true} className={css.dialog}>
        <div className={css.boxContainer}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={css.backdrop} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <ModalBox onClose={onClose}>{children}</ModalBox>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

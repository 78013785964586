import { useMst } from '~stores'
import { useRouter } from 'next/router'
import { isPublicPath } from '~utils'
import type { AuthGetToken } from '~api/APITypes'
import { bootIntercom } from '~utils/intercom'

export const useLoginSubmit = () => {
  const router = useRouter()
  const rootStore = useMst()
  const { userStore } = useMst()

  return async (data: AuthGetToken) => {
    const response = await rootStore.userStore.logIn(data)

    if (response.data.token) {
      await rootStore.hydrate()
      bootIntercom({
        user_id: rootStore.userStore.userData.id,
        email: rootStore.userStore.userData.email,
        name: `${rootStore.userStore.userData.first_name} ${rootStore.userStore.userData.last_name}`,
      })
      const isPublic = isPublicPath(router.asPath)
      const hasReturnUrl =
        !isPublic && router.query.returnUrl && router.query.returnUrl !== '#'

      const isSignup = userStore.subscriptionStatus !== 'active'

      if (hasReturnUrl) {
        await router.push(router.query.returnUrl.toString())
      } else if (isSignup) {
        await router.push('/payment')
      } else {
        await router.push('/account')
      }
    }
  }
}
